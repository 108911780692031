import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const BenefitsofGymTraining = () => {
  const articles = [
    { title: 'Benefits of Functional Training Exercises', path: '/news/benefitsoffunctionaltrainingexercises' },
    { title: 'Benefits of Outdoor vs. Indoor Workouts', path: '/news/benefitsofoutdoorvsindoorworkouts' },
    { title: 'Benefits of Personal Training Sessions', path: '/news/benefitsofpersonaltrainingsessions' },
    { title: 'Cardio vs. Strength Training: What\'s Best for You?', path: '/news/cardiovsstrengthtrainingwhatsbestforyou' },
    { title: 'Developing a Strong Core: Exercises Beyond Sit-Ups', path: '/news/developingastrongcoreexercisesbeyondsitups' },
    { title: 'Exercises for Healthy Knees and Injury Prevention', path: '/news/exercisesforhealthykneesandinjuryprevention' },
    { title: 'Exercises to Alleviate Neck and Shoulder Tension', path: '/news/exercisestoalleviateneckandshouldertension' },
    { title: 'Exercises to Enhance Lung Capacity', path: '/news/exercisestoenhancelungcapacity' },
    { title: 'Exercises to Improve Hand-Eye Coordination', path: '/news/exercisestoimprovehandeyecoordination' },
    { title: 'Exercises to Improve Joint Health and Longevity', path: '/news/exercisestoimprovejointhealthandlongevity' },
    { title: 'Gym Exercises to Strengthen Your Back and Reduce Pain', path: '/news/gymexercisestostrengthenyourbackandreducepain' },
    { title: 'Gym Strategies for Enhancing Mental Clarity', path: '/news/gymstrategiesforenhancingmentalclarity' },
    { title: 'Gym Training for Hormonal Balance', path: '/news/gymtrainingforhormonalbalance' },
    { title: 'Gym Training for Weight Loss: Tips and Tricks', path: '/news/gymtrainingforweightlosstipsandtricks' },
    { title: 'Gym Workouts During Pregnancy: Safety Tips', path: '/news/gymworkoutsduringpregnancysafetytips' },
    { title: 'Gym Workouts for Better Bone Density', path: '/news/gymworkoutsforbetterbonedensity' },
    { title: 'Gym Workouts for Boosting Metabolism', path: '/news/gymworkoutsforboostingmetabolism' },
    { title: 'Gym Workouts for Building Endurance', path: '/news/gymworkoutsforbuildingendurance' },
    { title: 'Gym Workouts for Building Lean Muscle', path: '/news/gymworkoutsforbuildingleanmuscle' },
    { title: 'Gym Workouts for Enhancing Flexibility', path: '/news/gymworkoutsforenhancingflexibility' },
    { title: 'Gym Workouts for Improving Agility and Speed', path: '/news/gymworkoutsforimprovingagilityandspeed' },
    { title: 'Gym Workouts for Improving Balance and Coordination', path: '/news/gymworkoutsforimprovingbalanceandcoordination' },
    { title: 'Gym Workouts for Improving Cardiovascular Health', path: '/news/gymworkoutsforimprovingcardiovascularhealth' },
    { title: 'Gym Workouts for Improving Sleep Quality', path: '/news/gymworkoutsforimprovingsleepquality' },
    { title: 'Gym Workouts for Managing Diabetes', path: '/news/gymworkoutsformanagingdiabetes' },
    { title: 'Gym Workouts for Posture Correction', path: '/news/gymworkoutsforposturecorrection' },
    { title: 'Gym Workouts for Rehabilitation and Injury Recovery', path: '/news/gymworkoutsforrehabilitationandinjuryrecovery' },
    { title: 'Gym Workouts for Seniors: Staying Fit at Any Age', path: '/news/gymworkoutsforseniorsstayingfitatanyage' },
    { title: 'Gym Workouts for Stress Relief', path: '/news/gymworkoutsforstressrelief' },
    { title: 'Gym Workouts for Teenagers: Building Healthy Habits', path: '/news/gymworkoutsforteenagersbuildinghealthyhabits' },
    { title: 'Gym Workouts Tailored for Seniors', path: '/news/gymworkoutstailoredforseniors' },
    { title: 'Gym Workouts to Improve Posture', path: '/news/gymworkoutstoimproveposture' },
    { title: 'Managing Stress Through Exercise', path: '/news/managingstressthroughexercise' },
    { title: 'Maximizing Fat Loss with High-Intensity Interval Training (HIIT)', path: '/news/maximizingfatlosswithhighintensityintervaltraininghiit' },
    { title: 'Mind-Muscle Connection: Enhancing Workout Effectiveness', path: '/news/mindmuscleconnectionenhancingworkouteffectiveness' },
    { title: 'Optimizing Fat Loss with Circuit Training', path: '/news/optimizingfatlosswithcircuittraining' },
    { title: 'Strength Training for Women: Debunking Myths', path: '/news/strengthtrainingforwomendebunkingmyths' },
    { title: 'Strength Training vs. Bodybuilding: What\'s the Difference?', path: '/news/strengthtrainingvsbodybuildingwhatsthedifference' },
    { title: 'The Benefits of Foam Rolling and Self-Myofascial Release', path: '/news/thebenefitsoffoamrollingandselfmyofascialrelease' },
    { title: 'The Benefits of High-Protein Diets in Training', path: '/news/thebenefitsofhighproteindietsintraining' },
    { title: 'The Benefits of Resistance Band Training', path: '/news/thebenefitsofresistancebandtraining' },
    { title: 'The Impact of Sleep on Muscle Recovery and Performance', path: '/news/theimpactofsleeponmusclerecoveryandperformance' },
    { title: 'The Role of Antioxidants in Muscle Recovery', path: '/news/theroleofantioxidantsinmusclerecovery' },
    { title: 'The Role of Functional Movement Screening', path: '/news/theroleoffunctionalmovementscreening' },
    { title: 'The Role of Gut Health in Fitness and Wellbeing', path: '/news/theroleofguthealthinfitnessandwellbeing' },
    { title: 'The Role of Healthy Fats in Fitness Nutrition', path: '/news/theroleofhealthyfatsinfitnessnutrition' },
    { title: 'The Role of Mental Health in Physical Fitness', path: '/news/theroleofmentalhealthinphysicalfitness' },
    { title: 'The Role of Nutrition in Achieving Fitness Goals', path: '/news/theroleofnutritioninachievingfitnessgoals' },
    { title: 'The Role of Nutrition in Fitness', path: '/news/theroleofnutritioninfitness' },
    { title: 'The Science Behind Muscle Hypertrophy', path: '/news/thesciencebehindmusclehypertrophy' },
    { title: 'Top 10 Exercises for Building Muscle Mass', path: '/news/top10exercisesforbuildingmusclemass' },
    { title: 'Top Exercises for Strengthening Your Core', path: '/news/topexercisesforstrengtheningyourcore' },
    { title: 'Training for Endurance Sports at the Gym', path: '/news/trainingforendurancesportsatthegym' },
    { title: 'Training for Functional Strength and Daily Activities', path: '/news/trainingforfunctionalstrengthanddailyactivities' },
    { title: 'Training for Obstacle Course Races (e.g., Tough Mudder)', path: '/news/trainingforobstaclecourseracesegtoughmudder' },
    { title: 'Training for Specific Sports: Tailored Gym Programs', path: '/news/trainingforspecificsportstailoredgymprograms' },
    { title: 'Training Programs for Improving Reaction Time', path: '/news/trainingprogramsforimprovingreactiontime' },
    { title: 'Training Programs for Strengthening the Immune System', path: '/news/trainingprogramsforstrengtheningtheimmunesystem' },
    { title: 'Training Strategies for Endurance Athletes', path: '/news/trainingstrategiesforenduranceathletes' },
    { title: 'Training Tips for Marathon Preparation', path: '/news/trainingtipsformarathonpreparation' },
    { title: 'The RAMP Fitness Difference', path: '/news/rampfitnesspersonaltraining' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
<h2>Benefits of Gym Training</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="news-item large-return">
        <Link to="/news" className="news-item">
          Return to News
        </Link>
      </div>
    </div>
  );
};

export default BenefitsofGymTraining;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForDementia = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Dementia | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the benefits of NDIS-funded physiotherapy for dementia at Ramp Physio and Fitness. Tailored exercise programs for improved quality of life." />
            </Helmet>

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Dementia</h1>
                    <h2>Enhancing Quality of Life Through Specialized Care</h2>
                    <p>At Ramp Physio and Fitness, we offer specialized NDIS physiotherapy services designed for individuals living with dementia. Our experienced team is dedicated to providing tailored physiotherapy that supports cognitive function, mobility, and overall well-being. Join us as we delve into the importance of physiotherapy for dementia and how we can assist in creating a positive impact on the lives of those affected by this condition.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Dementia and Physiotherapy</h1>
                        <p>Dementia is a progressive condition that affects cognitive processes such as memory, problem-solving, and emotional control. Physiotherapy plays a crucial role in managing symptoms and improving the quality of life for those with dementia. Our approach focuses on maintaining physical function, enhancing independence, and supporting mental health through movement.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Personalized NDIS Physiotherapy Plans</h1>
                        <p>Each individual's experience with dementia is unique, and so is our physiotherapy care. We develop personalized NDIS physiotherapy plans that cater to the specific needs and abilities of our clients. By incorporating exercises that focus on balance, strength, and flexibility, we aim to slow the progression of symptoms and promote a sense of achievement and autonomy.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <div className="service-intro-container3">
                <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                <p className="service-intro-text">
                    Tailored Exercise Programs for Dementia
                </p>
                <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="servicedetail-book-now"
                >
                    Book Now
                </a>
            </div>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Supporting Cognitive Health Through Movement</h1>
                    <p>Movement and exercise are not only beneficial for physical health but also for cognitive function. Our NDIS physiotherapy programs are designed to stimulate the brain and support cognitive health, incorporating activities that can be both enjoyable and therapeutic for individuals with dementia.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive Care for Dementia Patients</h1>
                    <p>We believe in a comprehensive approach to dementia care, which includes physiotherapy as an integral part. Our services extend beyond exercise to encompass education for families and caregivers, creating a supportive environment that encourages participation and engagement in daily activities.</p>
                    <p>By working closely with other NDIS service providers, we ensure a coordinated effort that maximizes the benefits of our physiotherapy programs for individuals with dementia.</p>
                </div>
            </div>
            </SkewedContainer>

            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForDementia;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unraveling the Benefits of Deep Tissue Remedial Massage</h1>
                    <h2>Discover the profound effects of deep tissue remedial massage for sports injury management and chronic pain relief at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that the road to recovery and peak physical health is multifaceted. One of the cornerstones of our therapeutic services is deep tissue remedial massage – a powerful tool in addressing sports-related injuries, chronic pain, and muscle tension. Our skilled therapists blend expert knowledge with a hands-on approach to facilitate your body's natural healing process. In this comprehensive guide, we will explore the nuances of deep tissue remedial massage, its benefits, and how it compares to other massage modalities, empowering you to make informed decisions about your health and wellbeing.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Deep Tissue Remedial Massage</h1>
                        <p>Deep tissue remedial massage is more than just a means to relax; it's a targeted therapeutic intervention designed to address specific musculoskeletal issues. Unlike general relaxation massages, our therapists use advanced techniques to reach the deeper layers of muscle tissue, connective tissue, and fascia. The goal is to alleviate pain, enhance movement, and trigger the body's natural healing response. This form of massage is particularly beneficial for athletes and individuals with chronic pain conditions, as it helps to break down scar tissue, improve circulation, and reduce inflammation.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comparing Remedial and Deep Tissue Techniques</h1>
                    <p>Understanding the nuances between various massage techniques is crucial for selecting the right treatment for your needs. Remedial massage is a systematic assessment and treatment of muscles, tendons, ligaments, and connective tissues, aimed at rehabilitating, managing, and optimizing movement. Deep tissue massage, often a component of remedial therapy, focuses specifically on realigning deeper layers of muscles and connective tissue. It is especially effective for chronic aches and contracted areas such as stiff necks, lower back pain, and tight leg muscles.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tailoring Treatment to Individual Needs</h1>
                    <p>At Ramp Physio and Fitness, we believe in a personalized approach to physiotherapy and massage therapy. Our deep tissue remedial massage treatments are tailored to the unique needs of each individual. Through a detailed assessment, our therapists identify the root causes of discomfort and develop a targeted treatment plan. Whether you're recovering from a sports injury, grappling with chronic pain, or looking to improve your physical performance, our specialized massage techniques are adapted to help you reach your health and fitness goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>PThe Healing Journey with Ramp Physio and Fitness</h1>
                    <p>Embarking on a healing journey with Ramp Physio and Fitness means you are not just receiving a massage; you are engaging in a comprehensive treatment strategy. Deep tissue remedial massage is integrated with our broader range of services, including NDIS support, chronic pain management, and group training classes. We aim to provide a holistic experience that not only addresses immediate concerns but also promotes long-term health and fitness. Our commitment to your well-being is reflected in the quality of care you receive from our dedicated team of professionals.</p>
                    <br></br>
                    <p>
                        In conclusion, deep tissue remedial massage at Ramp Physio and Fitness is a profound therapeutic practice that can significantly enhance your recovery and overall health. By understanding the differences and applications of this massage modality, you can make informed decisions about your treatment options. Our team is dedicated to guiding you through each step of your journey towards optimal health, ensuring that you receive the personalized care and support you need to thrive.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

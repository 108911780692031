import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymExercisesToStrengthenYourBackAndReducePain = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Exercises to Strengthen Your Back and Reduce Pain | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective gym exercises to strengthen your back and alleviate pain with the guidance of Ramp Physio and Fitness experts." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strengthen Your Back at the Gym</h1>
                    <h2>Explore powerful gym exercises tailored to fortify your back muscles and mitigate discomfort, curated by our Ramp Physio and Fitness professionals.</h2>
                    <p>Back pain can be debilitating, but with the right gym exercises, you can strengthen your back muscles, improve your posture, and reduce pain. At Ramp Physio and Fitness, we understand the importance of a strong and healthy back. That's why we've put together a comprehensive guide featuring gym exercises specifically designed to target back strength and alleviate pain. Let's dive into the best practices for a pain-free back and how you can incorporate these exercises into your workout routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Us Today
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Back Strengthening" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Back Pain and Core Stability</h1>
                        <p>Core stability is essential for back health. A strong core supports the spine, reduces strain on back muscles, and decreases the risk of injury. The following exercises are designed to enhance core strength, which in turn can help alleviate back pain:</p>
                        <ul>
                            <li>Planks</li>
                            <li>Dead bugs</li>
                            <li>Bird dogs</li>
                            <li>Bridge exercises</li>
                            <li>Partial crunches</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Targeted Back Strengthening</h1>
                    <p>Targeted back strengthening exercises are crucial for building the muscles that support your spine. Here are some effective gym exercises to incorporate into your routine:</p>
                    <ul>
                        <li>Lat pulldowns</li>
                        <li>Seated rows</li>
                        <li>Dumbbell rows</li>
                        <li>Back extensions</li>
                        <li>Pull-ups and chin-ups</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Training
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Movement and Flexibility</h1>
                    <p>Flexibility and proper movement are key to preventing back pain. Stretching exercises not only enhance flexibility but also promote mobility in the back. Here are some stretches to try:</p>
                    <ul>
                        <li>Child's Pose</li>
                        <li>Cat-Cow Stretch</li>
                        <li>Spinal Twist</li>
                        <li>Hamstring Stretch</li>
                        <li>Piriformis Stretch</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Functional Training for Life</h1>
                    <p>Functional training involves exercises that are similar to daily activities. This type of training can significantly benefit your back by improving balance, coordination, and strength. Here are some functional exercises to include:</p>
                    <ul>
                        <li>Squats</li>
                        <li>Lunges</li>
                        <li>Step-ups</li>
                        <li>Farmer's walk</li>
                        <li>Push-ups</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymExercisesToStrengthenYourBackAndReducePain;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Your Go-To Physiotherapy Clinic Near Me</h1>
                    <h2>Discover the ultimate destination for sports injury management, personalized physiotherapy, and fitness training at Ramp Physio and Fitness, where your health and wellness are our top priority.</h2>
                    <p>When it comes to managing sports injuries, chronic pain, or simply seeking to enhance your overall fitness, finding the right physiotherapy clinic near you is crucial. Our Physiotherapists are DVA, Medicare, Health Fund, NDIS and Work Cover approved, they specialise in the correct injury management and rehabilitation protocols to get you pain and injury free. At Ramp Physio and Fitness, we pride ourselves on providing top-tier physiotherapy and fitness services tailored to meet your individual needs. Our dedicated team of professionals is committed to helping you achieve your health and wellness goals, offering a range of services from NDIS support to after-hours physiotherapy. Let us guide you on your journey to recovery and peak performance.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Personalized Physiotherapy Services</h1>
                        <p>Finding a physiotherapy clinic that understands your unique needs can be daunting. At Ramp Physio and Fitness, we take a personalized approach to healthcare. Our physiotherapists are well-versed in the latest techniques and modalities to ensure that each treatment plan is customized to your specific condition and goals. Whether you're dealing with a recent injury or chronic pain, our goal is to provide relief and promote long-term health. We integrate evidence-based practices with a patient-centered mindset, ensuring that you're not just another number, but a valued member of the Ramp family.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>After-Hours Care for Your Convenience</h1>
                    <p>We understand that injuries and pain don't adhere to a 9-to-5 schedule. That's why after-hours physiotherapy is a critical component of our service offerings. Our clinic provides flexible hours to accommodate your busy lifestyle, ensuring that you can receive the care you need when you need it. Whether it's early morning or late evening appointments, our after-hours services mean that you never have to compromise on your health due to a tight schedule. At Ramp Physio and Fitness, we're committed to making our expert care accessible and convenient for you.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>A Hub for Sports Injury Management</h1>
                    <p>Athletes understand the importance of rapid and effective sports injury management. Our clinic specializes in diagnosing, treating, and preventing sports-related injuries. We work closely with athletes of all levels to ensure a safe and speedy return to their sport. Utilizing a combination of hands-on treatment, exercise prescription, and education, we empower our clients to take an active role in their recovery. Our physiotherapists are not only skilled clinicians but also fitness enthusiasts who understand the athlete's mindset and are dedicated to helping you get back in the game.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Group Training for Community and Motivation</h1>
                    <p>when shared with others. At Ramp Physio and Fitness, we offer group training classes that provide a supportive community atmosphere while still focusing on individual progress. These classes are designed to enhance strength, flexibility, and cardiovascular health under the guidance of our experienced trainers. By participating in group training, you benefit from the motivation and encouragement of your peers, all while our trainers ensure that you're exercising safely and effectively.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, our mission is to provide exceptional physiotherapy and fitness services that cater to your unique needs. Whether you're seeking after-hours physiotherapy, specialized sports injury management, or the camaraderie of group training classes, our clinic is your trusted partner in health and wellness. If you've been searching for a "physiotherapy clinic near me," look no further. Visit us today and take the first step toward a healthier, more active life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

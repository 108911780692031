import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Worker's Compensation</h1>
                    <h2>Expert Physiotherapy for Workers Compensation Cases</h2>
                    <p>At Ramp Physio and Fitness, we specialize in providing expert physiotherapy for individuals recovering from workplace injuries. Our team understands the complexities of workers compensation cases and is dedicated to helping you recover efficiently and safely. Whether you've suffered a minor injury or a more severe condition, our tailored treatment plans aim to restore your health and get you back to work as quickly as possible.</p>
                    <p>Suffering from a workplace injury? Contact us today to schedule a consultation and start your personalized recovery plan with our experienced physiotherapists.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Navigating Workcover: Your Rights and Options</h1>
                        <p>Workers compensation can be a complex and overwhelming process, but understanding your rights is crucial. Our team at Ramp Physio and Fitness is here to guide you through every step of your recovery journey. We work closely with your insurance provider and employer to ensure you receive the care and support you deserve. Learn more about how we can help you navigate the workers compensation system and get the treatment you need.</p>
                        <p>Unsure about your workers compensation rights? Let us help you navigate the process. Reach out to us for a consultation and learn how we can support your recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehabilitation Programs for a Safe Return to Work</h1>
                    <p>Every workplace injury is unique, which is why we offer customized rehabilitation programs tailored to your specific condition and job requirements. Our physiotherapists assess your injury and design a comprehensive plan that includes physical therapy, strength training, and ergonomic advice to ensure a safe and successful return to work. We are committed to helping you regain your strength and confidence.</p>
                    <p>Ready to start your rehabilitation journey? Contact us to create a personalized recovery plan that fits your needs and helps you get back to work safely.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Recovering from Workplace Injuries with Our Help</h1>
                    <p>Our clients' success stories highlight the effectiveness of our physiotherapy treatments for workers compensation cases. From regaining mobility to returning to work pain-free, our clients have achieved remarkable recoveries with our support. Read their testimonials and see how Ramp Physio and Fitness can help you on your road to recovery.</p>
                    <p>Be inspired by our clients' recovery stories! Start your journey to recovery with our expert physiotherapy services. Contact us today to book your appointment and take the first step toward getting back to work.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

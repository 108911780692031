import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Whatdoesatypicalndisphysiosessionlooklike = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy Sessions at Ramp Physio and Fitness</title>
                <meta name="description" content="Learn what a typical NDIS physiotherapy session involves at Ramp Physio and Fitness. Our expert team tailors treatments to your unique needs." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Understanding NDIS Physiotherapy Sessions</h1>
                    <h2>Discover the unique approach to NDIS physiotherapy sessions at Ramp Physio and Fitness, designed to empower participants with tailored, goal-oriented treatment plans.</h2>
                    <p>NDIS physiotherapy sessions are a cornerstone of the support offered to individuals living with disabilities. At Ramp Physio and Fitness, each session is meticulously planned and executed to address the specific goals and needs of the participant. Our experienced physiotherapists provide personalized care, ensuring that every individual we work with is empowered to reach their health and fitness milestones. In this article, we delve into what you can expect during a typical NDIS physiotherapy session with our dedicated team.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Initial Assessment and Goal Setting</h1>
                        <p>Every NDIS physiotherapy journey commences with a comprehensive initial assessment. This critical step involves understanding your medical history, current physical capabilities, and the challenges you face. Together, we will establish clear and achievable goals that pave the way for a customized treatment plan tailored to your aspirations.</p>

                        <p>Key components of the assessment may include:
                            <br />- Movement analysis
                            <br />- Strength and flexibility testing
                            <br />- Pain evaluation
                            <br />- Functional capacity examination
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Treatment and Therapeutic Exercise</h1>
                    <p>Following the initial assessment, our physiotherapists design a personalized treatment plan that may encompass a variety of therapeutic exercises and techniques. These are intended to improve your mobility, strength, and functional abilities, as well as manage any pain or discomfort.</p>

                    <p>Your tailored treatment may involve:
                        <br />- Manual therapy for joint mobilization
                        <br />- Targeted exercises for muscle strengthening
                        <br />- Balance and coordination activities
                        <br />- Pain management strategies
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Ongoing Evaluation and Progress Tracking</h1>
                    <p>To ensure that you are on the right track toward your goals, we regularly evaluate your progress and adjust your treatment plan accordingly. This continuous monitoring allows us to address any concerns promptly and celebrate your achievements along the way.</p>

                    <p>Progress tracking elements may include:
                        <br />- Re-assessment of physical capabilities
                        <br />- Pain level monitoring
                        <br />- Adaptation of exercise intensity and complexity
                        <br />- Feedback sessions to discuss improvements
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Independence and Self-Management</h1>
                        <p>Our role extends beyond in-clinic sessions. We strive to empower you with knowledge and skills for self-management, ensuring long-term success and independence. Education on injury prevention and home exercise programs are integral parts of our service.</p>
                        <p>Self-management support may cover:
                            <br />- Home exercise routines
                            <br />- Ergonomic advice for daily activities
                            <br />- Techniques for managing flare-ups
                            <br />- Guidance on assistive devices
                        </p>

                        <br></br>
                        <p>
                            By choosing Ramp Physio and Fitness for your NDIS physiotherapy needs, you're opting for a partner committed to your health and well-being. Our team is ready to assist you in navigating the NDIS landscape, ensuring that you receive the highest quality of care and support every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Whatdoesatypicalndisphysiosessionlooklike;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/We-Heart-NDIS_2020.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>NDIS</h1>
                    <h2>Registered NDIS Provider</h2>
                    <p>
                        We offer specialized support for NDIS participants, ensuring that you receive the care and assistance you need to achieve your health and fitness goals. Our team will work closely with you to develop a personalized plan that caters to your unique needs.
                    </p>
                    <p>Sign up with your email address to receive news and updates.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>REVOLUTIONARY APPROACH: INTEGRATING PHYSIOTHERAPY, EXERCISE SCIENCE AND HEALTHCARE.</h1>
                        <p>We believe in the power of combining exercise science with healthcare. Our unique approach allows us to deliver effective management strategies for injury rehabilitation and fitness. Whether you're seeking assistance with ndis, workers compensation, sports injury rehabilitation, or chronic condition management, our team of experts is here to guide you. Additionally, we offer group exercise mentoring and personalized training to help you achieve your fitness goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>REVOLUTIONARY APPROACH: INTEGRATING PHYSIOTHERAPY, EXERCISE SCIENCE AND HEALTHCARE.</h1>
                    <p>We believe in the power of combining exercise science with healthcare. Our unique approach allows us to deliver effective management strategies for injury rehabilitation and fitness. Whether you're seeking assistance with ndis, workers compensation, sports injury rehabilitation, or chronic condition management, our team of experts is here to guide you. Additionally, we offer group exercise mentoring and personalized training to help you achieve your fitness goals.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.
                    </p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>REVOLUTIONARY APPROACH: INTEGRATING PHYSIOTHERAPY, EXERCISE SCIENCE AND HEALTHCARE.</h1>
                    <p>We believe in the power of combining exercise science with healthcare. Our unique approach allows us to deliver effective management strategies for injury rehabilitation and fitness. Whether you're seeking assistance with ndis, workers compensation, sports injury rehabilitation, or chronic condition management, our team of experts is here to guide you. Additionally, we offer group exercise mentoring and personalized training to help you achieve your fitness goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

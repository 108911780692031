import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ExercisesForHealthyKneesAndInjuryPrevention = () => {
    return (
        <div>
            <Helmet>
                <title>Exercises for Healthy Knees and Injury Prevention | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the best exercises for knee health and injury prevention. Strengthen your knees with our expert-designed routines at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Essential Exercises for Knee Health</h1>
                    <h2>Explore our expert-curated exercises designed for knee strength and stability to prevent injuries and maintain healthy joint function.</h2>
                    <p>Knee injuries are a common concern for athletes and individuals alike, but with the right exercises, you can significantly reduce your risk. At Ramp Physio and Fitness, we prioritize knee health through a series of targeted exercises aimed at enhancing stability, strength, and flexibility. This comprehensive guide will walk you through effective routines to bolster your knee health and safeguard against common injuries.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Healthy Knees Exercises" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strengthening for Knee Stability</h1>
                        <ul>
                            <li>Quad sets for muscle engagement</li>
                            <li>Hamstring curls for balanced strength</li>
                            <li>Step-ups for functional movement</li>
                            <li>Leg presses for overall support</li>
                            <li>Lunges for dynamic stability</li>
                            <li>Squats for core and lower body synergy</li>
                            <li>Leg extensions for focused quad strengthening</li>
                            <li>Wall sits for endurance and alignment</li>
                            <li>Bridge lifts for posterior chain activation</li>
                            <li>Calf raises for lower leg support</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Flexibility for Injury Prevention</h1>
                    <p>Improving flexibility is crucial for knee injury prevention. Regular stretching can enhance joint mobility, reduce stiffness, and decrease the risk of strains or sprains. Our recommended stretches include the hamstring stretch, quadriceps stretch, calf stretch, and IT band stretch. Incorporating these into your routine can lead to a significant improvement in knee health and performance.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Knee Care Programs</h1>
                    <p>Every individual's knees are unique, which is why we provide customized knee care programs tailored to your specific needs and fitness levels. Whether you're recovering from an injury, looking to prevent future issues, or aiming to improve athletic performance, our team at Ramp Physio and Fitness will work with you to create a personalized exercise regimen that targets your goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Professional Physiotherapy Support</h1>
                        <p>At Ramp Physio and Fitness, our professional physiotherapists are dedicated to supporting your knee health journey. We provide expert guidance on proper exercise execution, injury management, and techniques to enhance your recovery. Our evidence-based approach ensures you receive the most effective care for your knees.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ExercisesForHealthyKneesAndInjuryPrevention;
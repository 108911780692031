import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Strengthtrainingvsbodybuildingwhatsthedifference = () => {
    return (
        <div>
            <Helmet>
                <title>Strength Training vs. Bodybuilding - Ramp Physio and Fitness</title>
                <meta name="description" content="Understand the differences between strength training and bodybuilding, and how each can benefit your health and fitness journey at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strength Training vs. Bodybuilding</h1>
                    <h2>Explore the unique aspects of strength training and bodybuilding, and how each discipline can sculpt your fitness journey at Ramp Physio and Fitness.</h2>
                    <p>Understanding the distinction between strength training and bodybuilding is pivotal for setting the right fitness goals. Whether you aim to enhance your physical strength or aspire to sculpt an aesthetic physique, Ramp Physio and Fitness in Homebush, NSW, offers tailored programs to help you succeed. In this comprehensive guide, we'll break down the differences and help you decide which path aligns with your personal objectives.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Strength vs. Bodybuilding" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Defining the Disciplines</h1>
                        <p>Strength training and bodybuilding, while often used interchangeably, have distinct goals and methodologies. Strength training focuses on increasing the functional strength of muscles, often for improved performance in sports or daily activities. Bodybuilding, on the other hand, is primarily concerned with aesthetics, shaping the body through muscle hypertrophy to achieve a symmetrical and proportionate appearance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Training Techniques Compared</h1>
                    <p>While both disciplines involve lifting weights, the techniques and training regimens differ. Strength trainers often engage in compound movements like squats, deadlifts, and bench presses, with the aim of lifting the maximum weight for fewer repetitions. Bodybuilders typically perform isolation exercises targeting specific muscle groups with higher repetitions to maximize muscle pump and growth.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Physiological Outcomes</h1>
                    <p>Strength training often results in increased muscle density and neural efficiency, leading to greater overall power. Bodybuilding promotes muscle hypertrophy, which is the enlargement of muscle fibers for a more defined and voluminous look. It's important to note that both forms of training can contribute to better health, including improved metabolism, bone density, and mental well-being.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Nutrition and Recovery</h1>
                    <p>Nutrition plays a critical role in both strength training and bodybuilding. Strength athletes may focus on a diet that supports recovery and maximizes energy for heavy lifting, while bodybuilders often employ a more meticulous approach to macronutrient balance to fine-tune their physique. Recovery protocols, including rest periods and supplementation, are also tailored to suit the demands of each training style.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, our experts can provide personalized nutrition and recovery plans to complement your chosen discipline and ensure you're getting the most out of your efforts.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Strengthtrainingvsbodybuildingwhatsthedifference;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingIntermittentFastingAndFitness = () => {
    return (
        <div>
            <Helmet>
                <title>Understanding Intermittent Fasting and Fitness | Ramp Physio and Fitness</title>
                <meta name="description" content="Unlock the synergy between intermittent fasting and fitness for optimal health and performance with expert guidance from Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Intermittent Fasting and Fitness Synergy</h1>
                    <h2>Explore the dynamic relationship between intermittent fasting and fitness routines to enhance your health and performance at Ramp Physio and Fitness.</h2>
                    <p>Intermittent fasting (IF) has gained popularity as a potent lifestyle strategy that can complement fitness routines. At Ramp Physio and Fitness, we understand the intricacies of merging dietary patterns with workout regimens. This article will delve into how intermittent fasting can be aligned with your fitness goals to foster a healthier, more vibrant you.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Intermittent Fasting and Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Intermittent Fasting</h1>
                        <p>Intermittent fasting is not just a diet; it's a timed approach to eating. Unlike other diets that specify what to eat, IF dictates when to eat. There are several methods, including the 16/8 method, Eat-Stop-Eat, and the 5:2 diet, each with its own set of rules and benefits. By cycling between periods of fasting and eating, IF can lead to various health benefits, such as weight loss, improved metabolic health, and even longevity.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Fasting and Exercise: A Powerful Duo</h1>
                    <p>Combining IF with exercise can amplify the benefits of both. Exercising during your fasting window may enhance fat oxidation and promote greater metabolic adaptations. However, it's essential to understand your body's response to fasting and adjust your workout intensity and duration accordingly. At Ramp Physio and Fitness, we can help tailor a plan that syncs your fasting schedule with your fitness regimen for optimal results.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Contact Us
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Navigating Nutritional Needs</h1>
                    <p>Understanding the nutritional demands of your body is crucial when practicing IF alongside a fitness routine. Ensuring you consume nutrient-dense foods during your eating windows will support your workouts and recovery. Our team at Ramp Physio and Fitness can guide you on the right mix of macronutrients and hydration to complement your fasting regime.</p>
                    <ul>
                        <li>Optimal meal timing for workout days</li>
                        <li>Hydration strategies during fasting</li>
                        <li>Balancing macronutrients for recovery</li>
                        <li>Importance of micronutrients and fiber</li>
                        <li>Managing hunger cues and energy levels</li>
                        <li>Pre- and post-workout nutrition</li>
                        <li>Supplements that align with IF</li>
                        <li>Adapting meal plans for rest days</li>
                        <li>Monitoring body's response to diet and exercise</li>
                        <li>Personalized nutrition coaching</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Intermittent Fasting for Athletes</h1>
                    <p>Athletes may find IF particularly beneficial for reducing body fat while maintaining muscle mass. The strategic approach can improve insulin sensitivity, aid in weight management, and potentially enhance endurance. Our team at Ramp Physio and Fitness is equipped to help athletes integrate IF into their training plans safely and effectively.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingIntermittentFastingAndFitness;
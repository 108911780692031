import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating Recovery from an ACL Tear</h1>
                    <h2>This comprehensive guide provides insights into the diagnosis, treatment, and rehabilitation of an ACL tear, aiding patients on their journey to recovery. Explore expert physiotherapy approaches and fitness strategies for optimal healing at Ramp Physio and Fitness.</h2>
                    <p>An ACL tear is a common yet challenging injury, particularly prevalent among athletes and physically active individuals. As a dedicated physiotherapist at Ramp Physio and Fitness, I understand the intricacies of this condition and the impact it has on your life. This article aims to shed light on the ACL tear, from its diagnosis to the advanced rehabilitative strategies we employ, ensuring you receive the support and care necessary for a successful recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding the ACL and Its Injury Mechanism</h1>
                        <p>The anterior cruciate ligament (ACL) is one of the key ligaments that help stabilize your knee joint. Injuries to the ACL can range from mild sprains to complete tears and are often the result of sudden stops, changes in direction, or direct impacts. Recognizing the symptoms, such as a distinct popping sound, immediate pain, and swelling, is crucial for early intervention. Our approach emphasizes prompt and accurate diagnosis, utilizing clinical examination and imaging techniques, ensuring that each patient receives a tailored treatment plan.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Treatment Options: Conservative vs. Surgical</h1>
                        <p>Once an ACL tear is confirmed, the decision between conservative management and surgical intervention must be made. Factors influencing this decision include the severity of the tear, the patient's activity level, and personal goals. Conservative treatments may involve bracing and physiotherapy, focusing on reducing pain and swelling while improving range of motion and strength. Surgical options, typically recommended for complete tears or those leading to knee instability, involve reconstructing the torn ligament. At Ramp Physio and Fitness, we provide comprehensive care regardless of the treatment path chosen, supporting you every step of the way.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Rehabilitation: A Journey to Full Function</h1>
                        <p>Rehabilitation is a critical component of recovery, whether you've undergone surgery or are following a conservative treatment plan. Our physiotherapy program is designed to restore function, strength, and confidence in your knee. We progress through phases, starting with pain and swelling management, and gradually introducing exercises to improve mobility, strength, and proprioception. Our personalized training ensures that you not only recover from an ACL tear but also prevent future injuries through education and conditioning.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Returning to Sport and Daily Activities</h1>
                    <p>The ultimate goal for many patients is to return to their pre-injury levels of activity, whether that's competitive sports, recreational activities, or daily tasks. Our team at Ramp Physio and Fitness is committed to guiding you through a safe and effective return-to-sport program. By assessing your readiness, focusing on sport-specific training, and optimizing movement patterns, we ensure that your return is both successful and sustainable. We stand by you, celebrating each milestone achieved and encouraging a healthy, active lifestyle long after recovery.</p>
                    <br></br>
                    <p>
                        In conclusion, an ACL tear is a significant injury that requires a comprehensive approach to treatment and rehabilitation. At Ramp Physio and Fitness, we are dedicated to providing top-tier care that addresses every aspect of your recovery. Our personalized and evidence-based strategies are designed to help you regain strength, functionality, and confidence, allowing you to return to the activities you love with resilience and vigor.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating the Path to Recovery: Neurological Physiotherapy</h1>
                    <h2>Discover the transformative power of neurological physiotherapy in managing complex conditions affecting the brain, spinal cord, and nerves. This comprehensive guide explores the innovative treatments and personalized care strategies available at Ramp Physio and Fitness.</h2>
                    <p>In the intricate tapestry of healthcare, neurological physiotherapy stands out as a specialized and highly impactful field, dedicated to the rehabilitation of individuals with neurological conditions. At Ramp Physio and Fitness, we recognize the unique challenges that these conditions present and the profound effect they can have on a person's life. Our commitment is to provide compassionate, evidence-based care that empowers our patients to navigate their recovery journey with confidence and support.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Neurological Physiotherapy</h1>
                        <p>Neurological physiotherapy is a specialized branch of physiotherapy focusing on the assessment and treatment of individuals with conditions that affect the brain, spinal cord, and peripheral nerves. It is grounded in the understanding that these conditions can lead to a wide array of physical impairments, including muscle weakness, loss of balance and coordination, spasticity, and difficulty with functional movement.</p>
                        <p>At Ramp Physio and Fitness, our neurological physiotherapy program is designed to address these challenges head-on. We utilize a patient- centered approach, tailoring each treatment plan to the individual's specific needs and goals. Our therapists are not only trained in the latest techniques but are also compassionate professionals who recognize the importance of emotional support in the healing process.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Conditions That Benefit from Neurological Physiotherapy</h1>
                    <p>ONeurological physiotherapy offers hope and tangible improvements to individuals facing a range of neurological conditions. Some of the conditions we commonly treat include stroke, traumatic brain injury, Parkinson's disease, multiple sclerosis, and spinal cord injuries. Each of these conditions presents its own set of challenges, and our therapists are adept at creating targeted strategies to meet these diverse needs.</p>
                    <p>Through a thorough assessment, we identify the primary areas of concern, such as mobility, strength, balance, or coordination. We then develop a comprehensive treatment plan that may include hands-on therapy, exercise prescription, and the use of assistive technology to promote independence and enhance quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Innovative Treatment Approaches</h1>
                    <p>RIn the realm of neurological physiotherapy, innovation is key. At Ramp Physio and Fitness, we stay at the forefront of the latest research and advances in treatment to ensure that our patients receive the most effective care possible. Our therapists are skilled in a variety of techniques, including but not limited to, motor relearning programs, constraint-induced movement therapy, and neuroplasticity-promoting activities.</p>
                    <p>Moreover, we harness the power of technology, incorporating equipment such as functional electrical stimulation and virtual reality systems to create engaging and effective treatment sessions. These tools not only facilitate the rehabilitation process but also provide valuable feedback and motivation for our patients.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Care and Ongoing Support</h1>
                    <p>The journey to recovery from a neurological condition is often long and requires sustained effort and support. At Ramp Physio and Fitness, we are committed to walking alongside our patients every step of the way. Our personalized care extends beyond the walls of our clinic, as we provide education and resources for patients and their families to manage their condition at home effectively.</p>
                    <p>We understand that each patient's path to recovery is unique, and we continuously adapt our treatment plans to reflect their evolving needs. Our goal is to foster an environment of growth, resilience, and hope, where patients feel empowered to reach their full potential.</p>
                    <br></br>
                    <p>
                        Neurological physiotherapy is more than just a treatment modality; it is a beacon of hope for those navigating the complexities of neurological conditions. At Ramp Physio and Fitness, our dedicated team of therapists is committed to providing the highest standard of care, combining expertise with compassion to support our patients in their recovery journey. Whether you are dealing with the aftermath of a stroke, living with a chronic neurological condition, or seeking to improve your overall function, we are here to help you ramp up your health and fitness, one step at a time.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

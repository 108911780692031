import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterFemurFracture = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Rehabilitation After Femur Fracture</h1>
                    <h2>Expert guidance and personalized rehabilitation programs for femur fracture recovery at Ramp Physio and Fitness.</h2>
                    <p>Recovering from a femur fracture can be a challenging journey, but with the right rehabilitation program, you can regain strength, mobility, and function. At Ramp Physio and Fitness, we specialize in providing comprehensive post-operative care and rehabilitation for individuals who have undergone femur fracture repair. In this article, we will explore the critical steps and therapeutic interventions that form the cornerstone of effective femur fracture rehabilitation.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Femur Fracture Recovery</h1>
                        <p>The femur, or thigh bone, is the largest and strongest bone in the body, and recovering from a fracture to this bone involves a multidisciplinary approach. Rehabilitation after femur fracture repair typically begins with managing pain and inflammation, followed by gradually increasing activity levels to restore function. Here are the key elements of our femur fracture rehab program:</p>
                        <ul>
                            <li>Pain management and inflammation control</li>
                            <li>Weight-bearing and mobility exercises</li>
                            <li>Strengthening and conditioning</li>
                            <li>Balance and gait training</li>
                            <li>Functional and occupational therapy</li>
                            <li>Personalized home exercise programs</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Post-Operative Care Essentials</h1>
                    <p>After femur fracture repair surgery, it's crucial to adhere to post-operative care instructions to ensure a successful recovery. Our team provides tailored guidance to help patients navigate this critical phase, which includes:</p>
                        <ul>
                            <li>Follow-up appointments and monitoring</li>
                            <li>Incision care and wound management</li>
                            <li>Medication management for pain relief</li>
                            <li>Use of assistive devices for mobility</li>
                            <li>Dietary recommendations for bone health</li>
                            <li>Prevention of complications such as DVT</li>
                        </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strengthening and Mobility Work</h1>
                    <p>As part of the rehabilitation process, we focus on exercises that help restore strength and mobility. These may include:</p>
                        <ul>
                            <li>Isometric exercises to build muscle strength</li>
                            <li>Range of motion exercises for joint flexibility</li>
                            <li>Partial weight-bearing exercises to support recovery</li>
                            <li>Resistance training to improve muscle tone</li>
                            <li>Hydrotherapy for low-impact rehabilitation</li>
                            <li>Neuromuscular electrical stimulation (NMES)</li>
                        </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Progressing Towards Full Recovery</h1>
                    <p>Our goal is to guide patients through a gradual progression that leads to full recovery and a return to normal activities. This includes:</p>
                        <ul>
                            <li>Advanced balance and proprioception exercises</li>
                            <li>Sports-specific training for athletes</li>
                            <li>Education on injury prevention and lifestyle modifications</li>
                            <li>Community reintegration activities</li>
                            <li>Long-term fitness and wellness planning</li>
                            <li>Regular assessments to track progress and adapt the program</li>
                        </ul>
                    <br></br>
                    <p>
                        In conclusion, rehabilitation after a femur fracture is a comprehensive process that requires dedication and expert care. At Ramp Physio and Fitness, we are committed to providing the highest quality of rehabilitation services to ensure you achieve the best possible outcome. Trust us to be your partner in recovery and help you return to the life you love.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterFemurFracture;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const KeyTreatmentsForNDIS = () => {
  const articles = [
    { title: 'NDIS Physiotherapy Assisted Stretching to Relieve Stiffness in Joints', path: '/news/ndisphysiotherapyassistedstretchingtorelievestiffnessinjoints' },
    { title: 'NDIS Physiotherapy Electrotherapy and Heat Therapy to Reduce Inflammation', path: '/news/ndisphysiotherapyelectrotherapyandheattherapytoreduceinflammation' },
    { title: 'NDIS Physiotherapy Functional Task Training Including Balancing and Movement', path: '/news/ndisphysiotherapyfunctionaltasktrainingincludingbalancingandmovement' },
    { title: 'NDIS Physiotherapy Improving Mood and Energy Levels', path: '/news/ndisphysiotherapyimprovingmoodandenergylevels' },
    { title: 'NDIS Physiotherapy Strengthening Exercises to Boost Muscle Condition and Posture', path: '/news/ndisphysiotherapystrengtheningexercisestoboostmuscleconditionandposture' },
    { title: 'NDIS Physiotherapy Tissues Massages to Break Up Tension in Muscles', path: '/news/ndisphysiotherapytissuesmassagestobreakuptensioninmuscles' },
    { title: 'NDIS Physiotherapy Training Capability in Handling Everyday Actions and Tasks', path: '/news/ndisphysiotherapytrainingcapabilityinhandlingeverydayactionsandtasks' },
    { title: 'NDIS Physiotherapy for NDIS Physiotherapy', path: '/news/ndisphysiotherapyforndisphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Paediatric Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforpaediatricphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Post-Natal Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforpostnatalphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Post Surgical Rehabilitation', path: '/news/physiotherapytreatmentandexercisesforpostsurgicalrehabilitation' },
    { title: 'Physiotherapy Treatment and Exercises for Posture Correction', path: '/news/physiotherapytreatmentandexercisesforposturecorrection' },
    { title: 'Physiotherapy Treatment and Exercises for Pregnancy Care', path: '/news/physiotherapytreatmentandexercisesforpregnancycare' },
    { title: 'Physiotherapy Treatment and Exercises for Remedial Massage', path: '/news/physiotherapytreatmentandexercisesforremedialmassage' },
    { title: 'Physiotherapy Treatment and Exercises for Sciatica', path: '/news/physiotherapytreatmentandexercisesforsciatica' },
    { title: 'Physiotherapy Treatment and Exercises for What we treat', path: '/news/physiotherapytreatmentandexercisesforwhatwetreat' },
    { title: 'Physiotherapy Treatment and Exercises for Women\'s Health Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforwomenshealthphysiotherapy' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
<h2>Key Treatments for NDIS</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyTreatmentsForNDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheBenefitsOfFunctionalMovementScreening = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unlock Performance with Functional Movement Screening</h1>
                    <h2>Explore the transformative power of Functional Movement Screening at Ramp Physio and Fitness to enhance your training, prevent injuries, and optimize your physical capabilities.</h2>
                    <p>Functional Movement Screening (FMS) is a game-changer in the world of physiotherapy and fitness. It's a comprehensive evaluation designed to assess movement patterns that are key to normal function. By identifying limitations and asymmetries, FMS pinpoints areas that require targeted intervention. At Ramp Physio and Fitness, we leverage the benefits of FMS to tailor fitness programs and prevent injuries, ensuring you can train smarter and perform better.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>What is Functional Movement Screening?</h1>
                        <p>The Functional Movement Screen is a standardized approach used to evaluate movement pattern quality for clients. It is not intended to diagnose orthopedic problems but rather to demonstrate opportunities for improved movement in individuals. FMS assesses seven fundamental movement patterns that are key to functional movement quality in individuals without current pain complaint or musculoskeletal injury. These movements provide observable performance of basic loco motor, manipulative and stabilizing movements by placing an individual in positions where weaknesses and imbalances become noticeable when appropriate mobility and motor control is not utilized.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maximize Your Training Efficiency</h1>
                    <p>With FMS, our experts at Ramp Physio and Fitness can design a fitness program that aligns with your body's specific needs. By focusing on your unique movement patterns, we can enhance your training efficiency, leading to better performance and fewer injuries. Here are the benefits of incorporating FMS into your training regimen:</p>
                    <ul>
                        <li>Personalized exercise programs</li>
                        <li>Reduced risk of injury</li>
                        <li>Improved athletic performance</li>
                        <li>Greater body awareness and control</li>
                        <li>Enhanced functional mobility and stability</li>
                        <li>Targeted interventions for weak areas</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Preventing Injuries with FMS</h1>
                    <p>One of the greatest benefits of Functional Movement Screening is its ability to prevent injuries before they occur. By identifying asymmetries and limitations, FMS allows for corrective exercises that reduce the risk of injury. This proactive approach is crucial for athletes and individuals who engage in regular physical activity. Here are key ways FMS contributes to injury prevention:</p>
                    <ul>
                        <li>Early detection of movement dysfunctions</li>
                        <li>Guidance on proper exercise selection</li>
                        <li>Development of safer movement patterns</li>
                        <li>Reduction of compensatory behaviors</li>
                        <li>Focus on building strength in underactive muscles</li>
                        <li>Enhancement of joint mobility and stability</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating FMS into Your Wellness Plan</h1>
                    <p>At Ramp Physio and Fitness, we believe that wellness extends beyond the gym. Integrating FMS into your wellness plan is a step towards a more comprehensive understanding of your body and its needs. Whether you're an elite athlete or someone looking to improve your general fitness, FMS can help you achieve your goals with greater precision and less risk of injury. Let us guide you through the FMS process and discover how it can benefit your overall wellness.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheBenefitsOfFunctionalMovementScreening;
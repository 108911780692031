import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForAnkylosingSpondylitis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empower Your Movement: Ankylosing Spondylitis Physiotherapy</h1>
                    <h2>Unlock the potential of physiotherapy for managing Ankylosing Spondylitis at Ramp Physio and Fitness, where our tailored approach helps you maintain mobility and improve your quality of life.</h2>
                    <p>Living with Ankylosing Spondylitis (AS) can be challenging, but with the right physiotherapy approach, you can manage your symptoms and maintain an active lifestyle. At Ramp Physio and Fitness, we specialize in providing personalized physiotherapy programs designed to address the unique needs of individuals with AS. This article explores the benefits of physiotherapy for AS and how our expert team can support you in achieving your health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Ankylosing Spondylitis</h1>
                        <p>Ankylosing Spondylitis is a form of arthritis primarily affecting the spine, though it can also impact other joints. It leads to inflammation, pain, and in severe cases, fusion of the vertebrae. Early intervention and targeted physiotherapy can play a significant role in managing AS symptoms and slowing disease progression. Our physiotherapy programs are designed to keep your joints flexible, improve posture, and decrease pain.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Techniques for AS</h1>
                    <p>Our physiotherapy techniques for Ankylosing Spondylitis include a combination of exercises, manual therapy, and education to help manage your condition. Here are some of the key components of our AS physiotherapy program:</p>
                    <ul>
                        <li>Stretching and strengthening exercises to maintain flexibility and muscle strength.</li>
                        <li>Postural training to prevent spinal deformity.</li>
                        <li>Breathing exercises to enhance lung capacity.</li>
                        <li>Pain relief techniques such as heat therapy and electrotherapy.</li>
                        <li>Hydrotherapy for gentle resistance and relaxation of stiff muscles.</li>
                        <li>Education on self-management strategies for daily living.</li>
                        <li>Tailored exercise programs that adapt as your condition changes.</li>
                        <li>Guidance on activity modification to reduce strain on your joints.</li>
                        <li>Regular monitoring and adjustment of your treatment plan.</li>
                        <li>Support and motivation to stay active and engaged in your treatment.</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of Physiotherapy for AS</h1>
                    <p>Engaging in regular physiotherapy offers numerous benefits for individuals with Ankylosing Spondylitis. It can help to:</p>
                    <ul>
                        <li>Reduce pain and stiffness in the affected joints.</li>
                        <li>Improve flexibility and range of motion.</li>
                        <li>Strengthen muscles supporting the spine.</li>
                        <li>Enhance cardiovascular health through aerobic exercise.</li>
                        <li>Decrease the risk of disability by maintaining spine mobility.</li>
                        <li>Improve overall well-being and quality of life.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Physiotherapy into Your Life</h1>
                    <p>At Ramp Physio and Fitness, we understand that integrating physiotherapy into your daily routine is key to managing Ankylosing Spondylitis effectively. We work with you to create a sustainable and enjoyable program that fits into your lifestyle, helping you to stay committed to your long-term health. Our team is here to support you every step of the way, from initial assessment to ongoing treatment and beyond.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy is an essential element in managing Ankylosing Spondylitis. With our expert guidance and your dedication, you can take control of your condition and lead a fulfilling life. Experience the transformative power of physiotherapy at Ramp Physio and Fitness and reclaim your mobility and independence.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForAnkylosingSpondylitis;
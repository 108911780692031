import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const AdaptingWorkoutsForChronicConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Adapting Workouts for Chronic Conditions - Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to adapt workouts for chronic conditions with expert guidance from Ramp Physio and Fitness. Achieve your health and fitness goals safely." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Adapting Workouts for Chronic Conditions</h1>
                    <h2>Unlock the potential of personalized fitness for managing chronic conditions at Ramp Physio and Fitness, where your health is our priority.</h2>
                    <p>At Ramp Physio and Fitness, we understand the challenges faced by individuals with chronic conditions when it comes to exercise. Our goal is to help you safely achieve your health and fitness goals by adapting workouts to meet your unique needs. This article will explore the importance of personalized fitness programs and how they can be tailored to effectively manage various chronic conditions.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Conditions</h1>
                        <p>Chronic conditions such as diabetes, heart disease, and arthritis require special consideration when it comes to exercise. The right workout plan can not only improve overall health but also manage symptoms and reduce the risk of complications. Here at Ramp Physio and Fitness, we prioritize an evidence-based approach to crafting workout regimes that align with the latest medical guidance for chronic disease management.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Exercise Programs</h1>
                    <p>Each individual with a chronic condition has unique needs and limitations. Our team of experts specializes in creating personalized exercise programs that cater to these differences. We take into account factors such as your current fitness level, medical history, and personal goals to ensure that your workout plan is not only safe but also effective in managing your condition.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Safe Exercise Strategies</h1>
                    <p>Understanding how to exercise safely with a chronic condition is crucial. Our physiotherapists and fitness specialists are trained to develop strategies that minimize the risk of injury and exacerbation of symptoms. This includes monitoring intensity levels, incorporating rest periods, and selecting exercises that target specific health outcomes without overtaxing the body.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Adapted Workouts</h1>
                        <p>Adapted workouts offer numerous benefits for individuals with chronic conditions. From improving cardiovascular health and increasing muscle strength to enhancing flexibility and reducing stress, the advantages are vast. At Ramp Physio and Fitness, we aim to help you experience these benefits through carefully designed exercise plans that respect your body's capabilities.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Examples of Adapted Exercises</h1>
                    <ul>
                        <li>Low-impact cardio for heart health</li>
                        <li>Strength training with resistance bands</li>
                        <li>Flexibility and mobility training programs</li>
                        <li>Water aerobics to reduce joint stress</li>
                        <li>Pilates for core strength and stability</li>
                        <li>Walking programs for endurance building</li>
                        <li>Tai Chi for balance and coordination</li>
                        <li>Chair exercises for limited mobility</li>
                        <li>Isometric exercises for muscle engagement</li>
                        <li>Interval training for metabolic control</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default AdaptingWorkoutsForChronicConditions;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TrainingForFunctionalStrengthAndDailyActivities = () => {
    return (
        <div>
            <Helmet>
                <title>Training for Functional Strength and Daily Activities | Ramp Physio and Fitness</title>
                <meta name="description" content="Unlock the potential of functional strength training for enhanced daily activities with Ramp Physio and Fitness. Our expert team guides you through personalized workout plans tailored to your lifestyle needs." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Functional Strength for Everyday Vitality</h1>
                    <h2>Embrace the power of functional strength training to enhance your daily life with Ramp Physio and Fitness. Our personalized programs are designed to build strength that matters for your everyday activities.</h2>
                    <p>At Ramp Physio and Fitness, we understand that the ultimate goal of fitness is to improve the quality of your daily life. That's why our functional strength training programs are specifically designed to help you build the strength needed for your day-to-day activities. From carrying groceries to playing with your kids, our expert team crafts workout routines that align with real-world movements and scenarios. In this comprehensive guide, we'll explore the benefits of functional strength training and how it can be seamlessly integrated into your lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Training
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Functional Strength Training" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Merging Fitness with Functionality</h1>
                        <p>Functional strength training goes beyond traditional weightlifting. It's about preparing your body for the various tasks and challenges you face outside the gym. By mimicking natural movements, such as squatting, lifting, and reaching, our programs help enhance your muscular balance and stability, making everyday activities easier and reducing the risk of injury. Discover the best exercises for functional strength and how they can transform your physical capabilities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Building a Foundation for Life</h1>
                    <p>Functional strength training is the cornerstone of a healthy and active lifestyle. Our approach is rooted in the latest research, which shows that functional exercises not only improve muscle strength but also enhance coordination and proprioception. This foundation is crucial for performing daily tasks with ease and precision. Learn about the key principles of functional training and how we apply them to create effective workout plans tailored to your individual needs.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Up Your Daily Performance
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Your Session
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Difference in Training Approaches</h1>
                    <p>Understanding the difference between traditional strength training and functional strength training is key to recognizing the value each brings to your fitness journey. While traditional strength training focuses on increasing muscle size and power, functional strength training emphasizes the ability to perform everyday activities with greater ease. Explore the unique advantages of functional training and how it complements conventional strength training methods.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Practical Workouts for Real Results</h1>
                        <p>At Ramp Physio and Fitness, we prioritize workouts that yield practical results. Our functional training sessions are crafted to improve your strength in ways that matter for your daily life. From carrying heavy items to maintaining balance on uneven surfaces, our exercises are designed to simulate real-life scenarios. Here are 10 examples of functional exercises that you can incorporate into your routine for optimal daily performance:</p>
                        <ul>
                            <li>Squats for lifting and bending</li>
                            <li>Deadlifts for back strength and posture</li>
                            <li>Lunges for lower body stability</li>
                            <li>Push-ups for upper body endurance</li>
                            <li>Planks for core strength and stability</li>
                            <li>Step-ups for stair climbing efficiency</li>
                            <li>Farmer's walks for grip and carry tasks</li>
                            <li>Medicine ball throws for explosive power</li>
                            <li>Battle ropes for cardiovascular fitness</li>
                            <li>TRX rows for back and arm strength</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TrainingForFunctionalStrengthAndDailyActivities;
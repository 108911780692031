import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const HowToOvercomeGymAnxiety = () => {
    return (
        <div>
            <Helmet>
                <title>Overcoming Gym Anxiety | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to overcome gym anxiety with expert tips and strategies from Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Conquer Your Gym Anxiety</h1>
                    <h2>Master your mindset and overcome gym anxiety with our personalized support and guidance at Ramp Physio and Fitness.</h2>
                    <p>Feeling anxious about stepping into the gym is a common experience for many. But at Ramp Physio and Fitness, we believe that your fitness journey should be empowering, not intimidating. That's why we've compiled expert advice and strategies to help you overcome gym anxiety and embrace a healthier, more confident lifestyle. In this comprehensive guide, we'll explore practical steps to conquer gym fears, boost your confidence, and make the most of your workouts.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Us Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Gym Anxiety</h1>
                        <p>Gym anxiety, or 'gymtimidation', can stem from various concerns, such as feeling judged, not knowing how to use equipment, or navigating a new social environment. Understanding these underlying factors is the first step toward overcoming the apprehension associated with gym culture. At Ramp Physio and Fitness, we cultivate an inclusive atmosphere where every member can feel comfortable and supported regardless of their fitness level or experience.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Simple Steps to Start</h1>
                    <p>Beginning your gym journey doesn't have to be daunting. Start with simple steps: familiarize yourself with the layout, understand the equipment, and set manageable goals. At Ramp Physio and Fitness, our trainers are here to guide you through every step, ensuring you feel comfortable and confident as you start your fitness journey. Here are some key tips:</p>
                    <ul>
                        <li>Take a tour of the facility to get your bearings.</li>
                        <li>Ask for an equipment demonstration from our friendly staff.</li>
                        <li>Set realistic fitness goals to keep you motivated.</li>
                        <li>Consider a personal training session to build a customized plan.</li>
                        <li>Try out different gym times to find a less crowded environment.</li>
                        <li>Begin with exercises you enjoy to build a positive association.</li>
                        <li>Focus on your workout and personal progress, not others.</li>
                        <li>Use music or podcasts to help you relax and focus.</li>
                        <li>Remember that everyone was a beginner at some point.</li>
                        <li>Join group classes to find community and support.</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Us Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Building Confidence and Competence</h1>
                    <p>Confidence in the gym comes with time and practice. At Ramp Physio and Fitness, we are dedicated to helping you build both confidence and competence. With a range of training programs and personalized coaching, you'll quickly move from feeling anxious to feeling at home in the gym. Remember, your journey is unique, and every small victory is a step towards overcoming gym anxiety.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maintaining Momentum</h1>
                    <p>Once you've taken the first steps to tackle gym anxiety, maintaining momentum is key. Celebrate your progress, stay consistent with your routine, and continue to challenge yourself with new goals. At Ramp Physio and Fitness, we're committed to supporting your ongoing growth and ensuring that gym anxiety becomes a thing of the past. Join our community and let us help you transform your gym experience into a positive and empowering aspect of your life.</p>
                    <br></br>
                    <p>
                        In conclusion, overcoming gym anxiety is a journey that requires patience, understanding, and support. At Ramp Physio and Fitness, we're here to provide you with the tools, resources, and community you need to conquer your fears and thrive in the gym environment. Whether you're a seasoned athlete or just starting out, we believe in your potential to overcome any challenge and achieve your health and fitness goals. Let's tackle gym anxiety together and unlock your true potential.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default HowToOvercomeGymAnxiety;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Advancedtrainingtechniquessupersetsdropsetsandmore = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Advanced Training Techniques</h1>
                    <h2>Unlock your full potential with advanced training techniques like Supersets, Drop Sets, and more at Ramp Physio and Fitness.</h2>
                    <p>Pushing past fitness plateaus requires innovation and dedication. At Ramp Physio and Fitness, we embrace advanced training techniques that challenge your body in new ways, leading to greater strength gains and improved muscle hypertrophy. This article explores the science and application of advanced techniques such as Supersets, Drop Sets, and other methods designed to intensify your workouts and maximize results.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Supersets for Intensity</h1>
                        <p>Supersets involve performing two exercises back-to-back with no rest in between. This technique not only saves time but also increases the intensity of your workout, leading to greater endurance and muscle size. Supersets can be structured in various ways, such as targeting the same muscle group to fatigue the muscles or alternating between opposing muscle groups to allow one to recover while the other works.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Drop Sets for Muscle Growth</h1>
                    <p>Drop Sets are a powerful tool for breaking through muscle-building plateaus. By continuing an exercise with a lighter weight after reaching muscle fatigue, you can push beyond conventional limits and stimulate hypertrophy. Drop Sets can be especially effective for bodybuilders and those looking to increase muscle mass quickly.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>More Techniques for Progress</h1>
                    <p>Beyond Supersets and Drop Sets, there are many other advanced techniques to incorporate into your training regimen. These include Giant Sets, Pre-Exhaustion, Post-Exhaustion, and Pyramid Sets. Each method has its unique benefits and can be tailored to your fitness goals, whether you're seeking strength, size, or endurance.</p>
                    <ul>
                        <li>Giant Sets: Circuit-style training targeting one muscle group.</li>
                        <li>Pre-Exhaustion: Fatigue a primary muscle with an isolation exercise before a compound movement.</li>
                        <li>Post-Exhaustion: Reverse the Pre-Exhaustion technique by starting with a compound movement.</li>
                        <li>Pyramid Sets: Gradually increasing or decreasing weight with each set.</li>
                        <li>Negative Sets: Focusing on the eccentric contraction of the muscle.</li>
                        <li>Partial Reps: Performing an exercise within a limited range of motion.</li>
                        <li>Forced Reps: Completing additional reps with assistance after muscle fatigue.</li>
                        <li>Cluster Sets: Incorporating short rest periods within a set for more reps.</li>
                        <li>Cheating Reps: Using momentum to complete a few final reps of an exercise.</li>
                        <li>Rest-Pause: Taking brief rest breaks to squeeze out more reps.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Training Programs</h1>
                    <p>At Ramp Physio and Fitness, we understand that one size does not fit all when it comes to training. Our expert trainers will work with you to create a customized program that incorporates advanced techniques suited to your individual needs and goals. Whether you're an athlete, a fitness enthusiast, or someone looking to get in shape, we have the expertise to elevate your training.</p>
                    <br></br>
                    <p>
                        We invite you to join us at Ramp Physio and Fitness to experience the transformative power of advanced training techniques. Our commitment to your success is unwavering, and we're here to support you every step of the way. Let's unlock your potential together and achieve the health and fitness goals you've been aiming for. Book your session now and take the first step towards a stronger, fitter you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Advancedtrainingtechniquessupersetsdropsetsandmore;
import React, { useState } from 'react';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const GymKnowledge = () => {
  const articles = [
    { title: 'Adapting Gym Workouts for Home Exercise', path: '/news/adaptinggymworkoutsforhomeexercise' },
    { title: 'Adapting Workouts for Chronic Conditions', path: '/news/adaptingworkoutsforchronicconditions' },
    { title: 'Adapting Workouts for Injuries and Physical Limitations', path: '/news/adaptingworkoutsforinjuriesandphysicallimitations' },
    { title: 'Advanced Training Techniques: Supersets, Drop Sets, and More', path: '/news/advancedtrainingtechniquessupersetsdropsetsandmore' },
    { title: 'Balancing Cardio and Strength Training', path: '/news/balancingcardioandstrengthtraining' },
    { title: 'Beginner\'s Guide to Starting a Gym Routine', path: '/news/beginnersguidetostartingagymroutine' },
    { title: 'Building an Effective Warm-Up and Cool-Down Routine', path: '/news/buildinganeffectivewarmupandcooldownroutine' },
    { title: 'Building Mind-Muscle Connection for Better Results', path: '/news/buildingmindmuscleconnectionforbetterresults' },
    { title: 'Building Muscle on a Plant-Based Diet', path: '/news/buildingmuscleonaplantbaseddiet' },
    { title: 'Circuit Training: Benefits and Sample Workouts', path: '/news/circuittrainingbenefitsandsampleworkouts' },
    { title: 'Creating a Balanced Gym Workout Plan', path: '/news/creatingabalancedgymworkoutplan' },
    { title: 'Effective Gym Routines for Beginners', path: '/news/effectivegymroutinesforbeginners' },
    { title: 'Effective Gym Workouts for Busy Professionals', path: '/news/effectivegymworkoutsforbusyprofessionals' },
    { title: 'Effective Stretching Techniques for Flexibility', path: '/news/effectivestretchingtechniquesforflexibility' },
    { title: 'Effective Weight Loss Workouts at the Gym', path: '/news/effectiveweightlossworkoutsatthegym' },
    { title: 'Gym Etiquette: Do\'s and Don\'ts', path: '/news/gymetiquettedosanddonts' },
    { title: 'Gym Etiquette: Unwritten Rules Every Member Should Know', path: '/news/gymetiquetteunwrittenruleseverymembershouldknow' },
    { title: 'High-Intensity Functional Training (HIFT) Explained', path: '/news/highintensityfunctionaltraininghiftexplained' },
    { title: 'High-Intensity Interval Training (HIIT) Explained', path: '/news/highintensityintervaltraininghiitexplained' },
    { title: 'How to Create a Personalized Workout Plan', path: '/news/howtocreateapersonalizedworkoutplan' },
    { title: 'How to Overcome Gym Anxiety', path: '/news/howtoovercomegymanxiety' },
    { title: 'How to Track and Measure Fitness Progress', path: '/news/howtotrackandmeasurefitnessprogress' },
    { title: 'How to Use Free Weights Safely', path: '/news/howtousefreeweightssafely' },
    { title: 'Hydration Strategies for Optimal Performance', path: '/news/hydrationstrategiesforoptimalperformance' },
    { title: 'Improving Flexibility and Mobility Through Stretching', path: '/news/improvingflexibilityandmobilitythroughstretching' },
    { title: 'Incorporating Dance Fitness for Fun Workouts', path: '/news/incorporatingdancefitnessforfunworkouts' },
    { title: 'Incorporating Flexibility and Mobility into Your Routine', path: '/news/incorporatingflexibilityandmobilityintoyourroutine' },
    { title: 'Incorporating Mindfulness and Meditation into Fitness', path: '/news/incorporatingmindfulnessandmeditationintofitness' },
    { title: 'Incorporating Plyometrics for Explosive Power', path: '/news/incorporatingplyometricsforexplosivepower' },
    { title: 'Nutrition Myths Debunked: Facts for Better Fitness', path: '/news/nutritionmythsdebunkedfactsforbetterfitness' },
    { title: 'Nutrition Timing: Pre- and Post-Workout Meals', path: '/news/nutritiontimingpreandpostworkoutmeals' },
    { title: 'Nutrition Tips for Vegetarians and Vegans in Fitness', path: '/news/nutritiontipsforvegetariansandvegansinfitness' },
    { title: 'Overcoming Plateaus: Strategies to Keep Progressing', path: '/news/overcomingplateausstrategiestokeepprogressing' },
    { title: 'Preventing Common Gym Injuries', path: '/news/preventingcommongyminjuries' },
    { title: 'Seasonal Fitness: Adjusting Your Workout Routine Throughout the Year', path: '/news/seasonalfitnessadjustingyourworkoutroutinethroughouttheyear' },
    { title: 'Setting Realistic Fitness Goals and How to Achieve Them', path: '/news/settingrealisticfitnessgoalsandhowtoachievethem' },
    { title: 'Staying Motivated on Your Fitness Journey', path: '/news/stayingmotivatedonyourfitnessjourney' },
    { title: 'Staying Motivated: Setting and Achieving Fitness Goals', path: '/news/stayingmotivatedsettingandachievingfitnessgoals' },
    { title: 'Strategies for Healthy Weight Gain', path: '/news/strategiesforhealthyweightgain' },
    { title: 'Strategies for Staying Fit While Traveling', path: '/news/strategiesforstayingfitwhiletraveling' },
    { title: 'The Impact of Alcohol on Fitness and Recovery', path: '/news/theimpactofalcoholonfitnessandrecovery' },
    { title: 'The Impact of Music on Workout Performance', path: '/news/theimpactofmusiconworkoutperformance' },
    { title: 'The Impact of Technology on Modern Fitness', path: '/news/theimpactoftechnologyonmodernfitness' },
    { title: 'The Importance of Consistency in Fitness', path: '/news/theimportanceofconsistencyinfitness' },
    { title: 'The Importance of Fiber in Athletic Performance', path: '/news/theimportanceoffiberinathleticperformance' },
    { title: 'The Importance of Goal Setting in Fitness', path: '/news/theimportanceofgoalsettinginfitness' },
    { title: 'The Importance of Proper Hydration During Workouts', path: '/news/theimportanceofproperhydrationduringworkouts' },
    { title: 'The Importance of Rest and Recovery in Training', path: '/news/theimportanceofrestandrecoveryintraining' },
    { title: 'The Importance of Rest Days and Active Recovery', path: '/news/theimportanceofrestdaysandactiverecovery' },
    { title: 'The Importance of Warm-Up and Cool-Down in Your Gym Session', path: '/news/theimportanceofwarmupandcooldowninyourgymsession' },
    { title: 'Time-Efficient Workouts for Busy Schedules', path: '/news/timeefficientworkoutsforbusyschedules' },
    { title: 'Top Supplements to Support Your Fitness Journey', path: '/news/topsupplementstosupportyourfitnessjourney' },
    { title: 'Tracking Your Fitness Progress with Technology', path: '/news/trackingyourfitnessprogresswithtechnology' },
    { title: 'Training with Free Weights vs. Machines', path: '/news/trainingwithfreeweightsvsmachines' },
    { title: 'Training with Kettlebells: Exercises and Benefits', path: '/news/trainingwithkettlebellsexercisesandbenefits' },
    { title: 'Training with Medicine Balls: Versatile Exercises', path: '/news/trainingwithmedicineballsversatileexercises' },
    { title: 'Training with Sandbags: Unstable Load Benefits', path: '/news/trainingwithsandbagsunstableloadbenefits' },
    { title: 'Training with Suspension Trainers (e.g., TRX)', path: '/news/trainingwithsuspensiontrainersegtrx' },
    { title: 'Understanding and Preventing Overtraining', path: '/news/understandingandpreventingovertraining' },
    { title: 'Understanding and Utilizing Active Recovery Days', path: '/news/understandingandutilizingactiverecoverydays' },
    { title: 'Understanding and Utilizing Gym Equipment Safely', path: '/news/understandingandutilizinggymequipmentsafely' },
    { title: 'Understanding Body Composition and Its Role in Fitness', path: '/news/understandingbodycompositionanditsroleinfitness' },
    { title: 'Understanding Body Composition and Setting Fitness Goals', path: '/news/understandingbodycompositionandsettingfitnessgoals' },
    { title: 'Understanding Bodyweight Training Benefits', path: '/news/understandingbodyweighttrainingbenefits' },
    { title: 'Understanding Caloric Needs for Different Fitness Goals', path: '/news/understandingcaloricneedsfordifferentfitnessgoals' },
    { title: 'Understanding Different Training Techniques: Supersets, Drop Sets, and More', path: '/news/understandingdifferenttrainingtechniquessupersetsdropsetsandmore' },
    { title: 'Understanding Heart Rate Zones for Optimal Training', path: '/news/understandingheartratezonesforoptimaltraining' },
    { title: 'Understanding Intermittent Fasting and Fitness', path: '/news/understandingintermittentfastingandfitness' },
    { title: 'Understanding Macros: Protein, Carbs, and Fats', path: '/news/understandingmacrosproteincarbsandfats' },
    { title: 'Understanding the Glycemic Index for Energy Management', path: '/news/understandingtheglycemicindexforenergymanagement' },
    { title: 'Understanding VO2 Max and Cardiovascular Fitness', path: '/news/understandingvo2maxandcardiovascularfitness' },
    { title: 'Workout Tips for Building Endurance', path: '/news/workouttipsforbuildingendurance' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Ramp Gym Knowledge Base</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="news-item large-return">
        <Link to="/news" className="news-item">
          Return to News
        </Link>
      </div>
    </div>
  );
};

export default GymKnowledge;

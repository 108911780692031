import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForMuscularDystrophy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives: NDIS Physiotherapy for Muscular Dystrophy</h1>
                    <h2>Explore the transformative power of NDIS-funded physiotherapy for individuals with Muscular Dystrophy at Ramp Physio and Fitness.</h2>
                    <p>Muscular Dystrophy is a group of genetic disorders characterized by progressive muscle weakness and degeneration. At Ramp Physio and Fitness, we are committed to providing specialized NDIS physiotherapy services to help those with Muscular Dystrophy enhance their mobility and improve their quality of life. Our experienced physiotherapists are dedicated to crafting personalized treatment plans that align with each individual's NDIS goals. Let's delve into how our approach can make a positive impact on your journey to better health and fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Muscular Dystrophy</h1>
                        <p>Muscular Dystrophy affects individuals in various ways, depending on the type and progression of the disorder. Common challenges include difficulty with walking, maintaining balance, and performing daily activities. Our physiotherapy services focus on enhancing function, promoting independence, and supporting individuals as they navigate life with Muscular Dystrophy.</p>

                        <p>NDIS funding can be a lifeline for those with Muscular Dystrophy, offering access to essential therapies and support services. At Ramp Physio and Fitness, we ensure that our interventions are NDIS compliant, providing the necessary documentation and progress reports for participants and their support network.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Plans</h1>
                    <p>Our approach to NDIS physiotherapy for Muscular Dystrophy is highly individualized. We begin with a thorough assessment to understand the specific needs and challenges faced by each participant. This allows us to design tailored treatment plans that aim to improve muscle strength, enhance cardiorespiratory fitness, and maintain flexibility.</p>

                    <p>Our treatment plans may include a combination of therapeutic exercises, manual therapy, and assistive technology recommendations. We also provide education on energy conservation techniques and adaptive strategies to empower individuals to manage their condition effectively.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Function</h1>
                    <p>Maintaining mobility is a key concern for people with Muscular Dystrophy. Our physiotherapy interventions are designed to help participants stay as mobile as possible. By focusing on strength training and functional exercises, we aim to slow the progression of muscle weakness and maximize functional ability.</p>

                    <p>Our facility is equipped with state-of-the-art equipment to support a wide range of exercises and treatments. We work closely with participants to ensure they are comfortable and confident in using any prescribed assistive devices.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supporting Lifelong Wellness</h1>
                        <p>At Ramp Physio and Fitness, we believe in supporting our participants beyond the immediate goals of strength and mobility. Our team is dedicated to fostering a culture of lifelong wellness, encouraging individuals with Muscular Dystrophy to stay active and engaged in their health journey.</p>

                        <p>We provide ongoing support and advice on managing symptoms, preventing complications, and adapting to the changing nature of the disorder. Our commitment to your well-being is unwavering, and we strive to be a constant source of motivation and empowerment.</p>

                        <br></br>
                        <p>
                            In summary, NDIS physiotherapy for Muscular Dystrophy at Ramp Physio and Fitness is a holistic service that addresses the multifaceted needs of individuals with this condition. Our expert team is passionate about delivering personalized care, utilizing innovative treatment methods, and providing the support needed to thrive. If you or a loved one is looking for compassionate, expert care, we invite you to join our community and take the next step towards a more active and fulfilling life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForMuscularDystrophy;
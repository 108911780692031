import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Ultrasound Physiotherapy: An In-Depth Exploration</h1>
                    <h2>This article delves into the intricacies of ultrasound physiotherapy, examining its applications, benefits, and the evolving perspective within the physiotherapy community.</h2>
                    <p>Ultrasound physiotherapy has long been a staple in the management of sports injuries and chronic pain conditions. At Ramp Physio and Fitness, we understand the importance of utilizing evidence-based treatments to ensure the best outcomes for our patients. As we explore the nuances of ultrasound therapy, we consider its effectiveness, the scientific backing, and how it integrates with our comprehensive approach to healthcare and personalized training.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind Ultrasound Therapy</h1>
                        <p>Ultrasound physiotherapy utilizes sound waves to promote healing at a cellular level. These high-frequency waves are capable of penetrating deep into the tissues, stimulating repair, and reducing inflammation. One of the key ideas surrounding this treatment is its non-invasive nature, making it a preferred choice for patients seeking pain relief without the use of medication or surgery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Therapeutic Effects and Applications</h1>
                    <p>Ultrasound therapy has been championed for its therapeutic effects, particularly when it comes to soft tissue injuries. The warmth generated by the sound waves is said to increase blood flow, which can be beneficial for tissue repair and reducing muscle spasms. It's often part of a broader treatment plan that includes manual therapy, exercise, and education. However, it's important to note that the efficacy of ultrasound physiotherapy has been debated, with some clinicians opting for other modalities that have shown more consistent results in clinical trials.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Evolving Perspectives in Physiotherapy</h1>
                    <p>The field of physiotherapy is constantly evolving, with research continually shaping clinical practice. While ultrasound therapy was once a mainstay in physiotherapy clinics, there's a growing trend towards treatments that have stronger evidence bases. This shift highlights the commitment of the physiotherapy community, including our team at Ramp Physio and Fitness, to provide the most effective care possible. We are dedicated to staying at the forefront of healthcare advancements to ensure our patients receive the best possible outcomes.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Real-Time Ultrasound in Physiotherapy</h1>
                    <p>An exciting development in the realm of ultrasound physiotherapy is the use of real-time ultrasound imaging. This technology allows physiotherapists to view muscles, tendons, and ligaments during movement, providing valuable feedback for both the therapist and the patient. It's particularly useful in retraining deep stabilizing muscles, which can be crucial for recovery and preventing future injuries. This innovative approach reflects our commitment to integrating cutting-edge technology with personalized care.</p>
                    <br></br>
                    <p>
                        In conclusion, ultrasound physiotherapy remains a subject of discussion within the physiotherapy community. At Ramp Physio and Fitness, we believe in an evidence-based approach, carefully considering the latest research and clinical expertise when integrating any treatment modality into our practice. Whether ultrasound therapy is the right choice for a patient depends on individual circumstances, and it's our job to ensure that each treatment plan is tailored to meet those unique needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

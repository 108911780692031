import React from 'react';
import './TestimonialSection.css';

const TestimonialSection = () => {
    return (
        <div className="testimonial-section">
            
            <div className="testimonial-background">
            <svg className="curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="white" fillOpacity="1" d="M0,124Q720,-100,1440,124L1440,0L0,0Z"></path>
            </svg>
                <div className="testimonial-content">
                    <div className="testimonial-text">
                        <p>
                            <span className="quote">“</span>The staff really care and it feels like a family. <span className="quote">”</span>
                        </p>
                        <p className="author">- Ash</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSection;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterWristFracture = () => {
    return (
        <div>
            <Helmet>
                <title>Rehabilitation After Wrist Fracture - Ramp Physio and Fitness</title>
                <meta name="description" content="Expert guidance on rehabilitation after wrist fracture, including physiotherapy exercises and recovery tips." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Wrist Fracture Rehabilitation</h1>
                    <h2>Regain strength and mobility with our tailored wrist fracture rehabilitation program at Ramp Physio and Fitness, designed for optimal recovery.</h2>
                    <p>Wrist fractures can significantly impact your daily life, limiting your ability to perform simple tasks. At Ramp Physio and Fitness, we understand the challenges that come with wrist injuries. Our expert team is dedicated to providing personalized rehabilitation programs to help you recover fully and quickly. In this comprehensive guide, we'll explore the essential exercises and strategies for effective wrist fracture rehabilitation.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Wrist Fracture Recovery</h1>
                        <p>A wrist fracture recovery journey involves several stages, each crucial for regaining full function. From the initial immobilization to the final stages of strengthening, our tailored approach ensures every aspect of rehabilitation is covered. Here are key themes to consider during your recovery:</p>
                        <ul>
                            <li>Importance of proper immobilization</li>
                            <li>Gradual reintroduction of movement</li>
                            <li>Strengthening exercises for resilience</li>
                            <li>Flexibility exercises for range of motion</li>
                            <li>Techniques to reduce swelling and pain</li>
                            <li>Strategies for preventing future injuries</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Rehab Exercise Programs</h1>
                    <p>Every wrist fracture is unique, and so is every recovery. At Ramp Physio and Fitness, we design personalized exercise programs tailored to your specific needs and progress. Our programs include:</p>
                    <ol>
                        <li>Gentle range-of-motion exercises</li>
                        <li>Strengthening exercises for the wrist and forearm</li>
                        <li>Exercises to improve fine motor skills</li>
                        <li>Techniques for swelling management</li>
                        <li>Pain relief methods, such as ice and heat therapy</li>
                        <li>Functional training for daily activities</li>
                    </ol>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Techniques for Wrist Rehab</h1>
                    <p>Our physiotherapists at Ramp Physio and Fitness use advanced techniques to facilitate your recovery, including:</p>
                    <ul>
                        <li>Manual therapy for joint mobilization</li>
                        <li>Soft tissue massage for scar tissue management</li>
                        <li>Ultrasound therapy for deep tissue healing</li>
                        <li>Electrotherapy for pain reduction</li>
                        <li>Therapeutic exercises using resistance bands</li>
                        <li>Mirror therapy for brain and muscle re-education</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Rehab into Daily Life</h1>
                    <p>Rehabilitation doesn't end at the clinic. We'll provide you with strategies and exercises to integrate into your daily routine, ensuring continuous improvement. These include:</p>
                    <ol>
                        <li>Adapting household tasks for wrist safety</li>
                        <li>Using assistive devices during recovery</li>
                        <li>Exercises for workplace ergonomics</li>
                        <li>Stress management techniques for overall well-being</li>
                        <li>Nutritional advice for bone health</li>
                        <li>Mindfulness practices for a positive mindset</li>
                    </ol>
                    <br></br>
                    <p>
                        Recovery from a wrist fracture requires patience, dedication, and expert guidance. At Ramp Physio and Fitness, our team is committed to supporting you through every step of your rehabilitation journey. With our comprehensive program, you can look forward to regaining strength, mobility, and confidence in your wrist. Book now to begin your path to recovery with us.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterWristFracture;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForTorticollisInInfants = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy Solutions for Infant Torticollis</h1>
                    <h2>Unraveling the Complexity of Torticollis: Expert Care for Your Infant at Ramp Physio and Fitness.</h2>
                    <p>Torticollis in infants, also known as 'wry neck', is a condition that can be concerning for parents. It involves a tilt and/or twist of the neck due to tightness in the neck muscles. At Ramp Physio and Fitness, we understand the intricacies of this condition and provide specialized physiotherapy to correct and manage torticollis, promoting normal, healthy development for your child.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Infant Torticollis</h1>
                        <p>Infant torticollis occurs when the sternocleidomastoid muscle is shortened, leading to an abnormal, asymmetrical head or neck position. It might be present at birth or develop later. Early intervention with physiotherapy can effectively treat torticollis, reducing the risk of potential developmental delays or complications such as plagiocephaly (flat head syndrome).</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Assessment and Diagnosis</h1>
                    <p>Our physiotherapy approach begins with a thorough assessment to confirm the diagnosis of torticollis. We evaluate the infant's neck range of motion, muscle tightness, and any associated conditions. This comprehensive assessment allows us to tailor a specific treatment plan that meets the unique needs of your child.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Treatment Techniques for Torticollis</h1>
                    <p>Our physiotherapy treatment for infant torticollis includes a variety of techniques such as gentle stretching, strengthening exercises, and positioning strategies. We also educate parents on how to continue these techniques at home to maximize the benefits of therapy.</p>
                    <ul>
                        <li>Gentle neck stretches to improve flexibility</li>
                        <li>Strengthening exercises for neck muscles</li>
                        <li>Positioning techniques to encourage correct head alignment</li>
                        <li>Use of specialized equipment when necessary</li>
                        <li>Parental guidance for home exercise programs</li>
                        <li>Monitoring for improvements and adjusting the plan as needed</li>
                        <li>Advice on preventing recurrence</li>
                        <li>Collaboration with other healthcare professionals if required</li>
                        <li>Support for developmental milestones associated with neck function</li>
                        <li>Assessment for potential associated conditions</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Engaging Infants in Therapy</h1>
                    <p>Our physiotherapy sessions are designed to be engaging and enjoyable for infants. We use play-based therapy to encourage active participation and create a positive experience. This approach not only aids in the treatment of torticollis but also promotes overall development and well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Parental Involvement in Treatment</h1>
                    <p>At Ramp Physio and Fitness, we believe that parental involvement is key to successful treatment. We empower parents with the knowledge and skills needed to support their infant's therapy at home, ensuring continuity of care and promoting faster progress.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Long-Term Outlook and Follow-Up</h1>
                    <p>The long-term outlook for infants with torticollis is generally positive, especially with early intervention. Our physiotherapists provide regular follow-ups to monitor the infant's progress and make necessary adjustments to the treatment plan, ensuring the best possible outcome for your child.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForTorticollisInInfants;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfGutHealthInFitnessAndWellbeing = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unlocking Gut Health for Fitness</h1>
                    <h2>Explore the connection between gut health and fitness at Ramp Physio and Fitness, and learn how optimizing your microbiome can enhance your wellbeing and athletic performance.</h2>
                    <p>At Ramp Physio and Fitness, we understand that a holistic approach to health and fitness is crucial for achieving optimal performance and wellbeing. Emerging research underscores the significant role of gut health in overall fitness. The complex ecosystem within your gut, known as the microbiome, influences everything from nutrient absorption to immune function. In this comprehensive guide, we'll uncover the intricate link between gut health and fitness, providing actionable insights to help you nurture your microbiome for peak physical and mental health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gut Health and Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Microbiome and Muscle Function</h1>
                        <p>Recent studies reveal a fascinating connection between gut bacteria and muscle growth, strength, and recovery. The gut microbiome produces metabolites that can impact muscle function and the body's response to exercise. By fostering a diverse and balanced gut microbiome, you can potentially enhance your fitness outcomes. Here are 10 ways to support your gut health for improved fitness:</p>
                        <ul>
                            <li>Incorporate a variety of fiber-rich foods</li>
                            <li>Stay hydrated to promote digestive health</li>
                            <li>Include probiotic and prebiotic foods in your diet</li>
                            <li>Manage stress levels to avoid gut imbalance</li>
                            <li>Get adequate sleep for microbiome maintenance</li>
                            <li>Limit the use of antibiotics to necessary situations</li>
                            <li>Avoid excessive alcohol and processed foods</li>
                            <li>Exercise regularly to enhance gut biodiversity</li>
                            <li>Consider personalized probiotic supplements</li>
                            <li>Regularly monitor gut health with professional help</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Gut Inflammation and Recovery</h1>
                    <p>Inflammation in the gut can hinder recovery and performance by affecting nutrient absorption and causing discomfort. Optimal gut health helps to reduce inflammation, which in turn can improve recovery times and reduce the risk of injury. Understanding the signs of gut inflammation and taking steps to mitigate them can be a game-changer for athletes and fitness enthusiasts alike.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Mental Health and the Gut</h1>
                    <p>The gut-brain axis is a two-way communication system between your gut and your brain. The state of your gut health can affect your mental wellbeing, influencing mood, stress levels, and even cognitive function. A healthy gut microbiome can contribute to a positive mental state, which is essential for motivation and performance in fitness routines.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Nutrient Absorption and Performance</h1>
                    <p>A well-functioning gut is essential for the absorption of nutrients that fuel your workouts and aid in recovery. When the gut is healthy, it can efficiently absorb the proteins, vitamins, and minerals necessary for energy production and muscle repair. This section will delve into how gut health affects nutrient uptake and the implications for your fitness regimen.</p>
                    <br></br>
                    <p>
                        In conclusion, the role of gut health in fitness and overall wellness cannot be overstated. At Ramp Physio and Fitness, we're dedicated to providing comprehensive support for your health and fitness goals, including guidance on nurturing your gut health. By understanding and caring for your microbiome, you can unlock new levels of wellbeing and performance. Join us on the journey to optimal health, where a balanced gut is at the heart of a strong body.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfGutHealthInFitnessAndWellbeing;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforparkinsonsdisease = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Parkinson's Disease | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Movement in Parkinson's Disease</h1>
                    <h2>Explore the benefits of specialized physiotherapy for Parkinson's Disease at Ramp Physio and Fitness, where we provide tailored interventions to enhance mobility, stability, and quality of life.</h2>
                    <p>Parkinson's Disease is a progressive neurological disorder that can significantly impact movement and coordination. At Ramp Physio and Fitness, we understand the challenges faced by individuals with Parkinson's and offer expert physiotherapy services designed to mitigate symptoms and improve daily functioning. This article will guide you through how physiotherapy can be a game-changer for those living with Parkinson's Disease.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Physiotherapy for Parkinson's" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Parkinson's and Physiotherapy</h1>
                        <p>Physiotherapy plays a critical role in the comprehensive management of Parkinson's Disease. It involves personalized exercises and interventions that target specific symptoms, such as tremors, bradykinesia (slowness of movement), and rigidity. Our physiotherapists at Ramp Physio and Fitness are equipped with the latest knowledge and techniques to create an effective treatment plan tailored to each individual's needs.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Stability and Balance</h1>
                    <p>One of the primary concerns for individuals with Parkinson's is the increased risk of falls due to compromised balance and stability. Our physiotherapy program includes exercises that focus on improving balance, coordination, and gait. By strengthening these areas, patients can experience greater confidence in their movements and a reduced risk of falls.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Tailored Therapies for Parkinson's
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Managing Symptoms with Exercise</h1>
                    <p>Exercise is a cornerstone of managing Parkinson's Disease. Our physiotherapy program includes a range of exercises designed to maintain and improve mobility, flexibility, and strength. These exercises are not only beneficial for physical health but also have a positive impact on mental well-being, helping to alleviate symptoms of depression and anxiety often associated with Parkinson's.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Incorporating Technology and Innovation</h1>
                        <p>At Ramp Physio and Fitness, we leverage the latest technological advancements to enhance physiotherapy outcomes for Parkinson's patients. From virtual reality to sensor-based monitoring, these innovative tools complement traditional therapies and provide engaging ways to practice movements and track progress.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforparkinsonsdisease;
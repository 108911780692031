import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Strengthtrainingforwomendebunkingmyths = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strength Training for Women: Debunking Myths</h1>
                    <h2>Discover the truth about strength training for women and how Ramp Physio and Fitness empowers you to build strength confidently and effectively.</h2>
                    <p>Strength training is a powerful tool for women seeking to improve their health, fitness, and quality of life. Yet, many women hesitate to embrace weightlifting due to persistent myths that cloud its benefits. At Ramp Physio and Fitness, we're dedicated to debunking these misconceptions and highlighting the transformative impact strength training can have on a woman's body and overall well-being. Join us as we dismantle the myths and explore the science-backed advantages of strength training for women.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Strength Training for Women" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Myth vs. Reality</h1>
                        <p>Let's address the most common myths that deter women from lifting weights and contrast them with the realities backed by scientific research and expert opinions:</p>
                        <ul>
                            <li>Myth: Strength training makes women bulky.</li>
                            <li>Reality: Women typically have lower testosterone levels than men, making it less likely for them to develop bulky muscles. Instead, strength training helps women tone their bodies and enhance muscle definition.</li>
                            <li>Myth: Cardio is enough for weight loss.</li>
                            <li>Reality: While cardio is beneficial, incorporating strength training can accelerate fat loss by increasing muscle mass, which boosts metabolism.</li>
                            <li>Myth: Lifting heavy is dangerous for women.</li>
                            <li>Reality: With proper technique and guidance, heavy lifting is safe and can be incredibly beneficial for building strength and bone density.</li>
                            <li>Myth: Strength training is not feminine.</li>
                            <li>Reality: Strength training has no bearing on femininity; it empowers women to be strong and confident in their own skin.</li>
                            <li>Myth: You'll see results immediately.</li>
                            <li>Reality: Strength gains and body changes are progressive; consistency and patience are key.</li>
                            <li>Myth: Machines are safer than free weights.</li>
                            <li>Reality: Both machines and free weights have their place in training, and safety comes from proper form and technique.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Strength Training</h1>
                        <p>Strength training goes beyond just enhancing muscle tone and size. Here are 10 compelling benefits that women can gain from regular strength training:</p>
                        <ul>
                            <li>Improved metabolic rate and fat loss</li>
                            <li>Increased bone density, reducing the risk of osteoporosis</li>
                            <li>Enhanced joint stability and reduced injury risk</li>
                            <li>Better cardiovascular health</li>
                            <li>Boosted energy levels and mood</li>
                            <li>Greater functional strength for daily activities</li>
                            <li>Enhanced athletic performance</li>
                            <li>Improved body image and confidence</li>
                            <li>Greater muscle endurance and stamina</li>
                            <li>Improved insulin sensitivity and glucose metabolism</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Starting Your Journey</h1>
                    <p>Embarking on a strength training journey can be intimidating, but with the right approach, it can also be incredibly rewarding. Here are some key ideas to keep in mind:</p>
                    <ul>
                        <li>Begin with a clear plan and realistic goals</li>
                        <li>Learn proper form and technique to prevent injuries</li>
                        <li>Start with lighter weights and progressively increase as you build strength</li>
                        <li>Incorporate a variety of exercises to target different muscle groups</li>
                        <li>Listen to your body and give it time to recover between sessions</li>
                        <li>Seek the guidance of a qualified personal trainer or physiotherapist</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Empowering Women at Ramp</h1>
                    <p>At Ramp Physio and Fitness, we believe in empowering women through strength training by providing a supportive and inclusive environment. Our expert team is dedicated to helping you achieve your health and fitness goals with personalized training and physiotherapy services.</p>
                    <br></br>
                    <p>
                        In conclusion, strength training for women is shrouded in myths that can be easily dispelled with the right information and support. At Ramp Physio and Fitness, we're committed to educating and empowering women to take control of their fitness journey. Whether you're new to strength training or looking to enhance your program, we're here to support you every step of the way. Join us and experience the true benefits of strength training for a healthier, stronger you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Strengthtrainingforwomendebunkingmyths;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating ACL Injuries: A Comprehensive Guide</h1>
                    <h2>Explore the intricacies of ACL injuries, from understanding the condition to personalized rehabilitation strategies at Ramp Physio and Fitness, where we champion your recovery journey.</h2>
                    <p>At Ramp Physio and Fitness, we understand that an Anterior Cruciate Ligament (ACL) injury doesn't just impact your knee; it can disrupt your entire lifestyle. As a crucial stabilizing structure in your knee, the ACL plays a vital role in your mobility and athletic performance. In this comprehensive guide, we'll delve deep into the anatomy of ACL injuries, their causes, symptoms, and the most effective treatment options available, including when surgery might be necessary. Our goal is to equip you with knowledge and support your path to recovery with expert physiotherapy and personalized fitness strategies.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding ACL Injuries</h1>
                        <p>The ACL's role in knee stability cannot be overstated. It's essential for athletes and active individuals alike to comprehend the mechanics behind these injuries. A sudden pivot or misstep during high-impact sports can overstress the ACL, leading to partial or complete tears. While anyone can suffer from an ACL injury, athletes participating in sports such as soccer, basketball, and skiing are at a higher risk. Understanding the anatomy and mechanics of the knee is the first step in recognizing the signs of an ACL injury and seeking appropriate care.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Symptoms and Diagnosis</h1>
                    <p>When an ACL injury occurs, recognizing the symptoms promptly can make a significant difference in the management and outcome of the condition. Beyond the initial audible pop and subsequent knee pain, individuals may also experience rapid swelling and a sense of instability when bearing weight. Physicians use a combination of patient history, physical examinations, and advanced imaging techniques to confirm the diagnosis. At Ramp Physio and Fitness, our team collaborates closely with medical professionals to ensure a precise diagnosis, paving the way for an effective treatment plan tailored to each individual.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Treatment and Management</h1>
                    <p>Deciding on the best course of action for an ACL injury is a nuanced process, taking into account the patient's lifestyle, the severity of the injury, and future activity goals. Non-surgical treatments may be suitable for those with a less active lifestyle or minor injuries, focusing on physiotherapy to regain strength and stability. However, for those with complete tears or who lead a highly active lifestyle, surgical intervention might be the recommended path. Post-surgery, physiotherapy remains a cornerstone of the recovery process, and at Ramp Physio and Fitness, we're dedicated to guiding patients through each step, ensuring a comprehensive and compassionate approach to healing.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Rehabilitation and Training</h1>
                        <p>Recovery from an ACL injury is a journey that requires patience, dedication, and expert guidance. Our physiotherapy team at Ramp Physio and Fitness provides a supportive environment where patients can work on regaining full function through a variety of exercises designed to restore strength, flexibility, and balance. We emphasize the importance of a gradual return to activity, monitoring progress closely to prevent re-injury. Our personalized training regimens are not only aimed at rehabilitation but also at enhancing overall fitness to support a robust and active lifestyle post-recovery.</p>
                        <br></br>
                        <p>
                            In conclusion, an ACL injury can be a challenging ordeal, but with the right knowledge and support, a full recovery is within reach. At Ramp Physio and Fitness, we are committed to being your partners in health, offering expert physiotherapy services, and a personalized approach to fitness that caters to your unique needs. Whether you're recovering from an injury or looking to prevent one, we're here to help you stay on track and achieve your goals.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const LymphaticDrainageMassageTherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unlock the Healing Power of Lymphatic Drainage</h1>
                    <h2>Experience the transformative effects of Lymphatic Drainage Massage Therapy at Ramp Physio and Fitness, designed to promote wellness, reduce swelling, and enhance immune function.</h2>
                    <p>Lymphatic Drainage Massage Therapy is a gentle and effective way to stimulate your body's lymphatic system, promoting detoxification, reducing swelling, and supporting overall health. At Ramp Physio and Fitness, our expert therapists are trained to deliver personalized lymphatic drainage techniques that cater to your individual health needs. In this comprehensive guide, we'll explore the myriad benefits of this therapy and how it can be integrated into your wellness routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Enhance Your Immune System</h1>
                        <p>Lymphatic Drainage Massage Therapy is not just for relaxation; it's a powerful ally in strengthening your immune system. By stimulating the flow of lymphatic fluid, this therapy helps your body to naturally detoxify, fight off infections, and maintain a healthy balance. Here are ten key benefits of incorporating Lymphatic Drainage Massage into your health regimen:</p>
                        <ul>
                            <li>Promotes the removal of bodily toxins</li>
                            <li>Reduces swelling and water retention</li>
                            <li>Supports immune system function</li>
                            <li>Improves skin health and appearance</li>
                            <li>Encourages healing after surgery or injury</li>
                            <li>Alleviates stress and promotes relaxation</li>
                            <li>Enhances digestion and can help with weight loss</li>
                            <li>Relieves fatigue and improves sleep quality</li>
                            <li>Supports pain management in conditions like fibromyalgia</li>
                            <li>Improves circulation and can reduce blood pressure</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Detoxify and Rejuvenate</h1>
                    <p>Lymphatic Drainage Massage Therapy goes beyond surface-level benefits. It deeply cleanses your body's tissues, helping you feel rejuvenated and energized. This therapy is particularly beneficial for those who lead sedentary lifestyles or experience regular bouts of fatigue. By aiding the lymphatic system, which is a crucial part of your body's natural detoxification process, you can enjoy a clearer complexion, reduced puffiness, and a boost in vitality.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Relief for Swelling and Edema</h1>
                    <p>One of the primary applications of Lymphatic Drainage Massage Therapy is the treatment of swelling and edema. Whether due to medical conditions such as lymphedema or common causes like sports injuries and post-operative recovery, this therapy can help reduce fluid buildup and accelerate the healing process. Our therapists use specialized techniques to gently guide excess lymph fluid towards the nearest group of lymph nodes, where it can be more effectively processed by the body.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Therapy for Your Needs</h1>
                    <p>At Ramp Physio and Fitness, we understand that every individual's body is unique. That's why we offer personalized Lymphatic Drainage Massage sessions tailored to your specific needs and health goals. Whether you're looking to support your body's natural detoxification, manage lymphedema, or simply relax and rejuvenate, our therapists are equipped to provide you with a therapeutic experience that caters to your well-being.</p>
                    <br></br>
                    <p>
                        In conclusion, Lymphatic Drainage Massage Therapy is a versatile and beneficial treatment that can support a wide range of health goals. Our dedicated team at Ramp Physio and Fitness is here to guide you through each step of your wellness journey. Experience the difference that expertly delivered Lymphatic Drainage Massage can make in your life. Book your session today and take the first step towards a healthier, more balanced you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default LymphaticDrainageMassageTherapy;
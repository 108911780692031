import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}
            {/*
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-P52MNG23ES"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-P52MNG23ES');
                    `}
                </script>
            </Helmet>
            */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Easing Back Pain with Remedial Massage Therapy</h1>
                    <h2>Discover the healing powers of remedial massage for back pain relief and improved mobility. Learn how this therapy can be tailored to your unique needs at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that back pain can be a debilitating condition that affects every aspect of your life. That's why we are dedicated to offering holistic and effective treatments, such as remedial massage, to alleviate discomfort and enhance your overall well-being. Our approach is personalized and evidence-based, ensuring that you receive the care that's just right for your body.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Healing Touch: Remedial Massage Explained</h1>
                        <p>Remedial massage is a targeted form of therapy that addresses specific areas of tension, pain, and injury within the body. It's a technique that has been refined over the years to not just provide temporary relief, but also to promote long-term healing. By applying controlled pressure and movement to the soft tissues of the body, remedial massage therapists at Ramp Physio and Fitness work to improve circulation, reduce inflammation, and encourage the repair of muscles and ligaments.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Understanding Back Pain and Its Causes</h1>
                    <p>Back pain is a complex issue that can stem from a myriad of causes. It might arise from acute injuries, such as a sprain or strain, or be the result of chronic conditions, like arthritis or disc degeneration. Lifestyle factors, such as poor posture or repetitive strain, can also play a significant role. Our therapists take the time to understand the root cause of your back pain to tailor the remedial massage to your specific needs, providing a pathway not only to relief but also to better health practices.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tailoring Massage to Your Pain Relief Needs</h1>
                    <p>No two individuals are the same, and neither are their experiences with back pain. This is why personalized care is at the heart of our remedial massage services. Our skilled therapists use a variety of techniques, including deep tissue work, myofascial release, and trigger point therapy, to create a treatment plan that is as unique as you are. By assessing your condition and listening to your body's responses, we adapt our methods to offer you the most effective treatment possible.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Long-Term Benefits and Integrated Care</h1>
                    <p>Remedial massage for back pain is more than a one-time fix; it's a step towards a healthier, more active lifestyle. Alongside immediate pain relief, it can improve range of motion, strengthen the body’s support structures, and prevent future injuries. At Ramp Physio and Fitness, we believe in integrated care. We may combine your massage therapy with other services like physiotherapy, chronic pain management techniques, and personalized training to ensure a comprehensive approach to your health and fitness journey.</p>
                    <br></br>
                    <p>
                        In conclusion, remedial massage at Ramp Physio and Fitness is a powerful ally in the fight against back pain. By understanding the intricacies of this therapy and how it can be customized to your individual needs, we offer a pathway to not just recovery, but also to a life free from the limitations of back pain. Join us on this journey to wellness, and let the healing hands of our expert therapists guide you to a more comfortable and active future.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

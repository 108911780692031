import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForMentalHealthConditions = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Mental Health Conditions | Ramp Physio and Fitness</title>
                <meta name="description" content="Explore the benefits of NDIS-funded physiotherapy services for individuals with mental health conditions at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Physiotherapy</h1>
                    <h2>Discover the transformative power of physiotherapy for mental health conditions under the NDIS at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we acknowledge the crucial role that physical health plays in managing and improving mental health conditions. Our NDIS physiotherapy services are designed to empower individuals with mental health challenges by providing them with tailored physical therapy interventions. In this comprehensive guide, we will delve into how physiotherapy can be a valuable component of a holistic treatment plan for mental well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Mental Health Support</h1>
                        <p>Physiotherapy for mental health conditions goes beyond traditional physical therapy. It involves understanding the interplay between physical activity and mental well-being. Our physiotherapists are trained to recognize the signs of mental health conditions and to create a supportive environment that fosters both physical and mental recovery.</p>

                        <p>We begin with an individualized assessment, considering the unique needs and limitations that mental health conditions might impose. From there, we design a program that not only addresses physical health but also contributes positively to mental health outcomes.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Tailored Physiotherapy Interventions</h1>
                        <p>Our NDIS physiotherapy services are highly individualized, catering to the specific needs of those with mental health conditions. We focus on creating a therapeutic plan that incorporates:</p>
                        <div>
                            <p>• Exercise regimens to improve mood and reduce anxiety</p>
                            <p>• Strength and conditioning to boost self-esteem</p>
                            <p>• Breathing and relaxation techniques to manage stress</p>
                            <p>• Posture correction to enhance body image perception</p>
                            <p>• Motivational strategies to encourage regular physical activity</p>
                            <p>• Education on the benefits of exercise for mental health</p>
                            <p>• Goal setting to provide a sense of accomplishment</p>
                            <p>• Supportive counseling to overcome barriers to exercise</p>
                            <p>• Coordination with mental health professionals for comprehensive care</p>
                            <p>• Progress monitoring to adjust the program as needed</p>
                        </div>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Collaborative Care and NDIS Coordination</h1>
                    <p>Our physiotherapy services extend to coordinating with other healthcare providers to ensure a seamless integration of care. This collaborative approach is essential for participants of the NDIS with mental health conditions, as it ensures that all aspects of their health are addressed in a unified manner.</p>

                    <p>We work closely with NDIS coordinators, psychologists, and other health professionals to develop a comprehensive plan that aligns with the participant's NDIS goals. Our aim is to support participants in gaining the maximum benefit from their plan, leading to improved health outcomes and quality of life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Building Resilience and Independence</h1>
                        <p>Our physiotherapy programs are not just about treating symptoms; they're about building resilience and fostering independence. We empower our clients by teaching them how to manage their conditions effectively, providing them with the tools and knowledge to take control of their health and well-being.</p>
                        <p>By incorporating resilience-building activities and self-management strategies into our programs, we help individuals with mental health conditions to lead more active, fulfilling lives. Our goal is to provide sustainable health benefits that extend beyond the duration of the NDIS plan.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForMentalHealthConditions;
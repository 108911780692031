import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/Ramp_cover.webp';
import otherinfoimg from '../assets/RAMP Physio Icon.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const About = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>What is Ramp Physio and Fitness?</h1>
                    <p>
                        Ramp Physio and Fitness is an innovative facility dedicated to merging the principles of physical therapy with comprehensive fitness training. At the core of its philosophy is the belief that optimal health and physical performance are achieved through a balanced approach that integrates injury prevention, rehabilitation, and strength conditioning.This holistic method ensures that individuals not only recover from injuries but also build resilience to avoid future setbacks.</p>

                    <p>
                        If you have additional questions about our gym and physio near you, please do not hesitate to contact us.
                    </p>
                    <p>
                        We operate in 208 Parramatta Road, Homebush, and our services allow us to bring optimal health care to the local community and patients of all ages, conditions, and lifestyles.
                        <br /><br />
                        Book now to see our expert physiotherapists
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">

                        <h1>Our Services</h1>
                        <p>
                            Our state of the art physiotherapy clinic and gym offers a diverse range of services designed to cater to clients of all ages and fitness levels. Whether you are recovering from surgery, managing a chronic condition, or simply looking to enhance your athletic performance. </p>
                            <br></br>
                            <p>Our Physiotherapists are DVA, Medicare, Health Fund, NDIS and Work Cover approved, they specialise in the correct injury management and rehabilitation protocols to get you pain and injury free.</p>
                            <br></br>
                           <p>Ramp Physio and Fitness has tailored programs to meet your needs. Our team of skilled physiotherapists and certified fitness trainers work collaboratively to develop personalised treatment and training plans. These plans are informed by the latest research in sports medicine and exercise science, ensuring that clients receive evidence-based care. <br /><br />
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/dumbbells.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Local Community Physio and Gym</h1>
                    <p>
                        Community and inclusivity are central to the Ramp Physio and Fitness ethos. They strive to create an environment where everyone feels welcomed and motivated, regardless of their fitness background.<br /><br />
                    </p>
                </div>
            </div>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Ramp Client-Centered Approach</h1>
                    <p>
                        Group classes, one-on-one sessions, and workshops are designed not only to improve physical health but also to foster a sense of camaraderie and support among participants. By prioritising a client-centered approach, Ramp Physio and Fitness helps individuals achieve their personal health goals, paving the way for a healthier, more active lifestyle.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Workouts.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={otherinfoimg} alt="RAMP" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>


                </div>
            </SkewedContainer>
        </div>

    );
};

export default About;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingwithmedicineballsversatileexercises = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Fitness with Medicine Balls</h1>
                    <h2>Explore the dynamic versatility of medicine ball training with Ramp Physio and Fitness and discover exercises that challenge and improve your strength, coordination, and endurance.</h2>
                    <p>At Ramp Physio and Fitness, we understand that variety is the spice of life—and the same goes for your workout routine. That's why we're excited to introduce you to the diverse world of medicine ball exercises. These simple yet effective tools can revolutionize your training, offering a unique blend of resistance and flexibility to your workouts. In this comprehensive guide, we'll cover versatile exercises that you can integrate into your fitness regimen, and explain why medicine balls should be a staple in your exercise toolkit.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Build Core Strength and Power</h1>
                        <p>One of the greatest benefits of medicine ball training is the emphasis on core strength. Engaging the core is essential for stability and power in all movements, and medicine balls provide the resistance needed to challenge these muscles effectively. From Russian twists to medicine ball slams, these exercises are designed to enhance your core strength, improve your power output, and increase overall athletic performance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhance Coordination and Balance</h1>
                    <p>Medicine ball exercises are not just about strength; they're also fantastic for improving coordination and balance. By incorporating movements that require you to throw, catch, and move dynamically with the ball, you're training your body to coordinate multiple muscle groups simultaneously. This can lead to better body awareness and stability, both of which are crucial for daily activities and sports performance.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Functional Training for Everyday Life</h1>
                    <p>Medicine ball training aligns perfectly with functional fitness, which focuses on exercises that simulate everyday movements. By using a medicine ball, you can perform movements that mimic real-life activities, such as lifting, throwing, and reaching. This type of training is beneficial for all ages and fitness levels because it helps improve the ease of daily movements and reduces the risk of injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Challenge Your Workout Intensity</h1>
                    <p>Looking to ramp up the intensity of your workouts? Medicine balls are the perfect tool to add extra resistance to your exercises, pushing you further than bodyweight movements alone. Whether you're performing squats, lunges, or overhead presses, incorporating a medicine ball can significantly increase the difficulty and, consequently, the effectiveness of your workout.</p>
                    <br></br>
                    <p>
                        Below are 10 versatile medicine ball exercises to get you started:
                        <ul>
                            <li>Medicine Ball Slam</li>
                            <li>Wall Balls</li>
                            <li>Rotational Throws</li>
                            <li>Medicine Ball Push-Ups</li>
                            <li>V-Ups with Medicine Ball</li>
                            <li>Lunge with Twist</li>
                            <li>Medicine Ball Burpees</li>
                            <li>Single-Leg Deadlift with Medicine Ball</li>
                            <li>Medicine Ball Squat to Press</li>
                            <li>Plank Rolls with Medicine Ball</li>
                        </ul>
                        Embrace these exercises to add variety and challenge to your fitness journey at Ramp Physio and Fitness.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingwithmedicineballsversatileexercises;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingTheGlycemicIndexForEnergyManagement = () => {
    return (
        <div>
            <Helmet>
                <title>Understanding the Glycemic Index for Energy Management</title>
                <meta name="description" content="Learn how the Glycemic Index can aid in effective energy management for fitness and overall health." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Manage Energy with the Glycemic Index</h1>
                    <h2>Unlock the power of the Glycemic Index for sustained energy and optimal health at Ramp Physio and Fitness.</h2>
                    <p>Understanding the Glycemic Index (GI) is crucial for anyone looking to manage their energy levels and maintain a healthy lifestyle. At Ramp Physio and Fitness, we believe in empowering our clients with knowledge that can transform their dietary habits and fitness routines. This comprehensive guide will explore the principles of the GI and how it can be integrated into your daily life for better energy management and overall well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Understanding the Glycemic Index" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>What Is the Glycemic Index?</h1>
                        <p>The Glycemic Index is a ranking system that measures how much certain foods raise blood glucose levels compared to pure glucose. Foods are scored on a scale of 0 to 100, with higher values indicating a quicker rise in blood glucose. Understanding GI can help with dietary choices that support stable energy levels throughout the day.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>GI and Energy Management</h1>
                    <p>By selecting foods with a low to moderate GI, you can achieve a slower, more controlled release of energy. This is particularly beneficial for athletes, individuals with diabetes, and anyone seeking to avoid energy spikes and crashes. Here are 10 examples of low-GI foods to include in your diet:</p>
                    <ul>
                        <li>Steel-cut oats</li>
                        <li>Quinoa</li>
                        <li>Lentils</li>
                        <li>Barley</li>
                        <li>Non-starchy vegetables</li>
                        <li>Most fruits</li>
                        <li>Whole grain bread</li>
                        <li>Low-fat dairy products</li>
                        <li>Nuts and seeds</li>
                        <li>Legumes</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>GI for Weight Management</h1>
                    <p>Adopting a low-GI diet can also be effective for weight management. Low-GI foods can help control appetite and delay hunger cues, which may lead to reduced calorie intake and weight loss. Incorporating low-GI foods into your diet can support your fitness goals by providing sustained energy for exercise and daily activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Planning Your GI Diet</h1>
                        <p>Creating a balanced, low-GI meal plan requires careful selection of foods and understanding their impact on your blood sugar levels. At Ramp Physio and Fitness, we can guide you through this process, helping you make informed choices that align with your health and fitness objectives.</p>
                        <br></br>
                        <p>
                            Embracing the Glycemic Index is a strategic approach to nutrition that can enhance your quality of life. Whether you are an athlete looking to optimize performance, or someone interested in better health, a low-GI diet is a powerful tool in your wellness arsenal. Connect with us to integrate the Glycemic Index into your lifestyle and take control of your energy and health.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingTheGlycemicIndexForEnergyManagement;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating the Road to Recovery Post-ACL Surgery</h1>
                    <h2>Discover the comprehensive approach to ACL surgery recovery, with insights into the rehabilitation process and tailored strategies for regaining strength and mobility at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand the pivotal role of expert care in recovering from anterior cruciate ligament (ACL) surgery. This delicate procedure requires not just surgical precision but also a well-charted rehabilitation program to ensure a successful return to your active lifestyle. With our dedicated team, we strive to guide you through each step, from pre-surgery preparation to post-surgery physiotherapy, ensuring you have the support needed for optimal recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding ACL Surgery and Recovery</h1>
                        <p>ACL surgery is a common but significant procedure aimed at repairing a torn anterior cruciate ligament in the knee—a crucial stabilizer for athletes and active individuals. The surgery involves replacing the damaged ligament with a graft, which can be sourced from the patient’s own body or from a donor. The recovery journey post-ACL surgery is as critical as the surgery itself, involving several stages of rehabilitation to restore knee functionality and strength.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Rehabilitation Process Unveiled</h1>
                        <p>Post-operative care is a multi-faceted approach that begins immediately after surgery. The initial focus is on reducing pain and swelling, followed by a gradual reintroduction of movement. As healing progresses, targeted exercises and physiotherapy play a key role in rebuilding strength, flexibility, and balance. Each phase of the rehabilitation process is designed to bring you closer to your pre-injury level of activity, while also focusing on injury prevention for the future.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Training for Optimal Outcomes</h1>
                        <p>At Ramp Physio and Fitness, we believe in a personalized approach to training during the recovery phase. Our physiotherapists assess your individual needs and tailor a training program that aligns with your specific goals. This bespoke plan not only aids in a smoother recovery but also helps in enhancing your overall physical fitness. Whether it's one-on-one sessions or group training classes, our methods are aimed at ensuring you regain full function and return to your desired level of activity safely.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Chronic Pain Management Strategies</h1>
                    <p>Chronic pain can be a challenging aftermath of ACL surgery for some individuals. Our team is adept at identifying and implementing chronic pain management techniques that not only alleviate discomfort but also address the root causes. Through a combination of hands-on therapy, exercise, and education, we empower you to manage pain effectively and improve your quality of life. Our goal is to ensure you can enjoy a pain-free return to your everyday activities and sports.</p>
                    <br></br>
                    <p>
                        ACL surgery is a journey that demands expertise, patience, and a personalized touch. At Ramp Physio and Fitness, we're committed to providing an environment where healing, strengthening, and prevention are the cornerstones of your recovery. By embracing our comprehensive approach to ACL surgery rehabilitation, you can look forward to a future where your knee is not a limitation but a testament to your resilience and our dedication to your health and fitness.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unraveling the Benefits of Remedial Massage Therapye</h1>
                    <h2>Discover the transformative power of remedial massage therapy in addressing specific health concerns, enhancing recovery, and promoting overall wellness at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that the journey to optimal health is multifaceted, incorporating a blend of specialized treatments and personalized care. Among the myriad of services we offer, remedial massage therapy stands out as a cornerstone of our integrative approach. Designed to target and alleviate the root causes of discomfort and pain, this therapeutic method is more than just a means of relaxation—it's a pathway to healing and improved physical function.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Remedial Massage Therapy</h1>
                        <p>Remedial massage therapy is a targeted form of massage that aims to treat muscles that are damaged, knotted, tense, or immobile. It is used to assist in the rehabilitation, pain, and injury management of a variety of health conditions. At its core, remedial massage therapy is about understanding the intricate web of muscles, tendons, and ligaments, and using this knowledge to bring relief and restore function.</p>
                        <p>When you visit Ramp Physio and Fitness, our skilled therapists employ a range of techniques to identify and repair damaged areas of the body. By stimulating the blood supply, remedial massage makes joints more mobile and helps to repair damaged tissues. The therapy has also been shown to reduce stress and tension, which can contribute to the overall healing process.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Healing Journey with Remedial Massage</h1>
                    <p>Embarking on a healing journey with remedial massage therapy means addressing the specific concerns that hinder your well-being. Whether you're dealing with chronic pain, recovering from a sports injury, or simply looking to improve your physical condition, remedial massage can be tailored to your needs.</p>
                    <p>Our therapists at Ramp Physio and Fitness are adept at treating a wide range of conditions, including but not limited to muscle cramps, whiplash, sports injuries, arthritis, and back pain. By employing techniques such as deep tissue massage, trigger point therapy, and myofascial release, we strive to alleviate pain, enhance function, and facilitate your body's natural healing process.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Remedial Massage into Your Wellness Regime</h1>
                    <p>Incorporating remedial massage therapy into your wellness regime can be a game-changer. Not only does it offer immediate relief from discomfort, but it also contributes to long-term health improvements. At Ramp Physio and Fitness, we advocate for a holistic approach to health care, where remedial massage is a key component of a broader strategy that includes physiotherapy, exercise, and good nutrition.</p>
                    <p>By making remedial massage a regular part of your health care routine, you can expect improved posture, increased mobility, and a reduction in the recurrence of pain and injury. It's an investment in your health that pays dividends in enhanced quality of life and performance.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Path Forward with Ramp Physio and Fitness</h1>
                    <p>Choosing Ramp Physio and Fitness for your remedial massage therapy means placing your trust in a team that is committed to your health and fitness goals. Our therapists are not only well-versed in various massage techniques but also understand the importance of creating a supportive and empowering environment for our clients.</p>
                    <br></br>
                    <p>
                        As you continue on your path to recovery and wellness, we encourage you to explore the full spectrum of services we offer. From NDIS support and chronic pain management to group training classes, our aim is to provide comprehensive care that addresses your unique needs. With remedial massage therapy as a vital tool in our arsenal, we are dedicated to helping you achieve and maintain optimum health.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

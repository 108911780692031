import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import RollingBannerSection from './RollingBannerSection';
import './Footerbar.css';

const FooterBar = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            firstName: formData.name, // using firstName field for consistency
            email: formData.email,
            phone: formData.phone,
        };

        emailjs.send('service_gvt5vwp', 'template_nwga3kh', templateParams, 'OpJMLUp7AgSF4zW4L')
            .then((response) => {
                alert('Sign up successful!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                });
            }, (error) => {
                alert('There was an error signing up.');
                console.error('Failed to send email:', error);
            });
    };

    return (
        <div className="footer-bar">
            <RollingBannerSection />
            <div className="footer-images">
                <img src="../assets/Gymfloor1.webp" alt="1" />
                <img src="../assets/Workouts.webp" alt="2" />
                <img src="../assets/ergonometer1.webp" alt="3" />
                <img src="../assets/Group train.webp" alt="4" />
                <img src="../assets/dumbbells.webp" alt="5" />
                <img src="../assets/Pinloaded.webp" alt="6" />
            </div>
            <div className="stay-updated">
                <div>

                 
      <h2>STAY UPDATED</h2>
                    <p>Sign up to be the first to find out when we have new services and special offers.</p>
                    <p>We respect your privacy and will never share your information with any third-party vendors.</p>
                    <br></br>
              

                       <h3>With onsite parking and near to major public transport, the Ramp Physio and Fitness team help treat locals for gym, personal training and physiotherapy from across Sydney, the inner west and nearby suburbs</h3>
                       <br></br>
                    <p>Homebush | Homebush West | Flemington | North Strathfield | Newington | Silverwater | Lidcombe | Concord | Concord West | Strathfield | Rhodes | Rosehill | Parramatta | West Ryde | Burwood | Five Dock | Ashfield | Croydon Park | Strathfield South | Greenacre | Chullora | Sydney Olympic Park | Auburn | Ermington | Liberty Grove | Cabrita | Greenacre | Belfield | Enfield | Campsie | Yagoona  | Bankstown | Lakemba | Rydalmere | Camellia | Rosehill | Canada Bay | Clyde</p>
                     <br></br>
                </div>
                <form className="signup-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Sign Up</button>
                </form>
            </div>
            <div className="separator-line"></div>
            <div className="footer-info">
                <div className="support">
                    <h3>SUPPORT</h3>
                    <ul>
                        <li><a href="/termsandconditions">Terms and Conditions</a></li>
                        <li><a href="/privacypolicy">Privacy Policy</a></li>
                        <li><a href="/faq">FAQs</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/team">Team</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/NDIS">NDIS</a></li>
                    </ul>
                </div>
                <div className="logo">
                    <img src="../assets/ramp-icon-update.png" alt="Logo" />
                </div>
                <div className="contact">
                    <h3>CONTACT</h3>
                    <p>208 Parramatta Rd, Homebush</p>
                    <p>info@rampphysioandfitness.com.au</p>
                    <p>(02) 8188 1618</p>
                    <br></br>
                    <p><strong>Same Day Appointments Guaranteed</strong></p>
                    <p><strong>Plentiful Free Onsite Parking</strong></p>
                    <br></br>
                    <div className="book-container">
                        <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="book-footer">
                            Book Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterBar;

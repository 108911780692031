import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForEnhancingFlexibility = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Enhancing Flexibility | Ramp Physio and Fitness</title>
                <meta name="description" content="Unlock your full range of motion with our tailored gym workouts designed to enhance flexibility. Reach your health and fitness goals with Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimize Your Movement with Flexibility Training</h1>
                    <h2>Explore the transformative power of gym workouts for enhancing flexibility at Ramp Physio and Fitness, and take the first step towards achieving a more limber and resilient body.</h2>
                    <p>Flexibility is a cornerstone of physical fitness and overall well-being. At Ramp Physio and Fitness, we understand the importance of incorporating flexibility training into your gym routine. Our expertly designed workouts are tailored to improve your range of motion, reduce the risk of injury, and enhance athletic performance. In this comprehensive guide, we'll explore the best workouts for increasing flexibility and how you can integrate them into your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Mastering Flexibility Fundamentals</h1>
                        <p>Before diving into the workouts, it's essential to understand the fundamentals of flexibility. Flexibility training involves stretching exercises that aim to lengthen the muscles and increase the range of motion in your joints. This type of training is not only beneficial for athletes but for anyone looking to maintain a healthy and active lifestyle. By mastering the basics, you'll set a solid foundation for a more flexible future.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Dynamic Stretching for Active Warm-Ups</h1>
                    <p>Dynamic stretching is an active method of warming up your body before a workout. It involves moving parts of your body and gradually increasing reach, speed of movement, or both. Dynamic stretching is ideal for preparing your muscles for the specific movements you'll perform in your workout and can lead to improved performance and decreased risk of injuries.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategic Stretching Post-Workout</h1>
                    <p>After a rigorous gym session, your muscles are warm and pliable—making it the perfect time to stretch. Post-workout stretching can help reduce muscle soreness, accelerate recovery, and contribute to muscle growth. It's important to focus on static stretches, holding each stretch for at least 30 seconds to achieve the best results.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Top Exercises for Improved Flexibility</h1>
                        <ul>
                            <li>Seated Toe Touches</li>
                            <li>Standing Quadriceps Stretch</li>
                            <li>Lying Hamstring Stretch</li>
                            <li>Shoulder Stretch</li>
                            <li>Cat-Cow Stretch</li>
                            <li>Butterfly Stretch</li>
                            <li>Triceps Stretch</li>
                            <li>Hip Flexor Stretch</li>
                            <li>Child's Pose</li>
                            <li>Cobra Pose</li>
                        </ul>
                        <p>Incorporating these exercises into your routine can significantly enhance your flexibility. Each stretch targets different muscle groups, ensuring a comprehensive approach to increasing your range of motion.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForEnhancingFlexibility;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const BenefitsOfProprioceptiveTrainingInInjuryPrevention = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Movement with Proprioceptive Training</h1>
                    <h2>Unlock the secrets to injury prevention through the power of proprioceptive training at Ramp Physio and Fitness, and take your physical capabilities to new heights.</h2>
                    <p>Proprioceptive training is a cornerstone of injury prevention and rehabilitation at Ramp Physio and Fitness. By enhancing your body's ability to sense movement, position, and balance, proprioceptive exercises can significantly reduce the risk of injury and improve performance. In this comprehensive guide, we'll explore the benefits of proprioceptive training and how it can be integrated into your fitness routine to keep you moving smoothly and safely.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Proprioception</h1>
                        <p>Proprioception, often referred to as the "sixth sense," is the body's ability to perceive its own position in space. This sensory information allows for precise and coordinated movements. Good proprioception is essential for athletes and non-athletes alike, as it helps maintain balance, fine-tune motor skills, and protect the body from injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Proprioception and Injury Prevention</h1>
                    <p>Injury prevention is a critical aspect of any fitness regimen. Proprioceptive training helps to 'rewire' the nervous system, enhancing the body's ability to respond to potential injury-causing situations. This heightened awareness and control play a pivotal role in preventing common injuries such as sprains, strains, and falls.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Proprioceptive Training Techniques</h1>
                    <p>There are a variety of techniques used in proprioceptive training, each designed to challenge and improve your body's positional awareness. These exercises can range from simple balance tasks to complex movements that require a high degree of coordination and skill.</p>
                    <ul>
                        <li>Balance boards and wobble cushions</li>
                        <li>Closed-eye exercises</li>
                        <li>Single-leg stands and squats</li>
                        <li>Plyometric drills</li>
                        <li>Agility ladders</li>
                        <li>Resistance band exercises</li>
                        <li>Bosu ball workouts</li>
                        <li>Dynamic stretching</li>
                        <li>Functional movement patterns</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Proprioception in Sports Performance</h1>
                    <p>For athletes, superior proprioception can be the difference between good and great performance. By integrating proprioceptive training into your routine, you can enhance your body's ability to execute complex movements with precision, leading to improved performance in your sport.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Rehabilitation and Proprioception</h1>
                    <p>Proprioceptive training is a vital component of rehabilitation programs. Following an injury, proprioceptive exercises help to restore the body's sense of position and movement, which is often compromised. This restoration is crucial for a full recovery and return to activity.</p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Your Proprioceptive Potential</h1>
                    <p>At Ramp Physio and Fitness, we are committed to helping you achieve your maximum proprioceptive potential. Our tailored programs are designed to challenge your body's proprioceptive system in a safe and progressive manner, ensuring that you can move confidently and injury-free.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default BenefitsOfProprioceptiveTrainingInInjuryPrevention;
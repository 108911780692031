import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForJuvenileArthritis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Juvenile Arthritis with Physiotherapy</h1>
                    <h2>Explore the benefits of specialized physiotherapy for Juvenile Arthritis at Ramp Physio and Fitness, where we tailor strategies to help young individuals lead active, fulfilling lives.</h2>
                    <p>Juvenile Arthritis (JA) can be a challenging condition for children and their families, but with the right approach, it's possible to manage symptoms and improve quality of life. At Ramp Physio and Fitness, we specialize in physiotherapy for Juvenile Arthritis, offering evidence-based treatments designed to reduce pain, increase mobility, and support overall well-being. In this comprehensive guide, we'll discuss how physiotherapy can be a cornerstone in the management of JA and what our dedicated team can do to help.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Juvenile Arthritis</h1>
                        <p>Juvenile Arthritis is the umbrella term used to describe various pediatric rheumatic diseases. These conditions can cause joint inflammation, pain, and stiffness in children. Physiotherapy plays a crucial role in managing JA by providing targeted exercises and therapies that promote joint health and function. Our team at Ramp Physio and Fitness works closely with young patients and their families to develop personalized treatment plans that address the unique challenges of JA.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Techniques for JA</h1>
                    <p>Our physiotherapy approach for Juvenile Arthritis includes a variety of techniques tailored to each child's needs. These may include:
                        <ul>
                            <li>Gentle strengthening exercises</li>
                            <li>Range-of-motion activities</li>
                            <li>Pain management strategies</li>
                            <li>Hydrotherapy</li>
                            <li>Posture education</li>
                            <li>Functional training</li>
                            <li>Joint protection techniques</li>
                            <li>Soft tissue mobilization</li>
                            <li>Orthotic recommendations</li>
                            <li>Family and caregiver education</li>
                        </ul>
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of Physiotherapy for JA</h1>
                    <p>Physiotherapy offers numerous benefits for children with Juvenile Arthritis, including:
                        <ul>
                            <li>Alleviating joint pain and discomfort</li>
                            <li>Improving joint mobility and range of motion</li>
                            <li>Enhancing muscle strength and coordination</li>
                            <li>Reducing the risk of joint deformities</li>
                            <li>Increasing overall physical function</li>
                            <li>Promoting independence in daily activities</li>
                        </ul>
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Family Involvement in Treatment</h1>
                    <p>At Ramp Physio and Fitness, we believe that family involvement is key to the success of a child's treatment for Juvenile Arthritis. We provide education and support to families, ensuring that home exercises and care strategies are understood and implemented effectively. By working together, we can create a supportive environment that encourages children to stay active and manage their condition confidently.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy is an essential part of managing Juvenile Arthritis. Our team at Ramp Physio and Fitness is dedicated to providing the highest quality of care to our young patients, helping them to overcome the challenges of JA and enjoy a more active, pain-free life. Contact us to learn more about our specialized physiotherapy services for Juvenile Arthritis and how we can support your child's health and well-being.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForJuvenileArthritis;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyApproachesToTreatingBursitis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Bursitis with Expert Physiotherapy</h1>
                    <h2>Explore the advanced physiotherapy approaches to treating bursitis at Ramp Physio and Fitness, tailored to reduce inflammation and restore mobility.</h2>
                    <p>Bursitis can be a painful and limiting condition, impacting your ability to move freely and perform daily activities. At Ramp Physio and Fitness, we understand the challenges bursitis presents and offer a range of expert physiotherapy approaches to treat this condition. Our team is dedicated to alleviating pain, reducing inflammation, and improving joint function through personalized treatment plans. This article will guide you through the effective physiotherapy techniques that can help manage and overcome bursitis.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Bursitis and Its Impact</h1>
                        <p>Bursitis is a condition characterized by the inflammation of the bursae, small fluid-filled sacs that cushion the bones, tendons, and muscles near your joints. It can lead to significant discomfort and restrict movement. Understanding the causes and symptoms of bursitis is the first step towards effective treatment and recovery. Our physiotherapists are skilled in diagnosing and addressing the specific type of bursitis you may be experiencing, whether it's in your shoulder, hip, elbow, or knee.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Plans for Bursitis</h1>
                    <p>Each case of bursitis is unique, and so are our treatment plans. At Ramp Physio and Fitness, we tailor our physiotherapy approaches to match your specific needs. By conducting thorough assessments and utilizing a combination of manual therapy, exercise prescriptions, and advanced modalities, we strive to reduce your pain and enhance your joint function. Our goal is to not only address the symptoms but also to target the underlying causes of bursitis to prevent recurrence.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Techniques for Bursitis Relief</h1>
                    <p>Our physiotherapists employ a variety of techniques to alleviate bursitis symptoms and promote healing. These methods include soft tissue massage, joint mobilization, ultrasound therapy, and dry needling. We also emphasize the importance of strength and flexibility exercises to support the affected joint and surrounding structures. Here are 10 examples of how we approach bursitis treatment:</p>
                    <ul>
                        <li>Tailored exercise programs to strengthen muscles</li>
                        <li>Manual therapy to improve joint mobility</li>
                        <li>Soft tissue massage to reduce muscle tension</li>
                        <li>Ultrasound therapy to promote tissue healing</li>
                        <li>Dry needling to alleviate trigger points</li>
                        <li>Heat and ice therapy to manage inflammation</li>
                        <li>Education on activity modification to prevent aggravation</li>
                        <li>Ergonomic advice to improve posture and joint use</li>
                        <li>Gait analysis and correction for lower limb bursitis</li>
                        <li>Guidance on the use of supportive devices, if necessary</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Embracing a Holistic Approach to Recovery</h1>
                    <p>At Ramp Physio and Fitness, we believe in a holistic approach to health and wellness. Our treatment for bursitis extends beyond the clinic, as we empower you with knowledge and strategies to maintain joint health and prevent future issues. We work closely with you to integrate physiotherapy into your lifestyle, ensuring long-term success in managing bursitis and enhancing your overall well-being.</p>
                    <br></br>
                    <p>
                        In summary, our expert physiotherapy approaches to treating bursitis are designed to provide you with relief from pain and help you regain function. With a focus on personalized care and evidence-based techniques, Ramp Physio and Fitness is your partner in overcoming bursitis and achieving your health and fitness goals. Contact us today to start your journey to recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyApproachesToTreatingBursitis;
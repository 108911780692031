import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Highintensityintervaltraininghiitexplained = () => {
    return (
        <div>
            <Helmet>
                <title>HIIT Explained | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>High-Intensity Interval Training (HIIT) Explained</h1>
                    <h2>Unleash Your Potential with HIIT at Ramp Physio and Fitness</h2>
                    <p>High-Intensity Interval Training (HIIT) is a transformative workout methodology that combines short bursts of intense exercise with periods of rest or lower-intensity exercise. At Ramp Physio and Fitness, we harness the power of HIIT to help you achieve remarkable fitness gains in a shorter period. Whether you're pressed for time or looking to elevate your fitness routine, our HIIT program is designed to boost your metabolism, enhance cardiovascular health, and improve muscular endurance. Let's dive into the world of HIIT and explore how it can revolutionize your workout regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="HIIT Workout" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>HIIT: A Powerful Fitness Tool</h1>
                        <p>HIIT is not just a workout; it's a potent fitness tool that can be tailored to suit anyone's fitness level. Whether you're a seasoned athlete or just starting your fitness journey, HIIT's adaptable nature allows for modifications that can challenge you at your current fitness level. Below are 10 key benefits of incorporating HIIT into your fitness routine:</p>
                        <ul>
                            <li>Efficient calorie burning</li>
                            <li>Increased metabolic rate</li>
                            <li>Improved cardiovascular health</li>
                            <li>Targeted muscle toning</li>
                            <li>Enhanced endurance and stamina</li>
                            <li>Greater workout variety</li>
                            <li>Convenience and time-saving</li>
                            <li>Mental health benefits</li>
                            <li>Boosted energy levels</li>
                            <li>Support in weight loss goals</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>HIIT Timing and Structure</h1>
                    <p>The structure of a HIIT workout is key to its effectiveness. Typically, a HIIT session involves a warm-up followed by several rounds of high-intensity exercises, interspersed with rest or low-intensity intervals, and concludes with a cool-down. The duration of these intervals can vary, but the principle remains the same: push your body to its limit for a short time, then allow it to recover just enough before going hard again. This cycle is repeated several times, creating a workout that can be completed in as little as 30 minutes.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized HIIT at Ramp</h1>
                    <p>At Ramp Physio and Fitness, we believe in customizing HIIT sessions to fit your unique needs and goals. Our expert trainers and physiotherapists work together to create a HIIT program that is safe, effective, and aligned with your fitness objectives. Whether you aim to improve sports performance, increase muscle strength, or boost your overall health, we have the expertise to guide you through your HIIT journey, ensuring you perform each exercise with proper form and intensity.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Our HIIT Community</h1>
                    <p>Being part of a community can significantly enhance your motivation and commitment to fitness. At Ramp Physio and Fitness, you're not just joining a gym; you're becoming part of a supportive community that shares your passion for health and well-being. Our HIIT classes are designed to foster a sense of camaraderie and teamwork, making every session not only a workout but also a social and fun experience. Together, we celebrate each other's successes and push through challenges, creating an uplifting environment for all.</p>
                    <br></br>
                    <p>
                        In summary, HIIT is a versatile and efficient way to work out, offering numerous benefits for both your physical and mental health. At Ramp Physio and Fitness, we're dedicated to providing you with top-notch HIIT training that's tailored to your individual needs. Join us to experience the energy, the challenge, and the results that HIIT can bring to your life. Book your session today and take the first step towards a fitter, stronger, and healthier you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Highintensityintervaltraininghiitexplained;
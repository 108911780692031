import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Developingastrongcoreexercisesbeyondsitups = () => {
    return (
        <div>
            <Helmet>
                <title>Developing a Strong Core: Exercises Beyond Sit-Ups | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective core exercises beyond traditional sit-ups with Ramp Physio and Fitness. Strengthen your core for better health and performance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Core Reinforcement Mastery</h1>
                    <h2>Uncover the secrets to a robust core with exercises that outshine sit-ups, brought to you by the experts at Ramp Physio and Fitness.</h2>
                    <p>Developing a strong core is vital for not only your fitness goals but also for your overall well-being. Traditional sit-ups have long been the go-to exercise, but there's a world of movements that can enhance core strength more effectively. In this comprehensive guide, we'll explore the top exercises that surpass sit-ups in building a resilient and powerful core. Ready to revolutionize your core workout? Let's dive in.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Core Strength" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Core Complexity Unraveled</h1>
                        <p>The core is more than just your abs; it's a complex system that includes the muscles in your pelvis, lower back, hips, and abdomen. These muscles work in harmony to support posture, balance, and stability. A strong core is the foundation for all physical activities, from everyday tasks to high-performance sports. By moving beyond sit-ups, we target these muscles in a more functional and effective way.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Planks: The Core Challenger</h1>
                    <p>Planks are a powerhouse when it comes to core strengthening. They engage multiple muscle groups simultaneously, offering a comprehensive workout for the entire core. Unlike sit-ups, planks reduce the risk of back injury and can be modified to increase difficulty or target specific areas. Here are 10 plank variations to try:</p>
                    <ul>
                        <li>Standard forearm plank</li>
                        <li>Side plank</li>
                        <li>Reverse plank</li>
                        <li>Plank with leg lift</li>
                        <li>Plank with arm lift</li>
                        <li>Spiderman plank</li>
                        <li>Plank jacks</li>
                        <li>Plank up-downs</li>
                        <li>Plank with knee to elbow</li>
                        <li>Extended plank</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Dynamic Core Circuit</h1>
                    <p>Building a strong core requires more than static exercises; it requires dynamic movements that mimic real-life activities. Here's a circuit of dynamic exercises designed to boost core strength and stability:</p>
                    <ul>
                        <li>Dead bug</li>
                        <li>Bird dog</li>
                        <li>Medicine ball slams</li>
                        <li>Woodchoppers</li>
                        <li>Russian twists</li>
                        <li>Mountain climbers</li>
                        <li>Rollouts with an exercise wheel</li>
                        <li>Stability ball pikes</li>
                        <li>Rotational throws</li>
                        <li>Turkish get-up</li>
                    </ul>
                    <p>Perform each exercise for 45 seconds with a 15-second rest in between. Repeat the circuit 2-3 times for an intense core workout.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Stability and Mobility Combo</h1>
                        <p>Core exercises should not only focus on strength but also on stability and mobility. Engaging in movements that challenge your balance and range of motion will enhance your core's functionality. Try these exercises to integrate stability and mobility into your core training:</p>
                        <ul>
                            <li>Swiss ball crunches</li>
                            <li>Stability ball knee tucks</li>
                            <li>Hanging leg raises</li>
                            <li>Swiss Ball training</li>
                            <li>Isometric holds such as plank</li>
                            <li>Dragon flag</li>
                            <li>Ab wheel rollouts</li>
                            <li>Cable rotation</li>
                            <li>Bosu ball squats</li>
                            <li>L-sit hold</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Developingastrongcoreexercisesbeyondsitups;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ImprovingFlexibilityAndMobilityThroughStretching = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Improving Flexibility and Mobility</h1>
                    <h2>Unlock your body's potential with expert stretching techniques at Ramp Physio and Fitness, your partner in achieving peak physical performance.</h2>
                    <p>At Ramp Physio and Fitness, we recognize the vital importance of flexibility and mobility for a healthy, active lifestyle. Whether you're an athlete looking to enhance performance or simply seeking to improve your daily functional movements, our comprehensive stretching programs are designed to increase your range of motion and reduce injury risk. Join us as we explore the benefits of stretching, understand the science behind it, and provide practical examples to incorporate into your routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Improving Flexibility and Mobility" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science of Stretching</h1>
                        <p>Understanding the physiological mechanisms behind stretching is crucial for maximizing its benefits. Stretching exercises help improve elasticity in your muscles and connective tissues, promoting increased blood flow and nutrient delivery to these areas. This not only aids in recovery from workouts but also enhances overall muscular function and joint mobility. By incorporating a variety of stretching techniques, such as dynamic, static, and proprioceptive neuromuscular facilitation (PNF), you can target different aspects of flexibility and mobility for comprehensive improvement.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Regular Stretching</h1>
                    <p>Regular stretching has numerous benefits that extend beyond the gym. It can lead to improved posture, reduced muscle soreness, and a lower chance of injury during physical activities. Stretching also plays a role in stress relief, as it can help release tension held in muscles, contributing to a more relaxed state of mind. By making stretching a consistent part of your routine, you can experience enhanced physical performance and an overall sense of well-being.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Stretching Techniques Explored</h1>
                    <p>Discover the various stretching techniques that can be tailored to your needs and preferences. Dynamic stretching, ideal for warming up, involves moving parts of your body and gradually increasing reach, speed, or both. Static stretching, best performed after exercise, involves stretching a muscle to the point of mild discomfort and holding the position. PNF stretching, often done with a partner, combines passive stretching and isometric contractions to achieve maximum flexibility gains.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Creating a Stretching Routine</h1>
                        <p>Developing a personalized stretching routine is key to improving flexibility and mobility. It should cater to your individual goals, whether that's enhancing athletic performance, aiding in muscle recovery, or simply improving functional daily movement. Here are 10 examples of stretches to include in your routine:</p>
                        <ul>
                            <li>Hamstring Stretch</li>
                            <li>Quadriceps Stretch</li>
                            <li>Shoulder Stretch</li>
                            <li>Triceps Stretch</li>
                            <li>Chest Stretch</li>
                            <li>Hip Flexor Stretch</li>
                            <li>Glute Stretch</li>
                            <li>Lower Back Stretch</li>
                            <li>Calf Stretch</li>
                            <li>Ankle Stretch</li>
                        </ul>
                        <p>Remember to warm up your muscles with light activity before stretching, and to never push yourself to the point of pain. Consistency and proper technique are essential for achieving the best results.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ImprovingFlexibilityAndMobilityThroughStretching;
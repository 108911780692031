import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Timeefficientworkoutsforbusyschedules = () => {
    return (
        <div>
            <Helmet>
                <title>Time-Efficient Workouts for Busy Schedules - Ramp Physio and Fitness</title>
                <meta name="description" content="Maximize your fitness with our time-efficient workout plans designed for busy schedules. Achieve health goals quickly and effectively with Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Fitness in Minimal Time</h1>
                    <h2>Unlock the potential of time-efficient workouts at Ramp Physio and Fitness and transform your busy schedule into a fitness opportunity.</h2>
                    <p>At Ramp Physio and Fitness, we understand that finding time for exercise can be challenging for those with busy schedules. That's why we've crafted a range of time-efficient workouts that deliver results without demanding hours from your day. In this comprehensive guide, we'll explore strategies and workout plans that fit seamlessly into your lifestyle, ensuring that you stay fit, healthy, and energized—no matter how packed your calendar is.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Quick and Effective Routines</h1>
                        <p>Time-efficient workouts are not just quick fixes; they're carefully designed to maximize every minute of your exercise routine. By focusing on high-intensity interval training (HIIT), circuit training, and functional movements, you can achieve comprehensive fitness results in shorter sessions. Here are 10 examples of time-efficient workouts:</p>
                        <ul>
                            <li>HIIT treadmill sprints</li>
                            <li>Bodyweight circuit sessions</li>
                            <li>Kettlebell complexes</li>
                            <li>Tabata-style workouts</li>
                            <li>Resistance band routines</li>
                            <li>Swimming intervals</li>
                            <li>Boxing or kickboxing drills</li>
                            <li>Core-focused Pilates</li>
                            <li>Outdoor running and agility drills</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strategies for Busy Bees</h1>
                    <p>Integrating workouts into a hectic schedule requires strategic planning. Here are six key ideas to help you stay on track:</p>
                        <ol>
                            <li>Plan your workouts for the week ahead.</li>
                            <li>Combine social and fitness activities.</li>
                            <li>Choose workouts that require minimal equipment.</li>
                            <li>Opt for morning sessions to boost energy for the day.</li>
                            <li>Incorporate active commuting whenever possible.</li>
                            <li>Utilize lunch breaks for quick gym sessions or walks.</li>
                        </ol>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personal Training for Efficiency</h1>
                    <p>Personal training sessions at Ramp Physio and Fitness are tailored to your unique needs, ensuring that you make the most of your time. Our certified trainers specialize in creating efficient workout plans that align with your goals, schedule, and fitness level. Whether you have 30 minutes or an hour, we'll help you maximize every second for optimal results.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Group Fitness: Fun and Fast</h1>
                    <p>Group fitness classes at Ramp Physio and Fitness offer an energetic and time-effective way to work out. Our classes are designed to keep you moving and motivated, with a variety of options that fit into even the busiest schedules. From spin classes to boot camps, there's a group session that will get your heart pumping and help you reach your fitness goals quickly.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Timeefficientworkoutsforbusyschedules;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const BalanceAndFallPreventionStrategiesForSeniors = () => {
    return (
        <div>
            <Helmet>
                <title>Balance and Fall Prevention Strategies for Seniors | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Balance and Fall Prevention for Seniors</h1>
                    <h2>Empower your independence with expert balance and fall prevention strategies at Ramp Physio and Fitness, designed to help seniors maintain mobility and prevent injuries.</h2>
                    <p>As we age, maintaining balance and preventing falls becomes increasingly important for a healthy and independent lifestyle. At Ramp Physio and Fitness, we understand the challenges that seniors face and offer specialized strategies to help reduce the risk of falls. This article provides valuable insights into fall prevention exercises, environmental modifications, and lifestyle changes that can enhance stability and safety for older adults.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Balance and Fall Prevention" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Fall Risks</h1>
                        <p>Recognizing the factors that contribute to falls is the first step in prevention. Age-related changes, medical conditions, and medications can affect balance and coordination. Environmental hazards and improper footwear also play a role. By addressing these risks, we can create a tailored prevention plan for each individual.</p>
                        <ul>
                            <li>Age-related sensory decline</li>
                            <li>Medication side effects</li>
                            <li>Chronic health conditions</li>
                            <li>Environmental trip hazards</li>
                            <li>Inadequate lighting</li>
                            <li>Cluttered living spaces</li>
                            <li>Unsupportive footwear</li>
                            <li>Lack of physical activity</li>
                            <li>Impaired vision or hearing</li>
                            <li>Improper use of mobility aids</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Fall Prevention Exercises</h1>
                    <p>Exercise is a cornerstone of fall prevention. Strengthening the muscles used for balance and improving flexibility can significantly reduce fall risk. Our programs include exercises to enhance gait, proprioception, and overall stability. Here are key exercises we recommend:</p>
                    <ul>
                        <li>Tai Chi for balance and flexibility</li>
                        <li>Leg strength training</li>
                        <li>Balance-enhancing movements</li>
                        <li>Coordination drills</li>
                        <li>Walking pattern practice</li>
                        <li>Posture correction exercises</li>
                        <li>Proprioceptive training</li>
                        <li>Resistance band workouts</li>
                        <li>Core strengthening routines</li>
                        <li>Gentle stretching for mobility</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Home Safety Assessments</h1>
                    <p>Modifying the living environment is crucial for preventing falls. Our team provides comprehensive home safety assessments to identify and mitigate potential hazards. Simple adjustments such as securing rugs, installing grab bars, and improving lighting can make a significant difference in safety and confidence at home.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Educational Workshops and Support</h1>
                    <p>Knowledge is power when it comes to fall prevention. We offer educational workshops for seniors and caregivers that cover a wide range of topics, including nutrition, medication management, and the use of assistive devices. Supporting seniors in making informed choices is key to preventing falls and maintaining independence.</p>
                </div>
            </div>
            </SkewedContainer>
                        
            <div className="news-section">
            <div className="news-header">
<h2>Physiotherapy & Gym Training Knowledge</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
            </div>
            <div className="news-content">
                {/* First Column */}
                <div className="news-column">
                <React.Fragment>
                    <div className="news-item">
                    <Link to="/services">
                        <p>Services</p>
                    </Link>
                    </div>
                    <div className="news-item-separator">
                    <p>•</p>
                    </div>
                    <div className="news-item">
                    <Link to="/news/understandingphysiotherapy">
                        <p>Understanding Physiotherapy</p>
                    </Link>
                    </div>
                    <div className="news-item-separator">
                    <p>•</p>
                    </div>
                    <div className="news-item">
                    <Link to="/news/physiotherapyforspecificconditions">
                        <p>Physiotherapy for Specific Conditions</p>
                    </Link>
                    </div>
                </React.Fragment>
                </div>

                {/* Second Column */}
                <div className="news-column">
                <React.Fragment>
                    <div className="news-item">
                    <Link to="/news/rehabilitation">
                        <p>Rehabilitation</p>
                    </Link>
                    </div>
                    <div className="news-item-separator">
                    <p>•</p>
                    </div>
                    <div className="news-item">
                    <Link to="/news/gymknowledge">
                        <p>Gym Knowledge Base</p>
                    </Link>
                    </div>
                    <div className="news-item-separator">
                    <p>•</p>
                    </div>
                    <div className="news-item">
                    <Link to="/news/benefitsofgymtraining">
                        <p>Benefits of Gym Training</p>
                    </Link>
                    </div>
                </React.Fragment>
                </div>
            </div>
            </div>

            <a 
            href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
            target="_blank"
            rel="noopener noreferrer"
            className="servicedetail-book-now"
            >
            Book Now
            </a>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default BalanceAndFallPreventionStrategiesForSeniors;
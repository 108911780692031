import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForCarpalTunnelSyndromeRelief = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Combat Carpal Tunnel with Physiotherapy</h1>
                    <h2>Unlock the path to relief from Carpal Tunnel Syndrome with expert physiotherapy techniques at Ramp Physio and Fitness, tailored to restore your hand function and comfort.</h2>
                    <p>Carpal Tunnel Syndrome (CTS) is a common condition that causes pain, numbness, and tingling in the hand and arm. At Ramp Physio and Fitness, we understand the impact that CTS can have on your daily life. Our physiotherapy program for Carpal Tunnel Syndrome relief is designed to alleviate symptoms and improve hand function through personalized treatment plans. In this comprehensive guide, we'll explore the benefits of physiotherapy for CTS and how our expert team can help you regain control over your hand health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Carpal Tunnel Syndrome</h1>
                        <p>Carpal Tunnel Syndrome is a condition that arises from pressure on the median nerve as it passes through the carpal tunnel, a narrow passageway in the wrist. This condition can be caused by repetitive motions, such as typing or any activities that involve prolonged wrist flexion or extension. Understanding CTS is the first step towards effective treatment, and our physiotherapists are equipped with the knowledge to guide you through your recovery journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Plans</h1>
                    <p>Each case of Carpal Tunnel Syndrome is unique, and so should be the treatment. Our physiotherapists create personalized plans that may include manual therapy, exercises, and education on ergonomics and hand positioning. By tailoring the treatment to your specific needs, we aim to provide the most effective relief from CTS symptoms.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for CTS Relief</h1>
                    <p>Exercise is a vital component of CTS treatment. Our physiotherapists may recommend specific exercises to stretch and strengthen the muscles of the hand and wrist. These exercises are designed to reduce pressure on the median nerve and can be easily incorporated into your daily routine.</p>
                    <ul>
                        <li>Wrist Flexor Stretch</li>
                        <li>Wrist Extensor Stretch</li>
                        <li>Median Nerve Glides</li>
                        <li>Tendon Gliding Exercises</li>
                        <li>Grip Strengthening Activities</li>
                        <li>Finger Extension Exercises</li>
                        <li>Thumb Opposition Stretch</li>
                        <li>Wrist Lifts and Rotations</li>
                        <li>Palmar Stretch</li>
                        <li>Neural Flossing Techniques</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Ergonomics and Lifestyle Changes</h1>
                    <p>Adopting ergonomic principles and making lifestyle changes can significantly impact the management of Carpal Tunnel Syndrome. Our team provides expert advice on workstation setup, wrist positioning, and other modifications to reduce strain on your hands and wrists.</p>
                    <br></br>
                    <p>
                        In conclusion, Carpal Tunnel Syndrome doesn't have to limit your life. With the right physiotherapy approach, exercises, and ergonomic adjustments, you can find relief and regain function. At Ramp Physio and Fitness, we're dedicated to helping you overcome CTS and get back to the activities you love without discomfort. Contact us today to start your journey to recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForCarpalTunnelSyndromeRelief;
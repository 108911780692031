import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForWilliamsSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Williams Syndrome | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how NDIS physiotherapy can benefit individuals with Williams Syndrome at Ramp Physio and Fitness. Personalized care for unique needs." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Williams Syndrome</h1>
                    <h2>Personalized Care for Unique Needs at Ramp Physio and Fitness</h2>
                    <p>Williams Syndrome is a rare genetic condition that presents unique challenges, including cardiovascular disease, developmental delays, and musculoskeletal issues. At Ramp Physio and Fitness, we offer specialized NDIS physiotherapy services tailored to meet the distinct needs of individuals with Williams Syndrome. Our approach is to provide compassionate, comprehensive care that supports each client's physical, emotional, and social well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Williams Syndrome</h1>
                        <p>Williams Syndrome is characterized by a deletion of genetic material on chromosome 7. It affects many developmental processes, often leading to cardiovascular issues, distinctive facial features, and varying degrees of intellectual disability. Early, consistent physiotherapy can be vital in managing the physical aspects of the syndrome and improving quality of life.</p>
                        <p>Our NDIS physiotherapy program at Ramp Physio and Fitness is designed to cater to the complexities associated with Williams Syndrome. It includes exercises and therapies aimed at enhancing motor skills, reducing the risk of joint and muscle complications, and promoting independence in daily activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>NDIS Support and Physiotherapy Integration</h1>
                        <p>Through the National Disability Insurance Scheme (NDIS), individuals with Williams Syndrome can access services that are essential for their health and well-being. Our role at Ramp Physio and Fitness is to ensure that physiotherapy is seamlessly integrated into their NDIS plans.</p>
                        <p>We work closely with NDIS participants, their families, and other healthcare professionals to create a supportive network. Our goal is to deliver physiotherapy that not only aligns with each client's NDIS plan but also empowers them to reach their personal health and fitness goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Therapeutic Strategies for Motor Function</h1>
                    <p>Improving motor function is a crucial aspect of physiotherapy for individuals with Williams Syndrome. Our therapeutic strategies focus on:</p>
                    <p>
                        - Enhancing gross motor skills <br />
                        - Increasing muscle strength and endurance <br />
                        - Improving balance and coordination <br />
                        - Facilitating joint flexibility and mobility <br />
                        - Encouraging active participation in therapy sessions <br />
                        - Tailoring exercise programs to individual capabilities
                    </p>
                    <p>By addressing these key areas, we help our clients achieve greater autonomy and reduce the risk of secondary complications associated with sedentary lifestyles.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supporting Families and Caregivers</h1>
                        <p>At Ramp Physio and Fitness, we recognize the importance of supporting not just the individual with Williams Syndrome, but also their families and caregivers. Our services include:</p>
                        <p>
                            - Providing education on Williams Syndrome and its effects <br />
                            - Offering guidance on home exercises and activities <br />
                            - Collaborating on strategies to manage daily challenges <br />
                            - Encouraging family involvement in therapy sessions <br />
                            - Facilitating access to community resources and supports
                        </p>
                        <p>We are committed to fostering a collaborative environment where families feel informed, supported, and confident in the care their loved ones receive.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForWilliamsSyndrome;
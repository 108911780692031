import React from 'react';
import { useNavigate } from 'react-router-dom';
import './postsubmit_thankyou.css';

const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <div className="thank-you-page">
            <div className="thank-you-content">
                <div className="success-icon">
                    <svg viewBox="0 0 50 50" width="70" height="70">
                        <circle cx="25" cy="25" r="23" fill="#4CAF50"/>
                        <path d="M15 25 L23 33 L35 20" 
                              stroke="white" 
                              strokeWidth="3" 
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"/>
                    </svg>
                </div>
                <h1>Thank you for your message.</h1>
                <div className="contact-info">
                    <p>For further enquires please use the details below or the chat button on this page, thank you.</p>
                    <p>
                        <a href="tel:+61281881618" className="phone-link">
                            ☎ (02) 8188-1618
                        </a>
                    </p>
                    <p>
                        <a href="mailto:info@rampphysioandfitness.com.au" className="email-link">
                            ✉ info@rampphysioandfitness.com.au
                        </a>
                    </p>
                </div>
                <div className="button-group">
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now large-button"
                    >
                        Book Now
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
import React from 'react';
import './RollingBannerSection.css';

import logo1 from '../assets/logo1.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import logo4 from '../assets/logo4.png';
import logo5 from '../assets/logo5.png';
import logo6 from '../assets/logo6.png';
import logo7 from '../assets/logo7.png';
import logo8 from '../assets/logo8.png';
import logo9 from '../assets/logo9.png';
import logo10 from '../assets/logo10.png';
import logo11 from '../assets/logo11.png';
import logo12 from '../assets/logo12.png';
import logo13 from '../assets/logo13.png';
import logo14 from '../assets/logo14.png';
import logo15 from '../assets/logo15.png';
import logo16 from '../assets/logo16.png';
import logo17 from '../assets/logo17.png';
import logo18 from '../assets/logo18.png';
import logo19 from '../assets/logo19.png';
import logo20 from '../assets/logo20.png';
import logo21 from '../assets/logo21.png';
import logo22 from '../assets/logo22.png';
import logo23 from '../assets/logo23.png';
import logo24 from '../assets/logo24.png';
import logo25 from '../assets/logo25.png';
import logo26 from '../assets/logo26.png';
import logo27 from '../assets/logo27.png';
import logo28 from '../assets/logo28.png';
import logo29 from '../assets/logo29.png';
import logo30 from '../assets/logo30.png';
import logo31 from '../assets/logo31.png';
import logo32 from '../assets/logo32.png';
import logo33 from '../assets/logo33.png';


const RollingBannerSection = () => {
    const firstRowLogos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo31, logo32];
    const secondRowLogos = [logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20];
    const thirdRowLogos = [logo21, logo22, logo23, logo24, logo25, logo26, logo27, logo28, logo29, logo30, logo33];

    return (
        <div className="banner-section">
            <div className="banner-container">
                <div className="banner">
                    {firstRowLogos.map((logo, index) => (
                        <div key={index} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {firstRowLogos.map((logo, index) => (
                        <div key={index + firstRowLogos.length} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {firstRowLogos.map((logo, index) => (
                        <div key={index + firstRowLogos.length * 2} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="banner-container reverse">
                <div className="banner">
                    {secondRowLogos.map((logo, index) => (
                        <div key={index} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {secondRowLogos.map((logo, index) => (
                        <div key={index + secondRowLogos.length} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {secondRowLogos.map((logo, index) => (
                        <div key={index + secondRowLogos.length * 2} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="banner-container">
                <div className="banner">
                    {thirdRowLogos.map((logo, index) => (
                        <div key={index} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {thirdRowLogos.map((logo, index) => (
                        <div key={index + thirdRowLogos.length} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                    {thirdRowLogos.map((logo, index) => (
                        <div key={index + thirdRowLogos.length * 2} className="banner-item">
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default RollingBannerSection;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Understandingandtreatingosgoodschlatterdisease = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Conquering Osgood-Schlatter Disease</h1>
                    <h2>Unlock your child's potential by understanding and treating Osgood-Schlatter Disease with the expertise of Ramp Physio and Fitness.</h2>
                    <p>Osgood-Schlatter Disease (OSD) is a common cause of knee pain in growing adolescents, often affecting those who are active in sports. At Ramp Physio and Fitness, we understand the impact this condition can have on a young athlete's life. This comprehensive guide is designed to help you navigate the complexities of OSD, offering insights into its causes, symptoms, and a variety of treatment options including physiotherapy interventions that can facilitate recovery and return to activity.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding OSD Pathophysiology</h1>
                        <p>Osgood-Schlatter Disease typically arises during periods of rapid growth, when muscles, bones, and tendons are quickly changing. The condition is characterized by inflammation of the patellar tendon at the tibial tuberosity, leading to pain and swelling in the knee area. It is crucial for parents and coaches to recognize the signs of OSD early to prevent exacerbation of the condition and support timely management.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Identifying OSD Symptoms</h1>
                    <p>The hallmark symptom of OSD is knee pain that worsens with activity and subsides with rest. Other signs include swelling, tenderness, and a prominent bump just below the knee. It is important to note that while the condition is self-limiting and often resolves with time, proper management is key to minimizing discomfort and ensuring ongoing participation in physical activities.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Treatment Strategies</h1>
                    <p>Treatment for OSD focuses on reducing symptoms and maintaining flexibility and strength around the knee joint. Physiotherapy is a cornerstone of treatment, with specific exercises aimed at stretching and strengthening. Pain management may include modalities such as ice, rest, and nonsteroidal anti-inflammatory drugs (NSAIDs). In some cases, a temporary reduction in sports participation is necessary to allow for healing.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy's Role in Recovery</h1>
                    <p>At Ramp Physio and Fitness, our physiotherapists specialize in pediatric sports injuries like OSD. We assess each individual's condition and tailor a rehabilitation program that may include exercises to improve flexibility, strength, and proprioception. Our goal is not only to alleviate symptoms but also to address the underlying biomechanical factors that may contribute to OSD.</p>
                    <br></br>
                    <p>
                        Our commitment to treating OSD includes:
                        <ul>
                            <li>Individualized assessment and treatment planning</li>
                            <li>Exercise programs focusing on flexibility and strength</li>
                            <li>Education on activity modification and pain management</li>
                            <li>Techniques to promote tendon healing and reduce inflammation</li>
                            <li>Guidance on gradual return to sports and prevention of recurrence</li>
                            <li>Support for parents and young athletes throughout the recovery journey</li>
                        </ul>
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Understandingandtreatingosgoodschlatterdisease;
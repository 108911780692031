import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Elevating Your Game with Sports Performance Physiotherapy</h1>
                    <h2>Discover how sports performance physiotherapy can revolutionize your athletic potential and recovery process, ensuring you stay at the top of your game.</h2>
                    <p>Welcome to Ramp Physio and Fitness, where we understand that the pursuit of athletic excellence is a journey that demands not only dedication but also the right support system. As a professional physiotherapist, I have witnessed the transformative power of sports performance physiotherapy in enhancing athletes' abilities and aiding in swift recovery from injuries. In this comprehensive guide, we will delve into the world of sports performance physiotherapy, exploring its benefits, techniques, and the personalized approach we take to ensure you achieve optimal results.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Unlocking Athletic Potential</h1>
                        <p>At the heart of sports performance physiotherapy lies the commitment to unlocking an athlete's full potential. This specialized area of physiotherapy goes beyond general treatment—it's an integrated approach that combines injury prevention with performance enhancement. Our expert physiotherapists work closely with athletes to identify biomechanical issues that could be impeding performance or increasing the risk of injury. By addressing these issues with targeted interventions, athletes can experience improvements in strength, flexibility, and overall functionality.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Injury Recovery and Management</h1>
                    <p>Injuries are an unfortunate reality of sports, but they don't have to sideline you for long. With advanced sports performance physiotherapy techniques, we focus on efficient and effective recovery protocols that are tailored to your specific needs. Our approach is not just about treating the symptoms; it's about understanding the root cause of the injury and implementing strategies to ensure a resilient comeback. Through hands-on treatment, therapeutic exercises, and education on injury prevention, we empower athletes to take control of their recovery journey.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Training and Rehabilitation</h1>
                    <p>Every athlete is unique, and so is their path to recovery and performance enhancement. At Ramp Physio and Fitness, we pride ourselves on offering personalized training and rehabilitation programs that are meticulously crafted to align with your individual goals and physical capabilities. By integrating cutting-edge technology and evidence-based practices, we ensure that your training is not only effective but also safe and sustainable. Whether you're recovering from an injury or looking to elevate your performance, our tailored programs will help you reach new heights.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Chronic Pain and NDIS Support</h1>
                    <p>Chronic pain can be a significant barrier to athletic performance and overall well-being. Our physiotherapists are skilled in chronic pain management, employing a holistic approach to alleviate discomfort and improve quality of life. Additionally, for those eligible for the National Disability Insurance Scheme (NDIS), we offer comprehensive support to help manage and overcome physical challenges. Our goal is to enable athletes of all abilities to participate fully in their chosen sports and activities, free from the limitations of pain and disability.</p>
                    <br></br>
                    <p>
                      Sports performance physiotherapy is an essential component of any athlete's toolkit, providing the means to not only recover from injuries but also to prevent them and enhance overall performance. At Ramp Physio and Fitness, we are dedicated to delivering exceptional care through personalized treatment plans, state-of-the-art techniques, and a compassionate understanding of our clients'; aspirations. Whether you're a weekend warrior or a professional athlete, let us be your partner in achieving peak performance and maintaining a healthy, active lifestyle.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Gymworkoutsforteenagersbuildinghealthyhabits = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Teenagers | Ramp Physio and Fitness</title>
                <meta name="description" content="Building Healthy Habits for Teenagers through Gym Workouts at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Workouts for Teenagers</h1>
                    <h2>Building Healthy Habits for Life at Ramp Physio and Fitness</h2>
                    <p>Teenage years are formative in developing lifelong fitness habits. At Ramp Physio and Fitness, we understand the importance of establishing a strong foundation in physical health during adolescence. This article explores tailored gym workouts for teenagers, emphasizing safe practices, balanced routines, and the cultivation of a positive relationship with exercise.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Start Strong with Safe Practices</h1>
                        <p>Embarking on a fitness journey requires understanding the importance of safety to prevent injury and ensure sustainable progress. We prioritize educating teenagers on proper form, technique, and gym etiquette. Our certified trainers provide supervision and guidance to establish a secure workout environment for all our young members.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balance Is Key to Fitness</h1>
                    <p>Creating a well-rounded workout plan is essential for teenagers. At Ramp Physio and Fitness, our programs combine strength training, cardio exercises, flexibility workouts, and rest days to promote overall health and prevent overtraining. This balanced approach helps teenagers build a strong, resilient body while enjoying various forms of physical activity.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Positive Mindset, Positive Results</h1>
                    <p>Instilling a positive attitude towards fitness is crucial for teenagers. We encourage goal-setting, celebrating achievements, and fostering a supportive community. Our trainers work to build confidence and motivate our teen members to push their limits in a healthy and rewarding way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Workout Plans Tailored for Teens</h1>
                    <p>We recognize that teenagers have unique physiological and psychological needs. Our workout plans are specifically designed to cater to the developmental stages of adolescence, ensuring that each session contributes positively to their growth and well-being.</p>
                    <ul>
                        <li>Full-body strength circuits</li>
                        <li>Cardiovascular endurance training</li>
                        <li>Core stability exercises</li>
                        <li>Flexibility and mobility routines</li>
                        <li>Functional movement patterns</li>
                        <li>Team sports and group activities</li>
                        <li>Individually tailored and physiotherapy designed and monitored flexibility and core stability programs</li>
                        <li>Personalized fitness challenges</li>
                        <li>Nutritional guidance for active teens</li>
                        <li>Rest and recovery strategies</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Gymworkoutsforteenagersbuildinghealthyhabits;
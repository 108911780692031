import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ndisLogo from '../assets/ramp-icon-update.png';
import '../components/NDISCategoryConditionsList.css';

const NDISConditionsList = () => {
    const [expandedSections, setExpandedSections] = useState({});
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };

    const renderColumn = (title, links) => {
        const isExpanded = expandedSections[title];
        
        return (
            <div className="NDIS_category-column">
                {isMobile ? (
                    <>
                        <h5 
                            onClick={() => toggleSection(title)}
                            style={{ cursor: 'pointer' }}
                        >
                            {title} {isExpanded ? '▼' : '▶'}
                        </h5>
                        <div className="NDIS_category-links" style={{ 
                            display: isExpanded ? 'block' : 'none' 
                        }}>
                            {links}
                        </div>
                    </>
                ) : (
                    <>
                        <h3>{title}</h3>
                        <div className="NDIS_category-links">
                            {links}
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="NDIS_category-container">
            <img src={ndisLogo} alt="NDIS Logo" className="NDIS_category-logo" />
            <h2 className="NDIS_category-text">
                Ramp Physio and Fitness offers NDIS Physiotherapy and Gym services and support to participants with a wide range of disabilities, including:
            </h2>
            
            <div className={`NDIS_category-content ${isMobile ? 'NDIS_category-mobile-layout' : ''}`}>
                {/* {renderColumn("Frequently Asked Questions", (
                    <div className="news-links">
                        <Link to="/news/howoftenwillineedtoseemyndisphysio">
                            <p>How Often Will I Need to See My NDIS Physio?</p>
                        </Link>
                        <Link to="/news/whatdoesatypicalndisphysiosessionlooklike">
                            <p>What Does a Typical NDIS Physio Session Look Like?</p>
                        </Link>
                        <Link to="/news/willmyndisphysiotherapistgivemeexercisestodoathome">
                            <p>Will My NDIS Physiotherapist Give Me Exercises to Do at Home?</p>
                        </Link>
                    </div>
                ))} */}

                {renderColumn("Intellectual/Developmental Disorders", (
                    <div className="news-links">
                        <Link to="/news/ndisphysiotherapyforautismspectrumdisorder">
                            <p>Autism Spectrum Disorder (ASD)</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforadhd">
                            <p>ADHD NDIS</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforangelmanssyndrome">
                            <p>Angelman's Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforbardetbiedisyndrome">
                            <p>Bardet-Biedi Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforchromosomaldisorders">
                            <p>Chromosomal Disorders</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfordevelopmentaldelay">
                            <p>Developmental Delay</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfordownsyndrome">
                            <p>Down Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforfoetalalcoholspectrumsyndrome">
                            <p>Foetal Alcohol Spectrum Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforfragilexsyndrome">
                            <p>Fragile X Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforintellectualdisabilities">
                            <p>Intellectual Disabilities</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforpraderwillisyndrome">
                            <p>Prader-Willi Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforwilliamssyndrome">
                            <p>Williams Syndrome</p>
                        </Link>
                    </div>
                ))}

                {renderColumn("Mental Health Conditions", (
                    <div className="news-links">
                        <Link to="/news/ndisphysiotherapyforanxiety">
                            <p>Anxiety</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforbipolardisorder">
                            <p>Bipolar Disorder</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfordepression">
                            <p>Depression</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfordisorderedeating">
                            <p>Disordered Eating</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyformentalhealthconditions">
                            <p>Mental Health Conditions</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforpersonalitydisorders">
                            <p>Personality Disorders</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforpsychoticillnesses">
                            <p>Psychotic Illnesses</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforptsd">
                            <p>PTSD</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforschizophrenia">
                            <p>Schizophrenia</p>
                        </Link>
                    </div>
                ))}

                {renderColumn("Neuromuscular Conditions", (
                    <div className="news-links">
                        <Link to="/news/physiotherapytreatmentandexercisesforagedcarephysiotherapy">
                            <p>Aged Care Physiotherapy</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforbalanceproblems">
                            <p>Balance Problems</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforfibromyalgia">
                            <p>Fibromyalgia</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforgaitandbalance">
                            <p>Gait and Balance</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforsofttissueandmuscleinjuries">
                            <p>Soft Tissue and Muscle Injuries</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforsportsinjuries">
                            <p>Sports Injuries</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforsportsinjuryprevention">
                            <p>Sports Injury Prevention</p>
                        </Link>
                    </div>
                ))}
            </div>

            <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                target="_blank"
                rel="noopener noreferrer"
                className="servicedetail-book-now"
            >
                Book Now
            </a>

            <div className={`NDIS_category-content ${isMobile ? 'NDIS_category-mobile-layout' : ''}`}>
                {renderColumn("Neurological Conditions", (
                    <div className="news-links">
                        <Link to="/news/ndisphysiotherapyforacquiredandtraumaticbraininjuries">
                            <p>Acquired and Traumatic Brain Injuries</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforcerebralpalsy">
                            <p>Cerebral Palsy</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfordementia">
                            <p>Dementia</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforepilepsy">
                            <p>Epilepsy</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforguillainbarresyndrome">
                            <p>Guillain Barre Syndrome</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforhuntingtonsdisease">
                            <p>Huntington's Disease</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyformotorneuronedisease">
                            <p>Motor Neurone Disease</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforneurologicalconditions">
                            <p>Neurological Conditions</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforparkinsonsdisease">
                            <p>Parkinson's Disease</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforperipheralneuropathy">
                            <p>Peripheral Neuropathy</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforspinalcordinjuries">
                            <p>Spinal Cord Injuries</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforstroke">
                            <p>Stroke</p>
                        </Link>
                    </div>
                ))}

                {renderColumn("Chronic Pain and Conditions", (
                    <div className="news-links">
                        <Link to="/news/physiotherapytreatmentandexercisesforarthritis">
                            <p>Arthritis</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforbackpain">
                            <p>Back Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforchronicpain">
                            <p>Chronic Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesfordryneedling">
                            <p>Dry Needling</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforelbowpain">
                            <p>Elbow Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforheadaches">
                            <p>Headaches</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforhippain">
                            <p>Hip Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforkneepain">
                            <p>Knee Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforlegpain">
                            <p>Leg Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforlowerbackpain">
                            <p>Lower Back Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesformiddlebackpain">
                            <p>Middle Back Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesformigraines">
                            <p>Migraines</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforneckpain">
                            <p>Neck Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforshoulderpain">
                            <p>Shoulder Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforsidepain">
                            <p>Side Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforupperbackpain">
                            <p>Upper Back Pain</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforwhiplash">
                            <p>Whiplash</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesformotorvehicleinjuries">
                            <p>Motor Vehicle Injuries</p>
                        </Link>
                    </div>
                ))}

                {renderColumn("Treatments", (
                    <div className="news-links">
                        <Link to="/news/ndisphysiotherapyassistedstretchingtorelievestiffnessinjoints">
                            <p>Assisted Stretching to Relieve Stiffness in Joints</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyelectrotherapyandheattherapytoreduceinflammation">
                            <p>Electrotherapy and Heat Therapy to Reduce Inflammation</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyfunctionaltasktrainingincludingbalancingandmovement">
                            <p>Functional Task Training Including Balancing and Movement</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyimprovingmoodandenergylevels">
                            <p>Improving Mood and Energy Levels</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapystrengtheningexercisestoboostmuscleconditionandposture">
                            <p>Strengthening Exercises to Boost Muscle Condition and Posture</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapytissuesmassagestobreakuptensioninmuscles">
                            <p>Tissues Massages to Break Up Tension in Muscles</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapytrainingcapabilityinhandlingeverydayactionsandtasks">
                            <p>Training Capability in Handling Everyday Actions and Tasks</p>
                        </Link>
                        <Link to="/news/ndisphysiotherapyforndisphysiotherapy">
                            <p>NDIS Physiotherapy</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforpaediatricphysiotherapy">
                            <p>Paediatric Physiotherapy</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforpostnatalphysiotherapy">
                            <p>Post-Natal Physiotherapy</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforpostsurgicalrehabilitation">
                            <p>Post Surgical Rehabilitation</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforposturecorrection">
                            <p>Posture Correction</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforpregnancycare">
                            <p>Pregnancy Care</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforremedialmassage">
                            <p>Remedial Massage</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforsciatica">
                            <p>Sciatica</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforwhatwetreat">
                            <p>What We Treat</p>
                        </Link>
                        <Link to="/news/physiotherapytreatmentandexercisesforwomenshealthphysiotherapy">
                            <p>Women's Health Physiotherapy</p>
                        </Link>
                    </div>
                ))}
            </div>
            <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="servicedetail-book-now"
                >
                    Book Now
                </a>
        </div>
    );
};

export default NDISConditionsList;
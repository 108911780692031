import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Poor Posture</h1>
                    <h2>Correcting Poor Posture for a Healthier, Pain-Free Life</h2>
                    <p>At Ramp Physio and Fitness, we understand that poor posture can lead to a variety of health issues, including chronic back pain, neck strain, and headaches. Whether you’re spending long hours at a desk or engaging in activities that strain your posture, our expert physiotherapists are here to help. We offer comprehensive assessments to identify the root causes of your poor posture and create a personalized treatment plan to correct it, improving your overall health and well-being.</p>
                    <p>Struggling with the effects of poor posture? Contact us today to schedule a posture assessment and start your journey to better health with our expert physiotherapy services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Identifying and Addressing Problems Caused by Poor Posture</h1>
                        <p>Poor posture can contribute to a wide range of issues, including muscle imbalances, joint pain, and even digestive problems. Symptoms may include chronic discomfort, reduced mobility, and fatigue. At Ramp Physio and Fitness, we help you understand how poor posture affects your body and provide targeted treatments to address these problems, ensuring long-term relief and improved function.</p>
                        <p>Experiencing pain or discomfort from poor posture? Let our team help you address the root causes. Reach out to us for a consultation and learn how we can improve your posture and overall health.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Programs for Effective Posture Correction</h1>
                    <p>Correcting poor posture requires a tailored approach that considers your specific needs and lifestyle. At Ramp Physio and Fitness, we offer customized physiotherapy programs that include manual therapy, exercises to strengthen postural muscles, and ergonomic advice to support healthy habits. Our goal is to help you achieve and maintain proper posture, reducing pain and enhancing your quality of life.</p>
                    <p>Ready to improve your posture and reduce pain? Contact us to create a personalized physiotherapy plan that fits your needs and helps you maintain a healthier, pain-free posture.</p>

                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Achieving Better Posture with Our Physiotherapy</h1>
                    <p>Our clients have experienced significant improvements in their posture and overall well-being through our physiotherapy programs. From alleviating chronic pain to enhancing daily comfort and mobility, our clients’ success stories highlight the positive impact of correcting poor posture. Read their testimonials to see how Ramp Physio and Fitness can help you achieve similar results.</p>
                    <p>Inspired by our clients’ success with posture correction? Start your own journey to better posture and a healthier life. Contact us today to book your appointment and take the first step toward lasting improvement.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheImpactOfTechnologyOnModernFitness = () => {
    return (
        <div>
            <Helmet>
                <title>The Impact of Technology on Modern Fitness | Ramp Physio and Fitness</title>
                <meta name="description" content="Explore how technology is reshaping the fitness landscape with innovative solutions that enhance training, recovery, and overall wellness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Shaping the Future of Fitness</h1>
                    <h2>Uncover the profound effects of technological advancements on modern fitness and well-being, and how Ramp Physio and Fitness is at the forefront of this evolution.</h2>
                    <p>Technology has revolutionized many aspects of our lives, and the fitness industry is no exception. From wearable devices to mobile apps, and smart gym equipment, the digital age has introduced a myriad of tools that are transforming how we approach health and exercise. In this comprehensive guide, we will explore the impact of technology on modern fitness and how it's paving the way for more personalized, efficient, and engaging workout experiences at Ramp Physio and Fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Modern Fitness Technology" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Wearable Tech: A Game Changer</h1>
                        <p>Wearable technology has become one of the most popular trends in the fitness industry. Devices like fitness trackers, smartwatches, and heart rate monitors provide real-time data on our physical activities, allowing for a more analytical approach to exercise. These gadgets track steps, monitor sleep patterns, gauge workout intensity, and even offer workout suggestions, making them indispensable companions for fitness enthusiasts.</p>
                        <ul>
                            <li>Real-time tracking of physical activity and health metrics</li>
                            <li>Personalized workout recommendations</li>
                            <li>Integration with smartphones and other devices</li>
                            <li>Enhanced motivation through goal setting and progress tracking</li>
                            <li>Improved accuracy in calorie burn and heart rate monitoring</li>
                            <li>Convenient access to health data for users and healthcare providers</li>
                            <li>Interactive challenges and social features to keep users engaged</li>
                            <li>Wearable tech's role in rehabilitation and physiotherapy</li>
                            <li>Advanced features like GPS tracking and altitude measurement</li>
                            <li>Impact on sports performance and coaching strategies</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Mobile Fitness Apps</h1>
                    <p>With the rise of smartphones, fitness apps have become a crucial part of many people's exercise routines. These apps offer a variety of features, from personalized training plans to nutrition tracking and virtual coaching. They also provide a platform for users to set goals, track progress, and stay motivated through community support and gamification. The convenience and customization offered by fitness apps make them a vital tool for anyone looking to improve their health and fitness.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        The Digital Transformation of Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Discover More
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Smart Equipment and IoT</h1>
                    <p>Smart gym equipment and the Internet of Things (IoT) have introduced a new level of interactivity and personalization to workouts. Machines like smart treadmills, bikes, and weightlifting equipment can adjust to the user's fitness level, offer feedback on form, and sync with other devices to provide a comprehensive view of one's fitness journey. These advancements not only enhance the exercise experience but also offer valuable data that can be used to optimize performance and prevent injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Virtual Reality and Gamification</h1>
                    <p>Virtual reality (VR) and gamification are taking the fitness world by storm, offering immersive workout experiences that combine fun and exercise. VR fitness programs transport users to virtual environments where they can engage in activities ranging from boxing to dancing, all while getting a full-body workout. Gamification adds an element of competition and achievement to fitness, making it more appealing and motivating for users to stick to their exercise routines.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheImpactOfTechnologyOnModernFitness;
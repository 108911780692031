import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Plaster Casting and Cast Removal</h1>
                    <h2>Expert Plaster Casting and Cast Removal Services for Quick Recovery</h2>
                    <p>At Ramp Physio and Fitness, we understand that fractures and injuries requiring casts can be stressful and disruptive to your daily life. That's why we offer expert plaster casting and cast removal services to help you recover quickly and efficiently. Our experienced physiotherapists provide same-day, immediate appointments for clients in need of a cast or cast removal, ensuring you receive prompt care when you need it most.</p>
                    <p>Need a cast or ready to have yours removed? Don't wait any longer. Contact us today to book your appointment and take the first step toward a swift recovery with our professional physiotherapy services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Uncovering the Importance of Proper Plaster Casting and Cast Removal</h1>
                        <p>Proper plaster casting is crucial for the healing of fractures and injuries, as it immobilizes the affected area to allow optimal recovery. Similarly, safe and professional cast removal is essential to prevent any further injury or complications. At Ramp Physio and Fitness, our physiotherapists are trained in the latest casting techniques and use specialized equipment for safe cast removal. We ensure that your healing process is supported every step of the way.</p>
                        <p>Unsure about your casting needs? Let our experts guide you through the process. Schedule an appointment today and receive personalized care tailored to your injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Care for Your Specific Injury</h1>
                    <p>No two injuries are exactly the same, which is why we offer customized plaster casting and cast removal services based on your specific needs. Whether it's a simple fracture or a more complex injury, our physiotherapists will assess your condition and recommend the most appropriate casting solution. We also provide rehabilitation exercises and physiotherapy treatments to aid in your recovery once the cast is removed.</p>
                    <p>Ready to get back to your normal activities? Contact us to create a personalized recovery plan that helps you regain strength and mobility.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Your Partner in Recovery and Rehabilitation</h1>
                    <p>Our clients' success stories demonstrate the effectiveness of our plaster casting and rehabilitation programs. From quick healing times to full restoration of function, we are proud to have helped numerous individuals return to their daily activities. Read their testimonials and discover how Ramp Physio and Fitness can assist you in achieving a successful recovery.</p>
                    <p>Inspired by our clients' progress? Join them by choosing our expert physiotherapy services for your casting and rehabilitation needs. Contact us today to book your appointment.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

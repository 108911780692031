import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingforspecificsportstailoredgymprograms = () => {
    return (
        <div>
            <Helmet>
                <title>Sports Specific Training Programs | Ramp Physio and Fitness</title>
                <meta name="description" content="Tailored gym programs for athletes in Homebush, NSW. Achieve peak performance in your specific sport with our custom training strategies." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Training for Specific Sports</h1>
                    <h2>Unlock your athletic potential with tailored gym programs designed for the demands of your sport. At Ramp Physio and Fitness, we develop customized training strategies that propel you towards your peak performance.</h2>
                    <p>Whether you're a competitive athlete or a fitness enthusiast looking to excel in a particular sport, a one-size-fits-all approach to training doesn't cut it. That's where our specialized sports-specific training programs come into play. Combining expert knowledge with state-of-the-art facilities, we create training regimens that are as unique as the sports you play and the goals you aim to achieve.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Sports Specific Training" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Customized Athletic Development</h1>
                        <p>Our approach to sports-specific training is grounded in a deep understanding of the physiological demands of different sports. We analyze the movements, skills, and energy systems used in your sport to create a program that enhances your strengths and addresses your weaknesses. The result is a training plan that not only maximizes your athletic performance but also reduces the risk of injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Elements of a Tailored Program</h1>
                    <ul>
                        <li>Assessment of sport-specific demands</li>
                        <li>Identification of individual athlete needs</li>
                        <li>Strength and conditioning components</li>
                        <li>Speed, agility, and quickness drills</li>
                        <li>Energy system development (aerobic/anaerobic)</li>
                        <li>Technical and tactical skill refinement</li>
                        <li>Recovery and injury prevention strategies</li>
                        <li>Periodization and training cycles</li>
                        <li>Nutritional guidance for performance</li>
                        <li>Mental toughness and psychological preparation</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Sports Science</h1>
                    <p>Our training programs are not just about hard work; they're about smart work. We integrate the latest sports science research to ensure that every aspect of your training is optimized. From biomechanics to exercise physiology, we use evidence-based practices to help you train more effectively and safely.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Expert Coaches and Trainers</h1>
                        <p>Our team of expert coaches and trainers brings a wealth of experience and knowledge to your training. With certifications and backgrounds in sports performance, they have the skills to guide you through every step of your sports-specific training journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingforspecificsportstailoredgymprograms;
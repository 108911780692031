import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import womensHealthLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Physiotherapytreatmentandexercisesforwomenshealthphysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Women's Health Physiotherapy Treatment and Exercises</title>
                <meta name="description" content="Expert women's health physiotherapy services tailored to your unique needs. Regain strength and confidence with our personalized treatment and exercise programs at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Women Through Physiotherapy</h1>
                    <h2>Discover the specialized physiotherapy treatments and exercises designed for women's unique health needs at Ramp Physio and Fitness.</h2>
                    <p>Women's health physiotherapy is a specialized field dedicated to addressing the unique conditions and challenges that women may face throughout their lives. At Ramp Physio and Fitness, we provide expert physiotherapy services tailored to the specific health concerns of women. Our approach includes comprehensive treatment plans, one-on-one care, and targeted exercises that aim to enhance your well-being and empower you to take control of your health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Women's Health Physiotherapy</h1>
                        <p>Women's health physiotherapy encompasses a variety of treatments and exercises aimed at improving conditions such as pelvic floor dysfunction, prenatal and postpartum issues, osteoporosis, and breast cancer rehabilitation. Our physiotherapists are trained to assess and manage these conditions with care and sensitivity, ensuring that you receive the most effective treatment possible.</p>
                        <p>Our treatments may include manual therapy, pelvic floor exercises, core stability training, and education on proper body mechanics. These are designed to alleviate symptoms, improve function, and enhance quality of life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Pelvic Health and Rehabilitation</h1>
                        <p>Pelvic health is a critical aspect of women's health physiotherapy. Our team offers specialized treatment for pelvic floor disorders, incontinence, prolapse, and pain. We use a combination of hands-on techniques and exercises to strengthen the pelvic floor muscles and improve bladder and bowel control.</p>
                        <p>Treatments are customized to your individual needs, ensuring that you receive the most appropriate care for your situation. Our goal is to help you regain confidence in your daily activities and improve your overall pelvic health.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={womensHealthLogo} alt="Women's Health Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Pre and Postnatal Care</h1>
                    <p>Pregnancy and childbirth can have a significant impact on a woman's body. We offer specialized pre and postnatal physiotherapy to help manage common conditions such as back pain, diastasis recti, and pelvic girdle pain. Our exercises and treatments are designed to support your body through the changes of pregnancy and aid in recovery after childbirth.</p>
                    <p>We focus on strengthening the core and pelvic floor muscles, improving posture, and providing ergonomic advice to make pregnancy and motherhood more comfortable and enjoyable.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Through Education</h1>
                        <p>Education is a powerful tool in women's health physiotherapy. We provide comprehensive education on topics such as pelvic floor awareness, proper lifting techniques, and strategies to manage symptoms at home. Our aim is to empower women with the knowledge they need to make informed decisions about their health and well-being.</p>
                        <p>We believe that by understanding your body better, you can take proactive steps to prevent issues and maintain your health throughout all stages of life.</p>
                        <br></br>
                        <p>
                            At Ramp Physio and Fitness, we are committed to offering the highest standard of care in women's health physiotherapy. Our dedicated professionals work closely with you to develop a personalized treatment plan that addresses your specific needs and goals. Whether you're dealing with a specific health condition or looking to improve your overall fitness, we are here to support you every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapytreatmentandexercisesforwomenshealthphysiotherapy;
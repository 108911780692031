import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const BenefitsOfPersonalTrainingSessions = () => {
    return (
        <div>
            <Helmet>
                <title>Preventing Common Gym Injuries | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to prevent common gym injuries with expert advice from Ramp Physio and Fitness. Stay safe and achieve your fitness goals." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Preventing Common Gym Injuries</h1>
                    <h2>Stay Safe and Maximize Your Workout with Ramp Physio and Fitness</h2>
                    <p>At Ramp Physio and Fitness, your safety is our priority. Our team of professionals is dedicated to helping you prevent common gym injuries so you can achieve your health and fitness goals without setbacks. In this comprehensive guide, we'll share essential tips and strategies to keep you injury-free on your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book a Consultation
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Gym Injury Risks</h1>
                        <p>Before diving into prevention, it's important to understand the risks associated with gym workouts. Overexertion, improper form, and inadequate warm-ups can lead to a range of injuries, from strains and sprains to more serious conditions. Recognizing these risks is the first step in preventing them.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Essential Warm-Up Routines</h1>
                    <p>Warming up is crucial to prepare your muscles and joints for the stress of exercise. Dynamic stretches and light cardio can increase blood flow and flexibility, reducing the risk of injury. Here are 10 warm-up exercises to include in your routine:</p>
                    <ul>
                        <li>Jogging in place or on a treadmill</li>
                        <li>Jumping jacks</li>
                        <li>Arm circles</li>
                        <li>Leg swings</li>
                        <li>Bodyweight squats</li>
                        <li>Lunges</li>
                        <li>High knees</li>
                        <li>Butt kicks</li>
                        <li>Dynamic stretching</li>
                        <li>Foam rolling target areas</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book a Consultation
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Proper Technique and Form</h1>
                    <p>Maintaining proper technique and form is essential for injury prevention. It ensures that the targeted muscles are engaged correctly and reduces the strain on joints and ligaments. At Ramp Physio and Fitness, our personal trainers and physiotherapists can guide you through the correct form for a wide range of exercises.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Recovery and Rest Periods</h1>
                    <p>Rest and recovery are as important as the workout itself. Allowing your body to recover prevents overtraining and reduces the risk of injury. Incorporate rest days into your workout schedule, and consider active recovery activities such as individually tailored and physiotherapy designed and monitored flexibility and core stability programs or swimming to aid in muscle repair and growth.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Gradual Progression in Workouts</h1>
                    <p>Increasing workout intensity or weight too quickly can lead to injuries. It's important to progress gradually, giving your body time to adapt. Our fitness experts can help you design a progressive workout plan that safely challenges your limits while minimizing injury risks.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container6">
                    <h1>Equipment Use and Safety</h1>
                    <p>Using gym equipment incorrectly can be a major cause of injury. Always read instructions carefully, adjust settings to fit your body, and if in doubt, ask a staff member for assistance. Our team is always ready to help you use equipment safely and effectively.</p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container7">
                <div className="service-intro-content7">
                    <h1>Staying Hydrated and Nourished</h1>
                    <p>Dehydration and inadequate nutrition can impair your performance and increase the likelihood of injury. Ensure you're drinking plenty of water and consuming a balanced diet to support your workout regimen.</p>
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default BenefitsOfPersonalTrainingSessions;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Your Ultimate Gym Near Me for Fitness and Wellness</h1>
                    <h2>Experience top-tier fitness training, personalized workout plans, and supportive community engagement at Ramp Physio and Fitness, the best gym near you dedicated to your health and wellness.</h2>
                    <p>When striving to improve your fitness, boost overall health, or recover from an injury, finding the perfect gym near you is essential. At Ramp Physio and Fitness, we specialize in offering personalized fitness services tailored to meet your unique needs. Our expert team is committed to guiding you toward your health goals, whether you're interested in intense training sessions, rehabilitation, or simply maintaining an active lifestyle. With a range of services from flexible hours to comprehensive fitness programs, we’re here to support your journey to a healthier you.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Customized Workout Plans</h1>
                        <p>Choosing the right gym that truly understands your fitness aspirations can be challenging. At Ramp Physio and Fitness, we prioritize a customized approach to your fitness. Our trainers are equipped with the latest knowledge and techniques to create workout plans specifically designed for your goals, whether it’s weight loss, muscle gain, or overall wellness. We believe in providing individualized attention, ensuring that every exercise you perform contributes effectively toward your fitness objectives. Here, you are more than just a gym member; you're a part of a community focused on results.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Convenient After-Hours Gym Access</h1>
                    <p>We recognize that life’s demands don’t always fit within regular gym hours. That’s why our gym offers after-hours access, allowing you to work out at a time that best suits your schedule. Whether you prefer early morning sessions or late-night workouts, our flexible hours make it possible to stay committed to your fitness journey without compromising other aspects of your life. At Ramp Physio and Fitness, we’re dedicated to providing you with the convenience and flexibility you need to stay on track with your fitness goals.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Specialized Support for Injury Recovery</h1>
                    <p>If you’re dealing with a sports injury or chronic pain, our gym provides specialized support to help you recover and regain your strength. Our team of experts combines physiotherapy techniques with targeted exercise plans to ensure a safe and effective recovery process. We work closely with you to address your specific needs, helping you get back to your favorite activities as quickly and safely as possible. At Ramp Physio and Fitness, we understand the athlete’s mindset and are committed to helping you overcome any obstacles on your path to recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Engaging Personal Training Sessions</h1>
                    <p>Our fitness journeys are more enjoyable and successful due to our Ramp Fitness programs with physiotherapy support and monitoring to ensure you get the best possible results to match you needs. Our gym offers tailored personalised training sessions that help you achieve your fitness goals. Are structured training programs are designed to improve strength, flexibility, and endurance in a motivating group setting. Our experienced trainers lead each session, ensuring that you perform exercises safely and effectively while enjoying the support and encouragement of your peers. Whether you’re new to fitness or a seasoned athlete, our group training sessions offer a fun and dynamic way to stay fit.</p>
                    <br></br>
                    <p>
                    Ramp Physio and Fitness is more than just a gym near you; it’s a community dedicated to your health and well-being. Whether you’re seeking personalized workout plans, convenient after-hours access, or the camaraderie of our welcoming environment, our gym is here to help you every step of the way. If you’ve been searching for the perfect gym, look no further. Join us today and embark on your journey to a healthier, fitter lifestyle.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForLowerBackPain = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Lower Back Pain - Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy treatment and exercises for lower back pain relief at Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Physiotherapy for Lower Back Pain</h1>
                    <h2>Explore specialized physiotherapy treatments and exercises designed to relieve lower back pain and improve mobility at Ramp Physio and Fitness.</h2>
                    <p>Lower back pain is a common ailment affecting a wide range of individuals. At Ramp Physio and Fitness, we offer professional physiotherapy treatments and tailored exercises to help you overcome discomfort and regain your active lifestyle. Our expert team in Homebush, NSW, is dedicated to providing the highest quality care for back pain relief. Let's delve into the effective strategies we employ to manage lower back pain.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Lower Back Pain</h1>
                        <p>Lower back pain can stem from various sources, including muscle strains, ligament sprains, intervertebral disc degeneration, and more. Recognizing the root cause of the pain is crucial for effective treatment. Our physiotherapists at Ramp Physio and Fitness conduct thorough assessments to pinpoint the specific factors contributing to your discomfort.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Tailored Treatment Plans</h1>
                        <p>Each case of lower back pain is unique, necessitating a personalized treatment plan. Our approach may include a combination of manual therapy, such as spinal mobilization, and specific exercises aimed at strengthening and stabilizing the lower back. We prioritize treatments that are supported by the latest research and clinical evidence.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Key Exercises for Lower Back Pain</h1>
                    <p>Exercise is a cornerstone of lower back pain treatment. It not only aids in pain relief but also prevents future episodes. Here are ten exercises recommended by our physiotherapists:</p>
                    <p>• Pelvic Tilts</p>
                    <p>• Cat-Cow Stretch</p>
                    <p>• Child's Pose</p>
                    <p>• Knee-to-Chest Stretch</p>
                    <p>• Bridges</p>
                    <p>• Lower Back Rotational Stretches</p>
                    <p>• Lying Lateral Leg Lifts</p>
                    <p>• Bird Dog</p>
                    <p>• Partial Crunches</p>
                    <p>• Wall Sits</p>
                    <p>These exercises target core stability, flexibility, and strength, which are vital for supporting the lower back.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Progressive Rehabilitation</h1>
                    <p>Successful lower back pain management is a gradual process. Our physiotherapists guide you through progressive rehabilitation, carefully advancing your exercises to ensure continuous improvement without exacerbating symptoms.</p>
                    <br></br>
                    <p>In conclusion, Ramp Physio and Fitness is committed to delivering exceptional lower back pain treatment through specialized physiotherapy and exercise regimens. Our expert team is ready to support you in achieving pain relief and restoring your quality of life. Book your appointment today and take the first step towards a healthier, more active you.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForLowerBackPain;
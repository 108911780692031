import React, { useState, useCallback } from 'react';
import WordCloud from 'react-d3-cloud';
import TextProcessor from './TextProcessor';

const D3WordCloud = () => {
  const [words, setWords] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);

  const MIN_FONT_SIZE = 24; 
  const MAX_FONT_SIZE = 120; 

  const MIN_FONT_WEIGHT = 400;
  const MAX_FONT_WEIGHT = 900;


  const fontSize = useCallback((word) => {
    const maxValue = Math.max(...words.map(w => w.value));
    const minValue = Math.min(...words.map(w => w.value));
    const normalizedValue = (word.value - minValue) / (maxValue - minValue);
    return MIN_FONT_SIZE + normalizedValue * (MAX_FONT_SIZE - MIN_FONT_SIZE);
  }, [words]);

  const fontWeight = useCallback((word) => {
    const maxValue = Math.max(...words.map(w => w.value));
    const minValue = Math.min(...words.map(w => w.value));
    const normalizedValue = (word.value - minValue) / (maxValue - minValue);
    return Math.round(MIN_FONT_WEIGHT + normalizedValue * (MAX_FONT_WEIGHT - MIN_FONT_WEIGHT));
  }, [words]);

  const onWordClick = useCallback((event, word) => {
    setSelectedWord(prevWord => word === prevWord ? null : word);
    if (event.target) {
      event.target.style.fontWeight = word === selectedWord ? 'normal' : 'bold';
    }
    console.log(`Selected word: ${word}`);
  }, [selectedWord]);

  const onWordMouseOver = useCallback((event, word) => {
    document.body.style.cursor = 'pointer';
    if (event.target) {
      const currentSize = event.target.style.fontSize;
      const currentFontSize = parseInt(currentSize);
      
      event.target.style.transition = 'all 0.3s ease';
      event.target.style.opacity = '0.7';
      event.target.style.fontSize = `${currentFontSize + 2}px`;
      event.target.style.fontWeight = 'bold';
    }
  }, []);

  const onWordMouseOut = useCallback((event, word) => {
    document.body.style.cursor = 'default';
    if (event.target) {
      const originalFontSize = fontSize({ text: word, value: word.value });
      
      event.target.style.transition = 'all 0.3s ease';
      event.target.style.opacity = '1';
      event.target.style.fontSize = `${originalFontSize}px`;
      event.target.style.fontWeight = 'normal';
    }
  }, [fontSize]);

  return (
    <div className="w-full bg-white rounded-lg shadow-lg p-6">
      <TextProcessor onWordsProcessed={setWords} />
      <div className="mb-4">
        {selectedWord && (
          <div className="text-lg text-blue-600 mb-2">
            Selected: {selectedWord}
          </div>
        )}
      </div>

      <div className="relative w-full h-[1000px] bg-gray-50 rounded-lg overflow-hidden">
        <WordCloud
          data={words}
          width={1800}
          height={1000}
          font="Poppins"
          fontWeight={fontWeight}
          fontSize={fontSize}
          rotate={0}
          padding={1}
          random={() => 0.5}
          onWordClick={(event, d) => onWordClick(event, d.text)}
          onWordMouseOver={onWordMouseOver}
          onWordMouseOut={onWordMouseOut}
        />
      </div>
    </div>
  );
};

export default D3WordCloud; 
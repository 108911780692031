import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingPlantarWartsWithPhysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Managing Plantar Warts with Physiotherapy | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective physiotherapy strategies for managing plantar warts at Ramp Physio and Fitness. Our expert team helps you achieve pain relief and recovery." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Combat Plantar Warts with Expert Care</h1>
                    <h2>Explore the role of physiotherapy in treating plantar warts and the comprehensive services offered by Ramp Physio and Fitness for optimal foot health.</h2>
                    <p>Plantar warts, those stubborn, often painful growths on the soles of feet, can be a source of discomfort and irritation. At Ramp Physio and Fitness, we understand the challenges they pose and offer specialized physiotherapy treatments to manage and alleviate plantar warts. Leveraging the latest research and techniques, our physiotherapists provide personalized care plans to restore your foot health and comfort.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Plantar Warts</h1>
                        <p>Plantar warts are caused by the human papillomavirus (HPV) and occur on the sole or toes of the foot. They can be recognized by their grainy, fleshy appearance, often with a small black dot at the center. While many warts resolve on their own, some persist, grow, and become painful, especially when walking. Physiotherapy offers non-invasive options to manage these warts, complementing or sometimes providing an alternative to medical treatments.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Physiotherapy Approaches</h1>
                    <p>Our physiotherapists at Ramp Physio and Fitness employ a tailored approach to treat plantar warts. After a thorough assessment, we devise a treatment plan that may include cryotherapy, salicylic acid application, or other non-invasive techniques. These methods aim to remove the wart tissue while encouraging healthy skin regeneration.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advancements in Wart Therapy</h1>
                    <p>Recent advancements in physiotherapy for plantar warts include innovative treatments like laser therapy and immune-therapy. These methods focus on eradicating the virus locally and stimulating the immune system to prevent recurrence. At Ramp Physio and Fitness, we stay abreast of these developments to offer our clients effective treatment options.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Preventive Care and Education</h1>
                        <p>Prevention is a key component of managing plantar warts. Our team provides education on preventive strategies, such as proper footwear, foot hygiene, and avoiding direct contact with warts. We also offer advice on strengthening the immune system to reduce the likelihood of wart formation and recurrence.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingPlantarWartsWithPhysiotherapy;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingAndUtilizingGymEquipmentSafely = () => {
    return (
        <div>
            <Helmet>
                <title>Safe Gym Equipment Use | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to utilize gym equipment safely and effectively at Ramp Physio and Fitness. Our guide ensures you achieve your fitness goals with reduced injury risk." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Understanding Gym Equipment Safety</h1>
                    <h2>Master the use of gym equipment with our expert guidance at Ramp Physio and Fitness, ensuring a safe and effective workout experience.</h2>
                    <p>Embarking on a fitness journey requires not just determination and discipline, but also a fundamental understanding of gym equipment and its safe utilization. At Ramp Physio and Fitness, our mission is to empower you with the knowledge and confidence to use gym apparatus effectively, minimizing the risk of injury and maximizing your workout benefits. This comprehensive guide will walk you through the essentials of gym equipment safety and how to incorporate these practices into your fitness routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Equipment" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Equipment Introduction and Setup</h1>
                        <p>Before starting any exercise, it's crucial to familiarize yourself with the different types of gym equipment available and understand their specific functions. From resistance machines to cardio equipment, each apparatus is designed to target various muscle groups and fitness aspects. Proper setup is equally important—adjusting the machine to fit your body size and strength levels can significantly enhance your workout efficiency and safety.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Free Weights and Proper Technique</h1>
                    <p>Free weights, such as dumbbells and barbells, are excellent tools for building strength and muscle. However, they require proper handling and technique to avoid injury. Always start with lighter weights to master the movement before progressing. Ensure your form is correct, and do not hesitate to ask our trainers for a demonstration or spotter assistance when lifting heavier weights.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Cardio Equipment Fundamentals</h1>
                    <p>Cardiovascular equipment like treadmills, ellipticals, and stationary bikes are staples in most fitness routines. To use them safely, begin with a moderate pace to warm up, gradually increasing intensity. Pay attention to your posture and balance, and use the safety stop features when necessary. Remember to clean the equipment after use to maintain hygiene and prevent the spread of germs.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Machine Weight Training Tips</h1>
                    <p>Machine weights are excellent for beginners due to their structured movement patterns. To ensure safety and effectiveness, adjust the seat and weight settings to match your physique and strength level. Perform movements smoothly without jerking, and breathe consistently throughout the exercise. Never sacrifice form for heavier weights, as this can lead to strain and injury.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Hygiene and Maintenance Awareness</h1>
                    <ul>
                        <li>Wipe down equipment before and after use</li>
                        <li>Report any equipment malfunctions immediately</li>
                        <li>Use clean towels and gym attire</li>
                        <li>Avoid using damaged or worn-out equipment</li>
                        <li>Ensure proper footwear for stability and support</li>
                        <li>Maintain personal space and be considerate of others</li>
                        <li>Store weights and accessories in their designated areas</li>
                        <li>Follow the gym's safety guidelines and protocols</li>
                        <li>Stay hydrated and take breaks when needed</li>
                        <li>Listen to your body and avoid overexertion</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingAndUtilizingGymEquipmentSafely;
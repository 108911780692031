import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForCerebralPalsy = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Cerebral Palsy | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for cerebral palsy - personalized treatment plans to improve mobility, strength, and independence. Book your session today!" />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Movement with Cerebral Palsy Physiotherapy</h1>
                    <h2>Unlock potential and foster independence through specialized physiotherapy for cerebral palsy at Ramp Physio and Fitness, tailored to meet individual needs and enhance quality of life.</h2>
                    <p>Cerebral palsy presents unique challenges, but at Ramp Physio and Fitness, we believe in empowering each individual to reach their fullest potential. Our expert physiotherapists use evidence-based treatments to improve mobility, strength, and coordination. This article will explore the transformative impact of physiotherapy for cerebral palsy and how our dedicated team can help achieve remarkable results.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Individualized Treatment Plans</h1>
                        <p>Every case of cerebral palsy is unique, and so are our treatment plans. Our physiotherapists collaborate with clients and their families to create personalized programs that address specific goals and challenges. From improving gait patterns to enhancing daily function, our comprehensive strategies are designed to promote independence and improve quality of life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Therapeutic Techniques</h1>
                    <p>Our physiotherapy approach incorporates advanced techniques such as neurodevelopmental treatment (NDT), strength training, and motor learning strategies. These methods are crucial for enhancing the functional abilities of individuals with cerebral palsy. We also integrate assistive technology and adaptive equipment to further facilitate progress.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Supporting Developmental Milestones</h1>
                    <p>Our physiotherapy sessions are aimed at supporting children with cerebral palsy to reach developmental milestones. Whether it's sitting, crawling, standing, or walking, we provide the necessary interventions to encourage each child's growth and development.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Family-Centered Care Approach</h1>
                    <p>At Ramp Physio and Fitness, we emphasize the importance of family involvement in the treatment process. Educating families about cerebral palsy and providing them with tools and strategies to support their loved ones at home is integral to our approach. Together, we create a nurturing environment that fosters learning and growth.</p>
                    <br></br>
                    <p>
                        In conclusion, our commitment to providing exceptional physiotherapy for cerebral palsy is unwavering. We understand the complexities of the condition and are dedicated to delivering care that makes a real difference in the lives of our clients. Join us at Ramp Physio and Fitness, where we strive to transform challenges into opportunities for empowerment and progress.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForCerebralPalsy;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForTennisElbow = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Physiotherapy for Tennis Elbow</h1>
                    <h2>Alleviate pain and regain strength with our specialized tennis elbow treatments at Ramp Physio and Fitness.</h2>
                    <p>Tennis elbow, or lateral epicondylitis, is a common condition causing pain and discomfort around the outside of the elbow, often due to repetitive motions of the wrist and arm. At Ramp Physio and Fitness, we provide expert physiotherapy services tailored to treat and manage tennis elbow, helping you return to your favorite activities with confidence. This article will guide you through our approach to treating tennis elbow and how our physiotherapy services can benefit you.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Tennis Elbow</h1>
                        <p>Tennis elbow affects a wide range of individuals, not just athletes. It's characterized by pain and tenderness on the lateral side of the elbow, caused by overuse of the forearm muscles. Understanding the condition is the first step towards effective treatment. Our physiotherapists at Ramp Physio and Fitness are well-versed in the anatomy and biomechanics of tennis elbow, ensuring accurate diagnosis and effective treatment plans.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each case of tennis elbow is unique, and so should be the treatment plan. Our personalized approach involves a thorough assessment of your condition, taking into account your level of activity, goals, and lifestyle. We design individualized treatment plans that may include a combination of manual therapy, exercises, and modalities to relieve pain and restore function.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for Recovery</h1>
                    <ul>
                        <li>Wrist extensor stretch</li>
                        <li>Wrist flexor stretch</li>
                        <li>Eccentric wrist extensor strengthening</li>
                        <li>Forearm pronation/supination</li>
                        <li>Elbow flexion and extension</li>
                        <li>Grip strengthening</li>
                        <li>Theraband exercises for forearm muscles</li>
                        <li>Isometric exercises for wrist extensors</li>
                        <li>Dynamic wrist and elbow movements</li>
                        <li>Functional exercises tailored to your activities</li>
                    </ul>
                    <p>Our physiotherapists will guide you through exercises specifically designed to strengthen the muscles around the elbow and forearm, improve flexibility, and reduce the risk of re-injury. We incorporate exercises from reliable sources, ensuring that you are receiving evidence-based care.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Modalities and Techniques</h1>
                    <p>In addition to exercises, we utilize advanced treatment modalities such as ultrasound, shockwave therapy, and laser therapy to promote healing and reduce pain. Manual techniques like soft tissue mobilization, joint mobilization, and trigger point therapy are also used to address specific issues contributing to tennis elbow symptoms.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForTennisElbow;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';


const Managingsciatica = () => {
    return (
        <div>
            <Helmet>
                <title>Managing Sciatica with Physiotherapy Exercises | Ramp Physio and Fitness</title>
                <meta
                    name="description"
                    content="Learn how to manage sciatica pain with targeted physiotherapy exercises. Join Ramp Physio and Fitness for expert guidance and relief."
                />
                {/* Google tag (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-P52MNG23ES"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-P52MNG23ES');
                    `}
                </script>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Managing Sciatica with Physiotherapy</h1>
                    <h2>Targeted Exercises for Sciatic Pain Relief</h2>
                    <p>
                        At <strong>Ramp Physio and Fitness</strong>, our experienced team offers expert guidance on managing sciatica through physiotherapy exercises. Sciatica, characterized by pain radiating along the sciatic nerve pathway, can be debilitating. However, with the right exercises tailored to your condition, you can alleviate pain and regain mobility. Here's how we can help you take control of your sciatica with physiotherapy.
                    </p>
                    <p>
                        Suffering from sciatica pain? <a href="/contact">Contact us</a> to schedule an appointment and start your journey to relief.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Sciatica Pain and Its Impact</h1>
                        <p>
                            Sciatica can cause sharp pain, numbness, or tingling in the lower back, buttocks, and down the legs. It's often triggered by a herniated disc, bone spur, or narrowing of the spine compressing the nerve. Understanding sciatica's impact on your life is the first step toward effective management.
                        </p>
                        <p>
                            Not sure if your pain is sciatica? Our experts can assess your symptoms. <a href="/appointments">Schedule an appointment</a> for a professional evaluation.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Stretching Exercise 1ß" />
                    </div>
                </div>

            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Stretching Exercise 2" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercises to Relieve Sciatic Pain</h1>
                    <p>
                        Physiotherapy exercises can be incredibly effective for sciatica. They not only aim to relieve immediate pain but also strengthen muscles and improve flexibility to prevent future episodes. Here are 10 exercises we may recommend:
                    </p>
                    <ul>
                        <li>Reclining pigeon pose</li>
                        <li>Sitting spinal stretch</li>
                        <li>Knee to opposite shoulder</li>
                        <li>Sciatic nerve mobilization</li>
                        <li>Standing hamstring stretch</li>
                        <li>Forward pigeon pose</li>
                        <li>Knee to chest stretch</li>
                        <li>Seated hamstring stretch</li>
                        <li>Gluteal and piriformis activation</li>
                        <li>Core strengthening exercises</li>
                    </ul>
                    <p>
                        Ready to learn these exercises under professional guidance? <a href="/contact">Contact us</a> to book a session with our physiotherapists.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a
                        href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Sciatica Success Stories</h1>
                    <p>
                        Our clients have found relief from sciatic pain through our customized exercise programs. Hear their stories and discover how we can make a difference in your life.
                    </p>
                    <br />
                    <h1>Personalized Sciatica Treatments</h1>
                    <p>
                        We understand that each case of sciatica is unique. That's why we offer personalized treatment plans that include a combination of exercises, manual therapy, and education to manage your specific symptoms effectively.
                    </p>
                    <br />
                    <h1>Who Benefits from Sciatica Management?</h1>
                    <p>
                        Anyone suffering from sciatica can benefit from our targeted physiotherapy exercises. Whether you're dealing with acute pain or chronic discomfort, our team is equipped to help you achieve relief and improve your quality of life.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Stretching Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Stretching Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Physiotherapy Session Expectations</h1>
                        <p>
                            In your sessions, you'll experience a caring environment focused on your comfort and recovery. Our physiotherapists will guide you through exercises, provide manual therapy, and offer advice to support your journey to wellness.
                        </p>
                        <p>
                            Have questions about our approach? <a href="/contact">Contact us</a> for more information and to set up an appointment.
                        </p>
                        <br />
                        <h1>Integrative Therapy for Sciatica</h1>
                        <p>
                            By combining different physiotherapy methods, we aim to provide you with the most effective treatment for sciatica. Our holistic approach addresses both symptoms and root causes to ensure long-term relief.
                        </p>
                        <p>
                            Eager to start your integrated therapy? <a href="/contact">Contact us</a> to create a treatment plan customized for you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Managingsciatica;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterRotatorCuffSurgery = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Rehabilitation After Rotator Cuff Surgery</h1>
                    <h2>Embark on a tailored recovery journey with Ramp Physio and Fitness, utilizing evidence-based protocols to ensure optimal healing and restoration of shoulder function after rotator cuff surgery.</h2>
                    <p>Rehabilitation following rotator cuff surgery is a critical phase in your recovery, demanding expert guidance and a personalized approach. At Ramp Physio and Fitness, we specialize in providing structured rehabilitation programs that align with the latest clinical research and protocols. Our aim is to support your healing process, maximize functional recovery, and get you back to your active lifestyle safely and effectively. In this comprehensive guide, we'll explore the stages of rehabilitation and the specific strategies we employ to foster a successful recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Initial Post-Surgery Care</h1>
                        <p>The immediate post-operative period is crucial for ensuring a stable healing environment for your rotator cuff. At Ramp Physio and Fitness, we adhere to strict guidelines that typically involve immobilization, pain management, and gentle passive movements to maintain joint mobility without compromising the surgical repair. Here are key points to consider in the initial weeks following surgery:</p>
                        <ul>
                            <li>Strict adherence to sling usage</li>
                            <li>Gentle passive range of motion exercises</li>
                            <li>Effective pain management strategies</li>
                            <li>Guided education on do's and don'ts</li>
                            <li>Early engagement in light daily activities</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Progressive Strength Recovery</h1>
                    <p>As your shoulder heals, we'll guide you through a progressive strengthening program designed to rebuild the strength of your rotator cuff and surrounding muscles. This phase is critical for restoring shoulder stability and function. Our approach includes:</p>
                    <ul>
                        <li>Isometric exercises to initiate muscle engagement</li>
                        <li>Gradual introduction of resistance training</li>
                        <li>Theraband exercises for controlled strengthening</li>
                        <li>Scapular stabilization techniques</li>
                        <li>Functional exercises to mimic daily tasks</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Functional Training</h1>
                    <p>As you regain strength, our physiotherapists will incorporate advanced functional training into your rehabilitation program. This stage is designed to prepare you for a return to sport, work, or other activities that require a high level of shoulder function. Our advanced functional training includes:</p>
                    <ul>
                        <li>Plyometric exercises to enhance dynamic stability</li>
                        <li>Sport-specific drills for athletes</li>
                        <li>Work simulation activities for labor-intensive professions</li>
                        <li>Advanced proprioception and balance exercises</li>
                        <li>Integration of core stability with upper extremity movements</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Long-Term Wellness and Prevention</h1>
                    <p>At Ramp Physio and Fitness, our commitment to your health extends beyond the initial phases of rehabilitation. We aim to provide you with the tools and knowledge necessary for long-term shoulder health and injury prevention, including:</p>
                    <ul>
                        <li>Education on proper body mechanics</li>
                        <li>Customized home exercise programs</li>
                        <li>Guidance on gradual return to full activity</li>
                        <li>Strategies to avoid future injuries</li>
                        <li>Continued support from our dedicated team</li>
                    </ul>
                    <br></br>
                    <p>
                        Your journey to full recovery is our priority. By following our comprehensive rehabilitation protocol after rotator cuff surgery, you can trust that you're on the path to restoring your shoulder's strength, function, and flexibility. Our team at Ramp Physio and Fitness is here to support you every step of the way. Book your appointment today and begin your recovery with confidence.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterRotatorCuffSurgery;
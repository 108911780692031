import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyfordupuytrenscontracture = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Dupuytren's Contracture | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for Dupuytren's Contracture at Ramp Physio and Fitness. Tailored treatments to restore hand function and flexibility." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Dupuytren's Contracture</h1>
                    <h2>Embark on a healing journey with targeted physiotherapy for Dupuytren's Contracture at Ramp Physio and Fitness, your pathway to restored hand movement and strength.</h2>
                    <p>Dupuytren's Contracture can significantly impact your hand function, but with the right physiotherapy approach, it's possible to manage and mitigate its effects. At Ramp Physio and Fitness, we specialize in treatments designed to slow the progression of Dupuytren's Contracture, alleviate symptoms, and enhance hand mobility. This article will guide you through our physiotherapy strategies and how they can help in the management of Dupuytren's Contracture.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Dupuytren's Contracture</h1>
                        <p>Dupuytren's Contracture is a hand condition that gradually causes the fingers to curl inward toward the palm. The thickening and shortening of the palmar fascia lead to restricted movement, making everyday tasks challenging. Our physiotherapy services at Ramp Physio and Fitness are tailored to address the unique needs of individuals with Dupuytren's, focusing on exercises and therapies that can help maintain hand function and delay the need for surgical intervention.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Physiotherapy Techniques</h1>
                    <p>Our physiotherapy approach for Dupuytren's Contracture includes a variety of techniques designed to improve flexibility and strength in the affected hand. We incorporate:</p>
                    <ul>
                        <li>Stretching exercises to enhance finger extension</li>
                        <li>Strengthening workouts for hand muscles</li>
                        <li>Manual therapy to reduce fascial tension</li>
                        <li>Ultrasound therapy to promote tissue healing</li>
                        <li>Splinting to gently stretch the fingers</li>
                        <li>Advice on activity modification to prevent worsening of the condition</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Post-Treatment Care and Support</h1>
                    <p>Following any invasive treatment for Dupuytren's Contracture, such as needle aponeurotomy or surgery, physiotherapy plays a critical role in recovery. Our post-treatment care at Ramp Physio and Fitness includes scar tissue management, range-of-motion exercises, and strengthening to support optimal recovery outcomes. We work closely with your healthcare team to ensure a coordinated approach to your rehabilitation.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Plans</h1>
                    <p>At Ramp Physio and Fitness, we understand that each case of Dupuytren's Contracture is unique. We create customized treatment plans based on your specific condition and goals. Our team is dedicated to providing you with the best physiotherapy care, whether you are in the early stages of the disease or dealing with advanced contractures. By partnering with us, you can trust that you're receiving personalized attention and expert guidance throughout your journey to better hand function.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyfordupuytrenscontracture;
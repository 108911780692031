import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForRehabilitationAndInjuryRecovery = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Workouts for Rehabilitation</h1>
                    <h2>Maximize your recovery with tailored gym workouts at Ramp Physio and Fitness, where rehabilitation meets personalized strength and conditioning.</h2>
                    <p>Understanding the pivotal role of gym workouts in the rehabilitation and injury recovery process is essential. At Ramp Physio and Fitness, we prioritize your return to optimal health through structured and safe exercise regimens. Our expert team combines physiotherapy expertise with fitness guidance to create gym workouts that not only aid in your rehabilitation but also promote overall strength and resilience. This article will explore the crucial exercises and principles behind effective gym-based recovery strategies.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Rehabilitation" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Recovery Through Resistance</h1>
                        <p>Resistance training is a cornerstone of gym-based rehabilitation, offering numerous benefits such as improved muscle strength, joint stability, and enhanced mobility. By carefully selecting and adapting exercises, our physiotherapists ensure that your workout plan aligns with your recovery goals, taking into account the nature and extent of your injury. Here are examples of resistance exercises commonly used in rehab programs:</p>
                        <ul>
                            <li>Seated leg press for lower body strength</li>
                            <li>Cable rows for back and shoulder rehabilitation</li>
                            <li>Leg curls and extensions for knee stability</li>
                            <li>Lat pulldowns to improve upper body conditioning</li>
                            <li>Modified squats for core and leg strength</li>
                            <li>Bicep curls with resistance bands for arm rehabilitation</li>
                            <li>Wall push-ups to enhance shoulder recovery</li>
                            <li>Modified lunges for hip and knee recovery</li>
                            <li>Balance exercises on a Bosu ball for proprioception</li>
                            <li>Gentle stretching with resistance bands for flexibility</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Cardiovascular Conditioning</h1>
                    <p>Cardiovascular exercise plays an essential role in injury recovery, helping to maintain heart health and stamina while promoting circulation. At Ramp Physio and Fitness, we incorporate low-impact cardio routines that facilitate recovery without placing undue stress on your body. Examples of cardio exercises suitable for rehabilitation include:</p>
                    <ul>
                        <li>Stationary cycling with controlled resistance</li>
                        <li>Elliptical training for whole-body engagement</li>
                        <li>Walking on a treadmill at a gentle incline</li>
                        <li>Swimming or water aerobics for non-weight-bearing cardio</li>
                        <li>Rowing machine workouts for upper body endurance</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Functional Movement Focus</h1>
                    <p>Rehabilitation is not just about healing; it's about returning to daily activities with confidence. Our gym workouts emphasize functional exercises that mimic everyday movements, ensuring that your recovery translates into real-world strength and mobility. We integrate exercises such as:</p>
                    <ul>
                        <li>Step-ups for stair climbing ability</li>
                        <li>Farmer's walk for grip and carry strength</li>
                        <li>Box lifts for squatting and lifting mechanics</li>
                        <li>Single-leg deadlifts for balance and unilateral strength</li>
                        <li>Medicine ball throws for dynamic core stability</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehab Programs</h1>
                    <p>Your journey to recovery is unique, and so should be your gym workout regimen. At Ramp Physio and Fitness, we craft individualized rehabilitation programs that consider your specific injury, fitness level, and personal goals. Our commitment to personalized care ensures that each session is both effective and motivating, helping you to recover stronger and faster.</p>
                    <br></br>
                    <p>
                        In conclusion, gym workouts for rehabilitation and injury recovery are a vital part of the healing process. By combining expert physiotherapy knowledge with tailored gym exercises, Ramp Physio and Fitness empowers you to overcome challenges and achieve your health and fitness goals. Book your session today and take the first step towards a stronger, healthier you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForRehabilitationAndInjuryRecovery;
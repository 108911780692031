import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Circuittrainingbenefitsandsampleworkouts = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Circuit Training</h1>
                    <h2>Unlock the power of circuit training at Ramp Physio and Fitness and experience a surge in your fitness journey with our expert-led sessions in Homebush, NSW.</h2>
                    <p>At Ramp Physio and Fitness, we understand the significance of varied and dynamic workout routines. Circuit training stands out as a versatile and efficient exercise modality that caters to various fitness levels and goals. This article will guide you through the myriad benefits of circuit training and provide you with sample workouts to kick-start your routine or enhance your current regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Circuit Training" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Efficient Full-Body Workouts</h1>
                        <p>Circuit training is a form of body conditioning that involves endurance training, resistance training, high-intensity aerobics, and exercises performed in a circuit. It targets strength building and muscular endurance, making it an all-encompassing workout. Here are the key benefits of integrating circuit training into your fitness routine:</p>
                        <ul>
                            <li>Enhanced cardiovascular health</li>
                            <li>Improved muscle strength and tone</li>
                            <li>Increased metabolic rate</li>
                            <li>Better weight management</li>
                            <li>Time-efficient for busy schedules</li>
                            <li>Customizable workouts</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Dynamic and Adaptable Training</h1>
                    <p>Circuit training is not a one-size-fits-all approach. It allows for flexibility in exercise selection, order, and intensity. Whether you're a beginner or an advanced athlete, you can tailor your circuit to challenge your personal fitness level. Here are ten examples of exercises you might include in a circuit:</p>
                        <ol>
                            <li>Jump rope</li>
                            <li>Squats</li>
                            <li>Push-ups</li>
                            <li>Plank holds</li>
                            <li>Box jumps</li>
                            <li>Dumbbell rows</li>
                            <li>Lunges</li>
                            <li>Mountain climbers</li>
                            <li>Kettlebell swings</li>
                            <li>Burpees</li>
                        </ol>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Sample Circuit Workouts</h1>
                    <p>To get you started, here are two sample circuit workouts that you can try at our gym or incorporate into your own fitness routine:</p>
                    <h3>Beginner Circuit:</h3>
                    <ol>
                        <li>Bodyweight squats: 15 reps</li>
                        <li>Incline push-ups: 12 reps</li>
                        <li>Bent-over dumbbell rows: 10 reps each side</li>
                        <li>Step-ups: 10 reps each leg</li>
                        <li>Plank: 30 seconds</li>
                        <li>Jumping jacks: 30 seconds</li>
                    </ol>
                    <h3>Advanced Circuit:</h3>
                    <ol>
                        <li>Kettlebell swings: 15 reps</li>
                        <li>Push-ups: 20 reps</li>
                        <li>Box jumps: 15 reps</li>
                        <li>Burpees: 10 reps</li>
                        <li>Mountain climbers: 30 seconds</li>
                        <li>Sprint: 30 seconds</li>
                    </ol>
                    <p>Complete 2-3 rounds of each circuit with minimal rest between exercises and a 2-minute rest between rounds.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Us for Circuit Training</h1>
                    <p>At Ramp Physio and Fitness, we offer circuit training sessions that are designed to keep you engaged and motivated. Our expert trainers will guide you through each workout, ensuring proper form and technique while challenging you to push your limits. Whether you're looking to improve your overall fitness, burn fat, or build muscle, our circuit training classes provide the structure and support you need to achieve your health and fitness goals.</p>
                    <br></br>
                    <p>
                        Don't wait any longer to elevate your workout routine. Join us at Ramp Physio and Fitness for circuit training sessions that will transform your body and mind. Book your spot today and take the first step towards a fitter, healthier you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Circuittrainingbenefitsandsampleworkouts;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const NeuromuscularAndChronic = () => {
  const articles = [
    { title: 'Physiotherapy Treatment and Exercises for Aged Care Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforagedcarephysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Arthritis', path: '/news/physiotherapytreatmentandexercisesforarthritis' },
    { title: 'Physiotherapy Treatment and Exercises for Back pain', path: '/news/physiotherapytreatmentandexercisesforbackpain' },
    { title: 'Physiotherapy Treatment and Exercises for Balance problems', path: '/news/physiotherapytreatmentandexercisesforbalanceproblems' },
    { title: 'Physiotherapy Treatment and Exercises for Chronic pain', path: '/news/physiotherapytreatmentandexercisesforchronicpain' },
    { title: 'Physiotherapy Treatment and Exercises for Dry Needling', path: '/news/physiotherapytreatmentandexercisesfordryneedling' },
    { title: 'Physiotherapy Treatment and Exercises for Elbow pain', path: '/news/physiotherapytreatmentandexercisesforelbowpain' },
    { title: 'Physiotherapy Treatment and Exercises for Fibromyalgia', path: '/news/physiotherapytreatmentandexercisesforfibromyalgia' },
    { title: 'Physiotherapy Treatment and Exercises for Gait and Balance', path: '/news/physiotherapytreatmentandexercisesforgaitandbalance' },
    { title: 'Physiotherapy Treatment and Exercises for Headaches', path: '/news/physiotherapytreatmentandexercisesforheadaches' },
    { title: 'Physiotherapy Treatment and Exercises for Hip pain', path: '/news/physiotherapytreatmentandexercisesforhippain' },
    { title: 'Physiotherapy Treatment and Exercises for Knee pain', path: '/news/physiotherapytreatmentandexercisesforkneepain' },
    { title: 'Physiotherapy Treatment and Exercises for Leg pain', path: '/news/physiotherapytreatmentandexercisesforlegpain' },
    { title: 'Physiotherapy Treatment and Exercises for Lower back pain', path: '/news/physiotherapytreatmentandexercisesforlowerbackpain' },
    { title: 'Physiotherapy Treatment and Exercises for Middle back pain', path: '/news/physiotherapytreatmentandexercisesformiddlebackpain' },
    { title: 'Physiotherapy Treatment and Exercises for Migraines', path: '/news/physiotherapytreatmentandexercisesformigraines' },
    { title: 'Physiotherapy Treatment and Exercises for Motor Vehicle Injuries', path: '/news/physiotherapytreatmentandexercisesformotorvehicleinjuries' },
    { title: 'Physiotherapy Treatment and Exercises for Neck pain', path: '/news/physiotherapytreatmentandexercisesforneckpain' },
    { title: 'Physiotherapy Treatment and Exercises for Shoulder pain', path: '/news/physiotherapytreatmentandexercisesforshoulderpain' },
    { title: 'Physiotherapy Treatment and Exercises for Side pain', path: '/news/physiotherapytreatmentandexercisesforsidepain' },
    { title: 'Physiotherapy Treatment and Exercises for Soft Tissue and Muscle Injuries', path: '/news/physiotherapytreatmentandexercisesforsofttissueandmuscleinjuries' },
    { title: 'Physiotherapy Treatment and Exercises for Sports injuries', path: '/news/physiotherapytreatmentandexercisesforsportsinjuries' },
    { title: 'Physiotherapy Treatment and Exercises for Sports Injury Prevention', path: '/news/physiotherapytreatmentandexercisesforsportsinjuryprevention' },
    { title: 'Physiotherapy Treatment and Exercises for Upper back pain', path: '/news/physiotherapytreatmentandexercisesforupperbackpain' },
    { title: 'Physiotherapy Treatment and Exercises for Whiplash', path: '/news/physiotherapytreatmentandexercisesforwhiplash' },
    { title: 'Physiotherapy Treatment and Exercises for Wrist pain', path: '/news/physiotherapytreatmentandexercisesforwristpain' },
    { title: 'NDIS Physiotherapy for Chronic Fatigue', path: '/news/ndisphysiotherapyforchronicfatigue' },
    { title: 'NDIS Physiotherapy for Fibromyalgia', path: '/news/ndisphysiotherapyforfibromyalgia' },
    { title: 'NDIS Physiotherapy for Multiple Sclerosis', path: '/news/ndisphysiotherapyformultiplesclerosis' },
    { title: 'NDIS Physiotherapy for Muscular Dystrophy', path: '/news/ndisphysiotherapyformusculardystrophy' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>NDIS Physiotherapy for Neuromuscular and Chronic Conditions</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeuromuscularAndChronic;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForCombattingSedentaryLifestyles = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Combat Sedentary Lifestyles with Gym Workouts</h1>
                    <h2>Achieve a Healthier, More Active Life at Ramp Physio and Fitness with Tailored Gym Workouts Designed to Counter the Risks of a Sedentary Lifestyle</h2>
                    <p>As we navigate through a world where sedentary behavior is increasingly common, the importance of physical activity cannot be overstated. Ramp Physio and Fitness is committed to helping you break free from the chains of a sedentary lifestyle with gym workouts that are both effective and enjoyable. Through a combination of strength training, cardiovascular exercises, and flexibility routines, our expert team will guide you on the path to a more active and vibrant life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Building Strength, Boosting Stamina</h1>
                        <p>Incorporating gym workouts into your daily routine is a powerful way to combat the negative effects of sitting for extended periods. Our strength-building programs focus on major muscle groups, ensuring a balanced approach to muscle development. Cardiovascular workouts are tailored to increase your heart rate and improve your overall stamina, while our flexibility sessions aim to enhance your range of motion and prevent injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Training Regimens</h1>
                    <p>Every individual's body is unique, and so should be their workout regimen. At Ramp Physio and Fitness, we personalize your gym workouts to align with your specific health and fitness goals. Whether you're looking to shed weight, build muscle, or simply enhance your overall well-being, our customized plans are designed to deliver results while keeping you motivated and engaged.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Sedentary Risks</h1>
                    <p>Recognizing the health risks associated with a sedentary lifestyle is the first step towards making a change. Long periods of inactivity can lead to a host of health issues, including heart disease, diabetes, and obesity. Our team at Ramp Physio and Fitness educates you on these risks and provides you with the tools and support to incorporate more movement into your daily life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Active Breaks, Better Health</h1>
                    <p>Integrating short, active breaks throughout your day is a simple yet effective way to combat the effects of a sedentary lifestyle. We recommend simple exercises that can be done at home or in the office to keep your body moving. From standing stretches to desk-based workouts, these small changes can make a significant impact on your health.</p>
                    <br></br>
                    <ul>
                        <li>Stand up every 30 minutes for a quick stretch</li>
                        <li>Take the stairs instead of the elevator</li>
                        <li>Walk while taking phone calls</li>
                        <li>Perform seated leg lifts under your desk</li>
                        <li>Use a standing desk or adjustable workstation</li>
                        <li>Engage in quick 5-minute workouts during breaks</li>
                        <li>Replace your office chair with a stability ball</li>
                        <li>Go for a walk during lunch breaks</li>
                        <li>Organize walking meetings</li>
                        <li>Do calf raises while standing in line</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForCombattingSedentaryLifestyles;
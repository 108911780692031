import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Posture: Corrective Exercises for Long-Term Health</h1>
                    <h2>Discover how corrective exercises can transform your posture and enhance overall well-being with our expert-guided strategies at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that good posture is the cornerstone of a healthy lifestyle. It's not just about standing tall; it's about nurturing a body that's balanced, strong, and less prone to injury. As a dedicated physiotherapist, it's my pleasure to guide you through the journey of improving your posture through corrective exercises. These movements are designed not only to alleviate immediate discomfort but also to provide a foundation for long-term health and fitness. Let's embark on a path to a better you, with a spine that’s aligned, muscles that are engaged correctly, and a posture that exudes confidence and vitality.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Correct Your Posture, Elevate Your Health</h1>
                        <p>Poor posture isn't just an aesthetic issue; it's a health concern that can lead to chronic pain, reduced mobility, and a host of other musculoskeletal problems. At Ramp Physio and Fitness, we emphasize the importance of addressing posture early on. By incorporating corrective exercises into your routine, you're taking a proactive step towards preventing these issues. Our approach focuses on strengthening the core, enhancing flexibility, and building awareness of your body's alignment. Through a series of targeted movements, we teach your body to adopt healthier patterns that contribute to a more balanced and pain-free life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strengthening Your Core, Aligning Your Spine</h1>
                    <p>The core is your body's powerhouse. It supports your spine, maintains your balance, and plays a pivotal role in almost every movement you make. A strong core is essential for good posture. Our physiotherapy programs at Ramp Physio and Fitness include exercises that specifically target the muscles surrounding your spine and abdomen. These exercises are designed not just to build strength but also to promote the endurance and stability needed to maintain proper posture throughout your daily activities. By consistently working on your core, you'll notice improvements in your posture, balance, and overall physical performance.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Flexibility and Mobility for Postural Integrity</h1>
                    <p>Flexibility and mobility are key components of postural correction. Tight muscles can pull your body out of alignment, while a full range of motion allows you to move efficiently and correctly. Our corrective exercise programs include stretches and movements that increase flexibility, particularly in areas that affect posture, such as the hips, shoulders, and back. By improving your flexibility, you'll reduce the risk of muscle imbalances and the discomfort that comes with them. Moreover, you'll gain a greater sense of freedom in your movements, further contributing to a healthier, more upright posture.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Awareness and Habit Formation for Lasting Change</h1>
                    <p>Correcting posture isn't a one-time fix; it's an ongoing process that requires awareness and the formation of new habits. At Ramp Physio and Fitness, we educate our clients on the importance of being mindful of their posture throughout the day. This may include how you sit at your desk, how you carry your bag, or even how you sleep. By becoming more aware of these daily habits, you can make conscious adjustments to maintain proper alignment. Coupled with regular corrective exercises, this mindfulness will pave the way for lasting change, ensuring that good posture becomes second nature.</p>
                    <br></br>
                    <p>
                      In conclusion, at Ramp Physio and Fitness, we believe that proper posture is a vital aspect of your health and fitness journey. By integrating corrective exercises into your routine, focusing on core strength, enhancing flexibility, and cultivating awareness, you'll be taking significant strides towards a healthier, more balanced body. Remember, improving your posture is an investment in your future self, and our team is here to support you every step of the way. Let's stand tall together and embrace the benefits of great posture for a lifetime of health and vitality.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingLymphedemaWithPhysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Managing Lymphedema with Physiotherapy | Ramp Physio and Fitness</title>
                <meta name="description" content="Explore how physiotherapy can aid in managing lymphedema effectively. Book a session with our experts at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Lymphedema with Physiotherapy</h1>
                    <h2>Expert care and personalized strategies to manage lymphedema and improve your quality of life with the support of Ramp Physio and Fitness's professional team.</h2>
                    <p>Dealing with lymphedema can be challenging, but with the right physiotherapy approach, it's possible to manage symptoms and reduce the impact on daily life. At Ramp Physio and Fitness, we specialize in offering personalized care plans to help those living with lymphedema. Our team is highly trained in the latest techniques to provide relief and support. In this article, we will explore the role of physiotherapy in managing lymphedema and how our services can help you regain control and comfort.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Lymphedema</h1>
                        <p>Lymphedema is a condition characterized by the accumulation of lymphatic fluid in the body, leading to swelling, most commonly in the arms or legs. It can result from congenital factors, cancer treatments, or surgery. Early intervention and risk reduction are critical in managing this condition effectively. Physiotherapy plays a pivotal role in both preventing and treating lymphedema, helping patients to maintain function and reduce discomfort.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Techniques for Lymphedema</h1>
                    <p>Physiotherapy for lymphedema includes a variety of techniques, such as Manual Lymphatic Drainage (MLD), exercises, skin care, and compression therapy. Our physiotherapists are trained in these specialized techniques to facilitate lymph flow, reduce swelling, and prevent complications. Each patient receives a tailored treatment plan that aligns with their unique needs and lifestyle, ensuring the best possible outcomes.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of Physiotherapy for Lymphedema</h1>
                    <ul>
                        <li>Reduction in swelling and discomfort</li>
                        <li>Improved range of motion and flexibility</li>
                        <li>Enhanced skin condition and prevention of infection</li>
                        <li>Education on self-management techniques</li>
                        <li>Better quality of life through customized care</li>
                        <li>Support with compression garment fitting and use</li>
                        <li>Guidance on appropriate exercises to encourage lymph flow</li>
                        <li>Preventive strategies for at-risk individuals</li>
                        <li>Integration with other cancer rehabilitation services</li>
                        <li>Access to a supportive community and resources</li>
                    </ul>
                    <p>Our physiotherapists provide education and support, empowering patients to take an active role in managing their condition. We understand the complexities of lymphedema and are dedicated to helping you navigate this journey with confidence and care.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise and Lymphedema Management</h1>
                    <p>Exercise is a crucial component of lymphedema management. Our physiotherapists design individualized exercise programs that safely promote lymph circulation without exacerbating symptoms. These programs may include gentle aerobic exercises, resistance training, and flexibility exercises, all aimed at improving lymphatic function and overall well-being.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, we are committed to providing comprehensive care for those with lymphedema. Our evidence-based approach ensures that you receive the best possible support, helping you to manage your symptoms and lead a more active and fulfilling life. Contact us today to learn how we can assist you in your lymphedema management journey.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingLymphedemaWithPhysiotherapy;
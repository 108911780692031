import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Knee Pain with Expert Physiotherapy</h1>
                    <h2>Discover the benefits of specialized knee pain physiotherapy at Ramp Physio and Fitness, and learn how targeted exercises and therapies can enhance your recovery and mobility.</h2>
                    <p>Knee pain is a common affliction that can significantly hinder your quality of life, affecting your ability to perform daily activities and engage in sports or fitness routines. At Ramp Physio and Fitness, we understand the complexities of knee injuries and the importance of personalized care. Our approach to knee pain physiotherapy is rooted in evidence-based practices and tailored to meet the unique needs of each individual. Whether you're managing chronic pain, recovering from an injury, or seeking to improve your overall knee health, our expert team is here to guide you through a comprehensive rehabilitation journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Knee Pain and Its Causes</h1>
                        <p>Knee pain can stem from a variety of sources, ranging from acute injuries such as ligament sprains and meniscus tears to chronic conditions like osteoarthritis and patellofemoral pain syndrome. The knee is a complex joint that bears a significant amount of stress during everyday activities, making it susceptible to wear and tear as well as sudden trauma. It's crucial to identify the root cause of your knee pain to develop an effective treatment plan. At Ramp Physio and Fitness, our initial assessment delves deep into your medical history, current condition, and lifestyle factors to ensure we address the underlying issues contributing to your discomfort.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Role of Physiotherapy in Knee Pain Management</h1>
                    <p>Physiotherapy is a cornerstone of knee pain treatment, offering a non- invasive and holistic approach to recovery. The benefits of physiotherapy for knee pain include reducing inflammation, strengthening surrounding muscles, improving joint mobility, and alleviating discomfort. Our therapists at Ramp Physio and Fitness employ a variety of techniques such as manual therapy, exercise prescription, and modalities like ultrasound or dry needling to foster healing. By working closely with you, we can not only address your present pain but also implement strategies to prevent future injuries and maintain long-term knee health.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tailored Exercises for Knee Pain Relief</h1>
                    <p>Exercise is a vital component of knee pain rehabilitation, and at Ramp Physio and Fitness, we emphasize the importance of a personalized exercise regimen. Specific exercises can help restore function, enhance stability, and build resilience in the knee joint. While each individual's program will vary, common elements include a focus on strengthening the quadriceps, hamstrings, and calf muscles, as well as improving flexibility and balance. Our physiotherapists will guide you through a series of exercises designed to meet your recovery goals while ensuring proper technique and progression to maximize benefits and minimize the risk of re-injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>When to Seek Professional Physiotherapy for Knee Pain</h1>
                    <p>Knowing when to consult a physiotherapist for knee pain is key to a successful recovery. If you're experiencing persistent pain, swelling, decreased mobility, or instability in the knee, it's time to seek professional help. Additionally, if you've sustained a knee injury or are undergoing post- operative rehabilitation, physiotherapy can play a pivotal role in your journey back to full function. At Ramp Physio and Fitness, we encourage you to reach out at the first signs of knee discomfort so we can help you get back to your active lifestyle as swiftly and safely as possible.</p>
                    <br></br>
                    <p>
                        In conclusion, knee pain physiotherapy at Ramp Physio and Fitness is a comprehensive approach to managing and overcoming knee-related issues. With our expert team's dedication to personalized care and our commitment to utilizing the latest evidence-based practices, you can trust that your knees are in capable hands. Don't let knee pain hold you back any longer—take the first step towards recovery and enhanced mobility by scheduling an appointment with us today.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

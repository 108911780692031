// components/utils/HealthSection.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const HealthSection = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
    };

    emailjs.send('service_gvt5vwp', 'template_nwga3kh', templateParams, 'OpJMLUp7AgSF4zW4L')
      .then((response) => {
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        });
      }, (error) => {
        alert('There was an error submitting the form.');
        console.error('Failed to send email:', error);
      });
  };

  return (
    <div className="health-section servicedetail-margin-top">
      <div className="health-content">
        <h2>TAKE CONTROL OF YOUR HEALTH!</h2>
        <p>Book a consultation or join our personalised gym and PT sessions to improve your well-being.</p>
      </div>
      <div className="health-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="first-name">Name <span>(required)</span></label>
            <div className="form-group-inline">
              <input
                type="text"
                id="first-name"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                id="last-name"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email <span>(required)</span></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone <span>(required)</span></label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default HealthSection;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Ndisphysiotherapyforparkinsonsdisease = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Parkinson's Disease | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert NDIS physiotherapy services for Parkinson's Disease patients at Ramp Physio and Fitness. Personalized care to improve mobility and quality of life." />
            </Helmet>

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Parkinson's Patients with NDIS Physiotherapy</h1>
                    <h2>Discover how our specialized NDIS physiotherapy services at Ramp Physio and Fitness can enhance the lives of those living with Parkinson's Disease.</h2>
                    <p>Parkinson's Disease presents unique challenges that require specialized physiotherapy interventions. At Ramp Physio and Fitness, our NDIS-approved services are designed to empower individuals with Parkinson's to maintain and improve their mobility, balance, and overall quality of life. Our expert team is committed to delivering personalized care that aligns with each participant's NDIS plan, ensuring that our clients can access the support they need to thrive.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Parkinson's and Physiotherapy</h1>
                        <p>Parkinson's Disease is a progressive neurological condition that affects movement. Symptoms such as tremor, stiffness, and bradykinesia can significantly impact daily activities. Physiotherapy plays a crucial role in managing these symptoms, with tailored exercises and therapies designed to enhance function and delay the progression of the disease.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness are experienced in the complexities of Parkinson's and work closely with clients to develop customized treatment plans. We focus on improving gait, balance, and strength, while also addressing the non-motor symptoms that can accompany the disease, such as fatigue and depression.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Massage" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>NDIS Support for Parkinson's Therapy</h1>
                        <p>The National Disability Insurance Scheme (NDIS) provides support to Australians with a permanent and significant disability, including those with Parkinson's Disease. NDIS funding can cover the cost of physiotherapy services, making it more accessible for participants to receive ongoing care.</p>

                        <p>At Ramp Physio and Fitness, we assist our clients in navigating the NDIS process, ensuring that physiotherapy goals are included in their plan. Our services are tailored to align with NDIS requirements, and we provide the necessary documentation and reports to support our clients' plans.</p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tailored Exercise Programs for Parkinson's</h1>
                    <p>Exercise is a cornerstone of managing Parkinson's Disease, and our physiotherapists are experts in crafting programs that cater to the specific needs of our clients. We incorporate a variety of exercises to improve flexibility, strength, and cardiovascular health, all of which contribute to better movement quality.</p>

                    <p>Examples of exercises we may include in a program are:
                        <br/>- Standing leg lifts for balance and strength
                        <br/>- Seated leg extensions to maintain joint mobility
                        <br/>- Arm raises to enhance upper body strength
                        <br/>- Walking exercises to improve gait and endurance
                        <br/>- Gentle stretching routines for flexibility
                        <br/>- Postural exercises to combat stooped posture
                        <br/>- Breathing exercises for respiratory function
                        <br/>- Resistance band exercises for muscle toning
                        <br/>- Coordination drills to address fine motor skills
                        <br/>- Relaxation techniques to ease muscle tension
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Advanced Therapies for Symptom Management</h1>
                        <p>In addition to exercise, we utilize advanced therapies to manage Parkinson's symptoms. Techniques such as neuromuscular re-education, balance training, and proprioceptive neuromuscular facilitation are part of our comprehensive approach.</p>

                        <p>Our physiotherapists are also trained in the use of technology-assisted therapies, such as virtual reality and biofeedback, which can offer engaging and effective ways to improve motor control and cognitive function.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforparkinsonsdisease;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingBiofeedbackInPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Mind-Body Control</h1>
                    <h2>Unlock the potential of biofeedback in physiotherapy at Ramp Physio and Fitness, where cutting-edge technology meets personalized care in Homebush, NSW.</h2>
                    <p>Understanding Biofeedback in Physiotherapy is a game-changer for patients and therapists alike. At Ramp Physio and Fitness, we incorporate biofeedback into our therapy programs to provide you with an innovative approach to rehabilitation and performance enhancement. This article will explore the intricacies of biofeedback, its benefits, and how it can be integrated into your health and fitness journey with our expert team.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science of Biofeedback</h1>
                        <p>Biofeedback is a non-invasive treatment that teaches patients to control physiological processes such as muscle tension, pain perception, and heart rate. It involves the use of electronic monitoring to convey information about these processes to the patient in real-time. This feedback enables individuals to make subtle changes in their body, resulting in improved health outcomes. It's particularly effective for conditions like chronic pain, muscle re-education, and stress-related disorders.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Biofeedback</h1>
                    <p>Biofeedback therapy has numerous benefits, including the ability to:
                        <ul>
                            <li>Reduce stress and anxiety</li>
                            <li>Control pain without medication</li>
                            <li>Improve athletic performance</li>
                            <li>Enhance recovery from musculoskeletal injuries</li>
                            <li>Manage symptoms of urinary incontinence</li>
                            <li>Train the body to respond differently to pain</li>
                            <li>Improve blood flow and physical function</li>
                            <li>Decrease muscle tension</li>
                            <li>Strengthen weak muscles</li>
                            <li>Improve body awareness</li>
                        </ul>
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Types of Biofeedback</h1>
                    <p>There are several types of biofeedback, each targeting different aspects of the body's function:
                        <ul>
                            <li>Electromyography (EMG) for muscle activity</li>
                            <li>Thermal biofeedback for skin temperature</li>
                            <li>Neurofeedback for brain wave patterns</li>
                            <li>Heart rate variability (HRV) for cardiovascular functions</li>
                            <li>Respiratory biofeedback for breathing patterns</li>
                        </ul>
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Integrating Biofeedback</h1>
                        <p>At Ramp Physio and Fitness, integrating biofeedback into your rehabilitation or fitness program is seamless. Our physiotherapists are trained to combine biofeedback with traditional therapeutic exercises, ensuring a comprehensive treatment plan. Whether you are recovering from an injury or seeking to enhance your athletic performance, biofeedback can be personalized to meet your specific needs and goals.</p>
                        <br></br>
                        <p>
                            Embrace the power of biofeedback to take control of your health and well-being. Our team at Ramp Physio and Fitness is ready to assist you on your journey to recovery and peak performance. Experience the transformative effects of biofeedback by scheduling a session with us today.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingBiofeedbackInPhysiotherapy;
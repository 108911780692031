import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Discover Premier Homebush Physiotherapy at Ramp Physio and Fitness</h1>
                    <h2>Ramp Physio and Fitness, located in the heart of Homebush, Sydney, offers top-notch physiotherapy services dedicated to your recovery and wellness. With a focus on personalized care, our clinic is your go-to destination for effective treatment and lasting health benefits.</h2>
                    <p>When it comes to finding high-quality physiotherapy services in Homebush, Ramp Physio and Fitness stands out as a trusted provider. Located in the vibrant suburb of Homebush, Sydney, our clinic is committed to delivering personalized physiotherapy that addresses your specific needs. Whether you’re recovering from an injury, managing chronic pain, or looking to enhance your physical performance, our expert team is here to guide you every step of the way. At Ramp Physio and Fitness, we pride ourselves on being an integral part of the Homebush community, offering services that help you achieve your health and wellness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Personalized Physiotherapy in Homebush</h1>
                        <p>At Ramp Physio and Fitness, we understand that every individual’s body and recovery journey are unique. That’s why our Homebush physiotherapy services are tailored to meet your specific needs. Our experienced physiotherapists take the time to assess your condition thoroughly and develop a customized treatment plan that promotes healing and improves your overall well-being. Whether you’re dealing with a sports injury, post-surgical rehabilitation, or chronic conditions, our goal is to provide you with the highest standard of care right here in Homebush.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive Services for Every Need</h1>
                    <p>Our Homebush physiotherapy clinic offers a wide range of services designed to cater to various health issues and fitness levels. From manual therapy and exercise prescription to injury prevention and management, we have the expertise and resources to support your recovery. Our holistic approach ensures that we address not only the symptoms but also the underlying causes of your condition, leading to more effective and long-lasting results. Located conveniently in Homebush, Ramp Physio and Fitness is your local solution for comprehensive physiotherapy care.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Convenient Location and Flexible Hours</h1>
                    <p>We understand the importance of accessibility when it comes to healthcare. That’s why Ramp Physio and Fitness is strategically located in Homebush, making it easy for residents of the suburb and surrounding areas to access our services. Additionally, we offer flexible hours, including after-hours appointments, to ensure that you can receive the care you need without disrupting your busy schedule. Whether you’re a local resident or work in the area, our Homebush physiotherapy clinic is designed to accommodate your needs with convenience and efficiency.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Expert Care in a Community Setting</h1>
                    <p>At Ramp Physio and Fitness, we’re more than just a physiotherapy clinic—we’re a part of the Homebush community. Our team is dedicated to building strong relationships with our clients, ensuring that you feel supported throughout your recovery process. We believe that physiotherapy should be a collaborative effort, with our therapists working closely with you to achieve the best possible outcomes. By choosing Ramp Physio and Fitness, you’re not just getting expert care; you’re joining a community committed to your health and well-being.</p>
                    <br></br>
                    <p>
                    Ramp Physio and Fitness is your trusted provider of Homebush physiotherapy, offering personalized, expert care in a convenient and welcoming environment. Whether you’re recovering from an injury, managing chronic pain, or looking to improve your physical performance, our clinic in Homebush is here to help you achieve your health goals. Visit us today and experience the difference that personalized physiotherapy can make in your life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

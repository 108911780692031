import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unfreezing Your Mobility: Overcoming Frozen Shoulder</h1>
                    <h2>This comprehensive guide explores the causes, symptoms, and treatment options for frozen shoulder, providing insights and strategies for effective management and recovery.</h2>
                    <p>At Ramp Physio and Fitness, we understand how a frozen shoulder can put a freeze on your daily activities and fitness goals. Frozen shoulder, or adhesive capsulitis, is a condition characterized by stiffness and pain in the shoulder joint. It can start gradually, worsen over time, and can significantly impact your quality of life. In this article, we will delve into the complexities of frozen shoulder and offer guidance on how you can thaw the stiffness and regain your mobility through informed healthcare and personalized physiotherapy approaches.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Frozen Shoulder</h1>
                        <p>Frozen shoulder is a condition that affects the flexibility of your shoulder joint. It typically develops when the connective tissue surrounding your shoulder joint, known as the capsule, becomes thickened and tight. This can lead to painful movements and a significant reduction in the range of motion. The exact cause of frozen shoulder is not always clear, but it can be associated with diabetes, thyroid diseases, and prolonged immobilization of the shoulder.</p>

                        <p>The development of frozen shoulder is commonly divided into three stages: the freezing stage, where you start to notice pain and a decrease in the range of motion; the frozen stage, where the pain may begin to diminish but the stiffness remains; and the thawing stage, where the range of motion starts to improve. Understanding these stages is crucial for effective treatment and management.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive Treatment Strategies</h1>
                    <p>The path to recovering from a frozen shoulder often involves a combination of approaches. The primary goals are to reduce pain and restore normal range of motion and function to the shoulder. Treatment options may include pain management strategies, such as medications and heat or ice application, as well as physiotherapy.</p>

                    <p>Physiotherapy plays a pivotal role in the recovery from a frozen shoulder. It involves exercises that stretch the shoulder capsule and improve range of motion. At Ramp Physio and Fitness, our team of physiotherapists will work with you to develop a personalized exercise program that aligns with your specific needs and stage of recovery. We may also incorporate other techniques such as manual therapy, to help loosen the joint and reduce pain.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Techniques and Support</h1>
                    <p>In some cases, more invasive treatments may be necessary if conservative methods are not effective. These can include corticosteroid injections to reduce inflammation or even surgical interventions to loosen the joint capsule. However, these are typically considered when other treatments have not yielded satisfactory results.</p>

                    <p>At Ramp Physio and Fitness, we also offer support for those with chronic conditions like diabetes, which can be associated with frozen shoulder. Our holistic approach to healthcare ensures that we address not just the symptoms but also the underlying causes that may contribute to the development of this condition.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Staying Active and Preventing Recurrence</h1>
                        <p>Once you begin to recover from a frozen shoulder, it is important to maintain an active lifestyle to prevent recurrence. Our team can guide you through group training classes and other fitness activities that are safe and beneficial for your shoulder health. By staying active and incorporating regular stretching and strengthening exercises into your routine, you can help keep your shoulder joints flexible and strong.</p>
                        <br></br>
                        <p>
                            In conclusion, frozen shoulder is a debilitating condition, but with the right guidance and treatment, you can regain your mobility and return to your favorite activities. At Ramp Physio and Fitness, we are committed to providing top-tier sports injury management and physiotherapy to help you overcome this challenge. Whether you are in the early stages of frozen shoulder or looking for advanced management strategies, our team is here to support you every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

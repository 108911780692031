import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import postSurgicalLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForPostSurgicalRehabilitation = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Post-Surgical Rehabilitation | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for Post-Surgical Rehabilitation</h1>
                    <h2>Maximize Your Recovery with Personalized Physiotherapy and Targeted Exercises</h2>
                    <p>Undergoing surgery can be a daunting experience, and the journey to full recovery often requires professional guidance. At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy treatment and exercises tailored to support your post-surgical rehabilitation. Our expert team is dedicated to helping you restore function, regain strength, and return to your daily activities with confidence. Let's delve into the essential components of post-surgical rehab and how our services can assist you in your healing process.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Post-Surgery Rehabilitation Essentials</h1>
                        <p>Following surgery, your body needs time to heal and adapt. The role of physiotherapy is to facilitate this process, ensuring that you recover safely and effectively. Our post-surgical rehabilitation program includes a variety of treatments and exercises designed to:</p>
                        <ul>
                            <li>Minimize post-operative pain and swelling</li>
                            <li>Restore joint mobility and flexibility</li>
                            <li>Strengthen muscles weakened by surgery</li>
                            <li>Improve circulation to promote healing</li>
                            <li>Enhance coordination and balance</li>
                            <li>Prevent the formation of scar tissue</li>
                        </ul>
                        <p>Each rehabilitation plan is customized to the specific type of surgery you've undergone, whether it's a joint replacement, spinal surgery, or soft tissue repair. Our physiotherapists work closely with you to set realistic goals and develop a step-by-step plan to achieve them.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Targeted Exercises for Effective Recovery</h1>
                        <p>Exercise is a critical component of post-surgical rehab. At Ramp Physio and Fitness, we design exercise programs that are both safe and effective, focusing on:</p>
                        <ul>
                            <li>Gradual progression of exercise intensity</li>
                            <li>Specific exercises for the surgical site</li>
                            <li>Inclusion of full-body conditioning</li>
                            <li>Attention to proper technique to prevent injury</li>
                            <li>Adaptations for any limitations you may have</li>
                            <li>Home exercise programs for continued progress</li>
                        </ul>
                        <p>Our physiotherapists are experienced in guiding patients through exercises that not only aid in recovery but also enhance overall fitness and well-being. From basic movements to more complex exercises, we ensure that you are challenged yet comfortable at every stage of your rehabilitation.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={postSurgicalLogo} alt="Post Surgical Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Care and Ongoing Support</h1>
                    <p>At Ramp Physio and Fitness, we believe that personalized care is key to successful rehabilitation. We take the time to understand your unique situation, including any pre-existing conditions that may affect your recovery. Our ongoing support includes:</p>
                    <ul>
                        <li>Regular progress evaluations</li>
                        <li>Adjustments to your treatment plan as needed</li>
                        <li>Communication with your surgical team</li>
                        <li>Education on self-care and injury prevention</li>
                        <li>Guidance on returning to work or sports</li>
                        <li>Access to our team for any questions or concerns</li>
                    </ul>
                    <p>Our goal is to empower you with the knowledge and confidence to manage your recovery and prevent future complications. With Ramp Physio and Fitness, you're not just a patient; you're an active participant in your rehabilitation journey.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Personalized Care" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Ongoing Support" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive Recovery for All Patients</h1>
                    <p>Ramp Physio and Fitness is committed to providing comprehensive recovery services for all post-surgical patients. Whether you're an athlete looking to return to competition or someone eager to resume daily activities, we have the expertise to support your goals. Our inclusive approach ensures that every patient receives high-quality care, regardless of age, fitness level, or type of surgery.</p>
                    <br></br>
                    <p>
                        In conclusion, our physiotherapy treatment and exercises for post-surgical rehabilitation are designed to accelerate your recovery and help you achieve your health and fitness goals. With a focus on individualized care, advanced techniques, and ongoing support, Ramp Physio and Fitness is your trusted partner in post-surgical recovery. Contact us today to book your appointment and take the first step towards a successful rehabilitation.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForPostSurgicalRehabilitation;
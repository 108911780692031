import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsToImprovePosture = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts to Improve Posture | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective gym workouts to enhance your posture and overall fitness at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Perfect Your Posture with Gym Workouts</h1>
                    <h2>Explore gym workouts tailored to strengthen your core and enhance your posture, exclusively at Ramp Physio and Fitness.</h2>
                    <p>Good posture is not just about standing tall; it's about building the foundation for a healthier and more functional body. At Ramp Physio and Fitness, we understand the importance of posture in your daily activities and fitness routines. This article will guide you through gym workouts that are specifically designed to improve your posture, reduce the risk of injury, and boost your overall well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts for Posture" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Align Your Spine with Strength Training</h1>
                        <p>Strength training is a powerful tool in the quest for better posture. By focusing on the muscles that support your spine, you can develop a more upright and aligned posture. Here are 10 gym exercises that are particularly effective:</p>
                        <ul>
                            <li>Deadlifts for a strong lower back</li>
                            <li>Planks to engage the core</li>
                            <li>Seated cable rows for mid-back muscles</li>
                            <li>Chest stretches to open up the pectorals</li>
                            <li>Face pulls for upper back and shoulder alignment</li>
                            <li>Lat pulldowns to strengthen the lats</li>
                            <li>Reverse dumbbell flies for rear deltoids</li>
                            <li>Wall angels to improve scapular mobility</li>
                            <li>Thoracic extensions on a foam roller</li>
                            <li>Bridges to activate the glutes and hamstrings</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Core Workouts for a Stable Foundation</h1>
                    <p>Core strength is crucial for maintaining good posture. A strong core stabilizes your spine, allowing you to maintain proper alignment throughout the day and during physical activities. Incorporate these key exercises into your routine for a rock-solid core:</p>
                    <ul>
                        <li>Plank variations for endurance</li>
                        <li>Abdominal crunches for muscle activation</li>
                        <li>Leg raises to challenge lower abs</li>
                        <li>Mountain climbers for dynamic stability</li>
                        <li>Pallof press for anti-rotational strength</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Stretching for Postural Balance</h1>
                    <p>Stretching is an integral part of any workout routine aimed at improving posture. It helps to relieve muscle tightness and allows for greater mobility. Focus on these stretches to enhance your posture:</p>
                    <ul>
                        <li>Pectoral stretches for a more open chest</li>
                        <li>Neck stretches to reduce tension</li>
                        <li>Hamstring stretches for lower body flexibility</li>
                        <li>Hip flexor stretches to prevent anterior pelvic tilt</li>
                        <li>Child's pose for back and shoulder release</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Posture Workouts into Your Routine</h1>
                    <p>Incorporating posture-focused workouts into your regular fitness routine can have profound effects on your overall health. At Ramp Physio and Fitness, we are committed to helping you integrate these exercises seamlessly into your lifestyle, ensuring that you maintain optimal posture both in and out of the gym. Let's work together to build a stronger, healthier you with a posture to be proud of.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsToImprovePosture;
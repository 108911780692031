import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyStrengtheningExercisesToBoostMuscleConditionAndPosture = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering NDIS Participants with Physiotherapy</h1>
                    <h2>Discover how NDIS physiotherapy can enhance muscle condition and improve posture for a better quality of life at Ramp Physio and Fitness.</h2>
                    <p>NDIS Physiotherapy is a transformative program designed to empower participants with disabilities by improving their physical conditioning and posture. At Ramp Physio and Fitness, we are dedicated to providing personalized physiotherapy sessions that cater to the unique needs of NDIS participants. Our expert therapists utilize strengthening exercises to enhance muscle condition, leading to improved posture and increased independence. Join us as we delve into the benefits of NDIS physiotherapy and the specific exercises that can make a significant difference in your life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Personalized NDIS Physiotherapy Plans</h1>
                        <p>Each NDIS participant has a unique set of capabilities and goals. At Ramp Physio and Fitness, we start with an individual assessment to create a tailored physiotherapy plan. Our experienced therapists focus on exercises that not only strengthen muscles but also promote better posture and mobility. With our personalized approach, participants can look forward to achieving their health and fitness objectives in a supportive and encouraging environment.</p>

                        <p>Our strengthening programs are designed to be progressive, ensuring that as participants gain strength and confidence, the exercises evolve to continue to challenge and benefit them. By aligning with the NDIS goals, our programs help participants maximize their potential and lead more fulfilling lives.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Strengthening Exercises for Muscle and Posture</h1>
                        <p>Our NDIS physiotherapy program includes a series of strengthening exercises specifically chosen to improve muscle condition and posture. These exercises are crucial for participants as they help in reducing the risk of injury, enhancing stability, and improving overall function. Some of the key exercises we incorporate include:</p>

                        <p>
                            - Seated rows for upper back strength<br />
                            - Wall push-ups to fortify shoulder muscles<br />
                            - Leg presses to build lower body power<br />
                            - Core exercises for a stable and supportive midsection<br />
                            - Stretching routines to maintain flexibility and range of motion<br />
                            - Balance exercises to improve coordination and prevent falls<br />
                        </p>

                        <p>Participants are guided through these exercises with care and attention to ensure they are performing them correctly and safely.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Supporting Intellectual Disabilities with Physio</h1>
                    <p>NDIS physiotherapy is not just about physical strength; it's also about supporting participants with intellectual disabilities. Our therapists are trained to communicate effectively and create a welcoming environment for learning and growth. Through consistent and patient guidance, participants can improve their motor skills, increase their physical activity levels, and enjoy a sense of achievement.</p>

                    <p>We understand the importance of a holistic approach and work closely with participants, their families, and other caregivers to ensure that our physiotherapy sessions are both effective and enjoyable.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Enhancing Mobility with Expert Care</h1>
                        <p>Improving mobility is a fundamental goal of our NDIS physiotherapy program. With expert care, our therapists assist participants in performing exercises that target mobility issues. We focus on exercises that can be easily integrated into daily routines, ensuring that participants can continue to practice and benefit from them outside of our sessions.</p>

                        <p>Our team is committed to helping NDIS participants overcome barriers to mobility, whether that means adapting exercises to suit individual needs or introducing assistive devices to enhance movement.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyStrengtheningExercisesToBoostMuscleConditionAndPosture;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Gymetiquetteunwrittenruleseverymembershouldknow = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Etiquette: Unwritten Rules | Ramp Physio and Fitness</title>
                <meta name="description" content="Master gym etiquette with our guide to the unwritten rules at Ramp Physio and Fitness. Respect, cleanliness, and safety come first!" />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Etiquette: Essential Unwritten Rules</h1>
                    <h2>Adhere to these unwritten gym rules at Ramp Physio and Fitness to maintain a respectful and efficient workout environment for all members.</h2>
                    <p>Understanding and following gym etiquette is crucial for a harmonious workout environment. At Ramp Physio and Fitness in Homebush, NSW, we pride ourselves on fostering a community where respect, safety, and cleanliness are paramount. This article will guide you through the essential unwritten rules of gym etiquette that every member should know.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Us Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Respect the Space and Equipment</h1>
                        <p>Respecting the gym space and equipment is fundamental to maintaining a clean and functional workout area. This includes re-racking weights after use, wiping down machines, and being mindful of others' space. Below are key points to ensure you're respecting the facility and your fellow gym-goers:</p>
                        <ul>
                            <li>Return weights and equipment to their designated spots.</li>
                            <li>Clean equipment with provided wipes after use.</li>
                            <li>Avoid occupying machines for extended periods during peak hours.</li>
                            <li>Handle equipment with care to prevent damage or injury.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Share and Cooperate with Others</h1>
                    <p>Sharing equipment and cooperating with other gym members is essential for a positive gym experience. Here are some guidelines to promote cooperation:</p>
                    <ul>
                        <li>Allow others to "work in" or take turns on equipment.</li>
                        <li>Be approachable and open to sharing space.</li>
                        <li>Communicate clearly and politely with others.</li>
                        <li>Avoid monopolizing popular machines or areas.</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Minimize Distractions and Noise</h1>
                    <p>Keeping distractions and noise to a minimum helps everyone focus on their workouts. Here are some key points to remember:</p>
                    <ul>
                        <li>Use headphones for personal music and keep the volume reasonable.</li>
                        <li>Avoid loud conversations or phone calls on the gym floor.</li>
                        <li>Limit noise from dropping weights or slamming equipment.</li>
                        <li>Be mindful of others' concentration and personal space.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Practice Personal Hygiene</h1>
                    <p>Maintaining personal hygiene is not only courteous but also essential for the health and comfort of all gym members. Follow these hygiene tips:</p>
                    <ul>
                        <li>Use deodorant and wear clean workout clothes.</li>
                        <li>Avoid wearing heavy perfumes or colognes.</li>
                        <li>Keep your hands clean and use hand sanitizer when available.</li>
                        <li>Shower after sweaty workouts if remaining in shared spaces.</li>
                    </ul>
                    <br></br>
                    <p>
                        By adhering to these gym etiquette rules, you contribute to a pleasant and productive environment for everyone at Ramp Physio and Fitness. Remember, these unwritten rules are about respect—for the equipment, the space, and most importantly, each other. Let's work together to ensure our gym remains a welcoming place where all members can achieve their health and fitness goals.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Gymetiquetteunwrittenruleseverymembershouldknow;
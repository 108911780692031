import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Ultrasound</h1>
                    <h2>Harness the Healing Power of Ultrasound Therapy for Pain Relief and Recovery</h2>
                    <p>At Ramp Physio and Fitness, we offer advanced ultrasound therapy as part of our comprehensive physiotherapy treatments. Ultrasound therapy is a non-invasive technique that uses sound waves to promote healing in injured tissues, reduce pain, and improve circulation. Whether you’re recovering from a sports injury, surgery, or dealing with chronic pain, our expert physiotherapists integrate ultrasound therapy into your personalized treatment plan to accelerate your recovery and enhance your overall well-being.</p>
                    <p>Interested in learning how ultrasound therapy can accelerate your recovery? Contact us today to schedule a consultation and discover the benefits of this advanced treatment.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Discover the Wide-Ranging Benefits of Ultrasound Therapy</h1>
                        <p>Ultrasound therapy offers a variety of benefits for individuals dealing with musculoskeletal issues. It can be used to treat conditions like tendonitis, bursitis, muscle strains, and joint pain by promoting tissue healing, reducing inflammation, and increasing blood flow. At Ramp Physio and Fitness, we apply ultrasound therapy to target specific areas of pain and dysfunction, helping you achieve faster and more effective results in your rehabilitation journey.</p>
                        <p>Struggling with pain or injury? Ultrasound therapy might be the solution you need. Contact us to find out how it can help you recover more quickly and effectively.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Ultrasound Therapy Treatments for Optimal Results</h1>
                    <p>Every patient’s condition is unique, which is why we tailor our ultrasound therapy treatments to meet your specific needs. Our physiotherapists at Ramp Physio and Fitness use ultrasound therapy as part of a comprehensive, individualized treatment plan designed to address your particular injury or pain. Whether you’re dealing with a recent injury or managing chronic pain, we ensure that your ultrasound therapy sessions are optimized for the best possible outcomes.</p>
                    <p>Ready for a treatment plan that’s customized to your needs? Contact us to start your personalized ultrasound therapy sessions and experience the benefits of targeted care.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Healing Faster with Ultrasound Therapy</h1>
                    <p>Our clients have experienced significant improvements in their recovery timelines and pain management with the help of ultrasound therapy. From speeding up recovery from sports injuries to alleviating chronic joint pain, our clients’ success stories highlight the effectiveness of this advanced treatment. Read their testimonials to see how Ramp Physio and Fitness has helped others achieve faster, more complete recoveries with ultrasound therapy.</p>
                    <p>Inspired by our clients’ successful recoveries with ultrasound therapy? Start your own healing journey with us. Contact us today to book your appointment and explore how ultrasound therapy can benefit you.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Top10ExercisesForBuildingMuscleMass = () => {
    return (
        <div>
            <Helmet>
                <title>Top 10 Exercises for Building Muscle Mass | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the most effective exercises for building muscle mass at Ramp Physio and Fitness. Achieve your strength and fitness goals with our expert guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Muscle Growth</h1>
                    <h2>Unlock the secrets to building muscle mass with our top 10 exercises designed for peak performance and strength gains at Ramp Physio and Fitness.</h2>
                    <p>Building muscle mass isn't just about lifting weights; it's about following a strategic exercise program that targets various muscle groups effectively. At Ramp Physio and Fitness, we've compiled a list of the top 10 exercises that are essential for anyone looking to increase muscle mass and improve overall strength. Whether you're a beginner or an experienced gym-goer, these exercises will help you achieve your fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Your Session
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Build a Solid Foundation</h1>
                        <p>Creating a solid foundation is crucial when embarking on a muscle-building journey. The exercises we've selected are not only effective for muscle growth but also for enhancing functional strength and stability. Incorporating these movements into your routine will ensure a balanced and strong physique.</p>
                        <ul>
                            <li>Deadlifts</li>
                            <li>Squats</li>
                            <li>Bench Press</li>
                            <li>Overhead Press</li>
                            <li>Barbell Rows</li>
                            <li>Pull-Ups</li>
                            <li>Leg Press</li>
                            <li>Dumbbell Shoulder Press</li>
                            <li>Tricep Dips</li>
                            <li>Bicep Curls</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Technique and Form</h1>
                    <p>Mastering the proper technique and form is essential for preventing injuries and maximizing muscle engagement. Our certified trainers at Ramp Physio and Fitness provide personalized coaching to ensure you're performing each exercise correctly for optimal results.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Training
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Progressive Overload</h1>
                    <p>Progressive overload is the gradual increase of stress placed upon the body during exercise training. This principle is key to continuous improvement and muscle growth. Our programs are designed to safely increase the intensity of your workouts over time.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Training Plans</h1>
                    <p>Everyone's body is different, which is why we offer customized training plans tailored to your unique goals and needs. Our expert trainers will work with you to create a program that maximizes muscle growth while aligning with your personal fitness level and preferences.</p>
                    <br></br>
                    <p>
                        In conclusion, building muscle mass is a journey that requires dedication, proper technique, and a well-structured exercise program. At Ramp Physio and Fitness, we're committed to providing you with the guidance and support you need to succeed. With our top 10 exercises for building muscle mass and our expert team by your side, you're on the right path to achieving the strong, muscular physique you desire. Book your session today and start transforming your body with us.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Top10ExercisesForBuildingMuscleMass;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForStroke = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Stroke Recovery</h1>
                    <h2>Unlock your potential with tailored stroke rehabilitation under the NDIS at Ramp Physio and Fitness.</h2>
                    <p>Stroke recovery can be a challenging journey, but with the support of NDIS-funded physiotherapy, individuals can reclaim their independence and enhance their quality of life. Ramp Physio and Fitness in Homebush, NSW, specializes in providing personalized and evidence-based physiotherapy services to stroke survivors. Our team is dedicated to helping you navigate your rehabilitation with expert care and state-of-the-art facilities. Discover how our physiotherapy services can transform your post-stroke recovery experience.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Stroke and NDIS Eligibility</h1>
                        <p>Stroke can lead to significant physical, cognitive, and emotional changes, requiring a multifaceted approach to rehabilitation. The National Disability Insurance Scheme (NDIS) provides funding for eligible individuals to access necessary physiotherapy services. Our team at Ramp Physio and Fitness is experienced in navigating the NDIS process, ensuring you receive the support you need for a successful recovery.</p>
                        <p>Eligibility for NDIS funding typically involves meeting certain criteria, such as having a permanent and significant disability. Stroke survivors may qualify for support if their condition affects functional capacity in daily activities. Our knowledgeable staff can assist with understanding your eligibility and creating a plan tailored to your specific rehabilitation needs.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Programs</h1>
                    <p>Every stroke survivor's journey is unique, which is why our physiotherapy programs are customized to meet the individual needs of each client. We integrate a variety of therapeutic techniques, including manual therapy, exercise prescription, and functional training, to address the challenges posed by stroke.</p>
                    <p>Our physiotherapists are trained in neuroplasticity and motor relearning principles, which are essential for stroke rehabilitation. We focus on helping you regain movement, strength, and coordination, with the goal of enhancing your ability to perform daily tasks and improve overall well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Recovery Potential</h1>
                    <p>Our goal is to maximize your recovery potential by employing the latest research and innovative treatments. We understand that recovery from a stroke is not linear and requires patience and persistence. Our team is committed to providing support and encouragement throughout your rehabilitation journey.</p>
                    <p>We utilize a combination of hands-on therapy and technology-assisted exercises to stimulate neuroplasticity and promote recovery. Our gym is equipped with specialized equipment that caters to the needs of stroke survivors, enabling safe and effective workouts.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Independence and Mobility</h1>
                        <p>Our physiotherapy services for stroke survivors extend beyond the clinic. We empower you to continue your exercises at home with a personalized home exercise program. This ensures continuity of care and maximizes your functional independence and mobility.</p>
                        <p>Our therapists also provide education on lifestyle modifications and adaptive strategies to overcome daily challenges. By fostering self-management skills, we help you take control of your recovery and maintain progress outside of the clinical setting.</p>
                        <br></br>
                        <p>
                            At Ramp Physio and Fitness, we are committed to helping you rebuild your life after a stroke. Our expert team, cutting-edge facilities, and compassionate care provide the foundation for a successful recovery journey. Contact us to learn more about how our NDIS physiotherapy services can support your stroke rehabilitation goals.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForStroke;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const AdaptingGymWorkoutsForHomeExercise = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Home Workouts with Ease</h1>
                    <h2>From gym routines to home exercises, Ramp Physio and Fitness guides you in adapting your workouts seamlessly for effective fitness results.</h2>
                    <p>Transitioning from gym workouts to home exercises can be challenging, but with the right guidance and strategies, it's entirely possible to maintain or even enhance your fitness levels. In this comprehensive article, Ramp Physio and Fitness will walk you through adapting your gym routines for effective home exercise, ensuring you stay on track with your health and fitness goals, regardless of your setting.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Creating a Home Gym Space</h1>
                        <p>Setting up a dedicated space for your home workouts is the first step towards success. You don't need expensive equipment; with a few essentials and some creativity, you can create an effective workout area. Here are ten key items to consider:</p>
                        <ul>
                            <li>Resistance bands for strength training</li>
                            <li>An exercise mat for floor exercises and stability work</li>
                            <li>Dumbbells or kettlebells for added resistance</li>
                            <li>A sturdy chair or bench for elevated exercises</li>
                            <li>An exercise ball for core workouts and balance training</li>
                            <li>A jump rope for cardiovascular fitness</li>
                            <li>Free online workout videos for guidance</li>
                            <li>A mirror to check your form</li>
                            <li>Good lighting and ventilation</li>
                            <li>Speakers or headphones for motivational music</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Adapting Gym Exercises</h1>
                    <p>Many gym exercises can be modified for home workouts without losing their effectiveness. Focus on these six key themes to adapt your workouts:</p>
                    <ul>
                        <li>Bodyweight exercises for strength and endurance</li>
                        <li>High-Intensity Interval Training (HIIT) for fat loss and cardio</li>
                        <li>Specific designed programs for flexibility and core strength</li>
                        <li>Circuit training for full-body workouts</li>
                        <li>Balance and coordination drills for functional fitness</li>
                        <li>Recovery techniques, such as stretching and foam rolling</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Home Workout Benefits</h1>
                    <p>By adapting your gym workouts for home, you can enjoy a range of benefits that go beyond convenience. These include:</p>
                    <ul>
                        <li>Flexibility to exercise at any time</li>
                        <li>Saving time and money on gym commutes</li>
                        <li>Personalized workout environment</li>
                        <li>Privacy to try new exercises without self-consciousness</li>
                        <li>Ability to focus on personal fitness goals</li>
                        <li>Reduced risk of exposure to illness</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Staying Motivated at Home</h1>
                    <p>Maintaining motivation can be tough when working out at home. Here are tips to keep you inspired:</p>
                    <ul>
                        <li>Set clear, achievable fitness goals</li>
                        <li>Track your progress with a workout journal</li>
                        <li>Create a workout schedule and stick to it</li>
                        <li>Join online fitness communities for support</li>
                        <li>Challenge yourself with new exercises</li>
                        <li>Reward yourself for milestones achieved</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default AdaptingGymWorkoutsForHomeExercise;
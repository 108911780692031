import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Ndisphysiotherapyforhuntingtonsdisease = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Huntington's Disease - Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness provides specialized NDIS physiotherapy services for individuals with Huntington's Disease, helping them enhance their mobility and quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Physiotherapy for Huntington's Disease</h1>
                    <h2>Our expert physiotherapy services at Ramp Physio and Fitness are designed to support individuals with Huntington's Disease through tailored NDIS-funded programs.</h2>
                    <p>Huntington's Disease is a challenging neurological condition that affects movement, cognitive functions, and emotions. At Ramp Physio and Fitness, we are dedicated to providing compassionate and comprehensive NDIS physiotherapy to support individuals with Huntington's Disease. Our programs are tailored to enhance mobility, manage symptoms, and improve the overall quality of life for our clients. In this extensive guide, we will discuss the benefits of physiotherapy for Huntington's Disease and how our specialized services can make a difference.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Huntington's Disease</h1>
                        <p>Huntington's Disease is a genetic disorder that progressively deteriorates a person's physical and mental abilities. Symptoms typically appear between the ages of 30 and 50 and can include uncontrolled movements, cognitive decline, and emotional disturbances. NDIS physiotherapy plays a critical role in managing these symptoms and maintaining independence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized NDIS Physiotherapy Plans</h1>
                    <p>Every individual with Huntington's Disease experiences a unique set of challenges. Our NDIS physiotherapy services at Ramp Physio and Fitness start with a thorough assessment to develop a personalized treatment plan. This plan focuses on the specific needs of the individual, incorporating exercises and interventions that target symptom management and functional improvement.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        NDIS Support for Optimal Care
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Strength</h1>
                    <p>Our physiotherapists employ various techniques to improve mobility and strength in individuals with Huntington's Disease. By focusing on flexibility, balance, and coordination, we aim to reduce the risk of falls and maintain as much independence as possible. Strength training is also a key component of our program, helping to support weakened muscles and joints.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supporting Daily Living Activities</h1>
                        <p>Our NDIS physiotherapy for Huntington's Disease also focuses on supporting daily living activities. We work closely with individuals to develop strategies that simplify tasks, enhance safety, and promote independence. Our interventions may include adaptive equipment recommendations and environmental modifications to support a better quality of life.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforhuntingtonsdisease;
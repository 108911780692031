import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Seasonalfitnessadjustingyourworkoutroutinethroughouttheyear = () => {
    return (
        <div>
            <Helmet>
                <title>Seasonal Fitness: Adjusting Your Workout Routine Throughout the Year</title>
                <meta name="description" content="Learn how to adapt your fitness routine to the changing seasons with Ramp Physio and Fitness. Stay motivated and achieve your goals all year round." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Embrace the Change: Seasonal Fitness Strategies</h1>
                    <h2>Master the art of seasonal fitness with Ramp Physio and Fitness, your guide to adjusting your workout routine throughout the year in Homebush, NSW.</h2>
                    <p>As the seasons change, so do our daily routines, energy levels, and even our workout preferences. At Ramp Physio and Fitness, we understand the importance of adapting your fitness regimen to suit the changing weather and your personal goals. In this comprehensive guide, we'll explore how to tweak your workout routine as the seasons roll by, ensuring you stay on track with your health and fitness objectives no matter the time of year.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Seasonal Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Winter Workouts: Stay Warm and Active</h1>
                        <ul>
                            <li>Embrace indoor activities like RAMP personal training, HIIT and cardio workouts</li>
                            <li>Incorporate resistance training to build strength.</li>
                            <li>Try out high-intensity interval training (HIIT) indoors.</li>
                            <li>Stay motivated with group classes or virtual challenges.</li>
                            <li>Focus on flexibility with warm-up and cool-down routines.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Spring Renewal: Refresh Your Routine</h1>
                    <ul>
                            <li>Take your workouts outdoors to enjoy the fresh air.</li>
                            <li>Increase cardiovascular activities with cycling or running.</li>
                            <li>Join a sports league or outdoor fitness group.</li>
                            <li>Start training for summer events or competitions.</li>
                            <li>Use the natural terrain for bodyweight exercises.</li>
                        </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Summer Strategies: Beat the Heat</h1>
                    <ul>
                        <li>Opt for swimming or water aerobics to stay cool.</li>
                        <li>Choose early morning or late evening for outdoor workouts.</li>
                        <li>Stay hydrated and protect your skin with sunscreen.</li>
                        <li>Experiment with new indoor gym classes to stay motivated.</li>
                        <li>Focus on core strength and stability with Pilates or barre.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Autumn Adaptations: Enjoy the Outdoors</h1>
                        <ul>
                            <li>Explore hiking or trail running to enjoy the foliage.</li>
                            <li>Use the cooler weather to increase workout intensity.</li>
                            <li>Prepare for the indoors with a home workout space.</li>
                            <li>Engage in seasonal sports like football or soccer.</li>
                            <li>Focus on balance and agility with functional training.</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Year-Round Fitness: Consistency is Key</h1>
                    <p>Adapting your workout routine to the seasons doesn’t mean starting from scratch every few months. It’s about making small adjustments to maintain consistency and motivation throughout the year. At Ramp Physio and Fitness, we can help you craft a personalized fitness plan that evolves with the seasons while keeping your long-term goals in sight.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Seasonalfitnessadjustingyourworkoutroutinethroughouttheyear;
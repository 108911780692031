import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const MentalHealth = () => {
  const articles = [
    { title: 'NDIS Physiotherapy for Anxiety', path: '/news/ndisphysiotherapyforanxiety' },
    { title: 'NDIS Physiotherapy for Bipolar Disorder', path: '/news/ndisphysiotherapyforbipolardisorder' },
    { title: 'NDIS Physiotherapy for Depression', path: '/news/ndisphysiotherapyfordepression' },
    { title: 'NDIS Physiotherapy for Disordered Eating', path: '/news/ndisphysiotherapyfordisorderedeating' },
    { title: 'NDIS Physiotherapy for Mental Health Conditions', path: '/news/ndisphysiotherapyformentalhealthconditions' },
    { title: 'NDIS Physiotherapy for Personality Disorders', path: '/news/ndisphysiotherapyforpersonalitydisorders' },
    { title: 'NDIS Physiotherapy for Psychotic Illnesses', path: '/news/ndisphysiotherapyforpsychoticillnesses' },
    { title: 'NDIS Physiotherapy for PTSD', path: '/news/ndisphysiotherapyforptsd' },
    { title: 'NDIS Physiotherapy for Schizophrenia', path: '/news/ndisphysiotherapyforschizophrenia' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
<h2>NDIS Physiotherapy for Mental Health</h2>
<p>
  <strong>Stay informed with our latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
</p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MentalHealth;

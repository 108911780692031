import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForSpinalCordInjuries = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Spinal Cord Injuries | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for spinal cord injuries at Ramp Physio and Fitness. Achieve your rehabilitation goals with our dedicated team in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Rehabilitation for Spinal Cord Injuries</h1>
                    <h2>Explore the transformative impact of specialized physiotherapy on spinal cord injury recovery at Ramp Physio and Fitness, where we foster resilience and independence.</h2>
                    <p>Spinal cord injuries (SCI) can be life-altering, but with the right support, recovery and adaptation are possible. At Ramp Physio and Fitness, we provide expert physiotherapy services tailored to the needs of individuals with SCI. Through evidence-based treatments and innovative rehabilitation strategies, our team is dedicated to helping you navigate the challenges of SCI and reclaim your mobility and quality of life. In this comprehensive guide, we'll discuss the essential aspects of physiotherapy for spinal cord injuries and how our approach can make a significant difference in your journey to recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding SCI and Physiotherapy</h1>
                        <p>Spinal cord injuries result in a disruption of the neural pathways that affect sensation, strength, and function below the injury level. Physiotherapy plays a vital role in SCI rehabilitation, helping to maximize recovery through targeted exercises, mobility training, and education. Our physiotherapists at Ramp Physio and Fitness are well-versed in the complexities of SCI and are equipped with the skills to address the unique challenges each individual faces.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Therapy Programs</h1>
                    <p>Every spinal cord injury is different, and so is every recovery journey. Our physiotherapists create personalized therapy programs designed to align with your specific goals and capabilities. These programs may include strength training, neuromuscular re-education, manual therapy, and the use of assistive devices, all aimed at fostering independence and improving your overall quality of life.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Mobility and Strength</h1>
                    <p>Regaining mobility and strength is a cornerstone of SCI physiotherapy. Our programs emphasize exercises that enhance muscle performance, prevent atrophy, and improve cardiovascular health. We also focus on teaching transfer skills and wheelchair mobility to enhance independence in daily activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Innovative Treatment Approaches</h1>
                        <p>We stay at the forefront of SCI physiotherapy by incorporating innovative treatment approaches like activity-based therapy, functional electrical stimulation, and hydrotherapy. These treatments are designed to activate the nervous system, promote neural plasticity, and facilitate functional improvements.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Support Beyond the Clinic</h1>
                    <p>Recovery from an SCI extends beyond the walls of our clinic. Our team provides education on lifestyle modifications, community resources, and adaptive sports to support your long-term health and well-being. We also offer guidance on navigating life with an SCI, including strategies for preventing complications and maintaining an active lifestyle.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Empowerment Through Education</h1>
                    <p>Education is a powerful tool in SCI rehabilitation. Our physiotherapists provide comprehensive education on injury prevention, skin care, pain management, and other crucial aspects of living with an SCI. By empowering you with knowledge, we aim to enhance your autonomy and participation in the rehabilitation process.</p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Collaborative Care and Support</h1>
                    <p>At Ramp Physio and Fitness, we believe in a collaborative approach to care. We work closely with other healthcare professionals, caregivers, and family members to ensure a supportive environment for your rehabilitation. Our team is committed to providing compassionate care and ongoing support throughout your recovery journey.</p>
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForSpinalCordInjuries;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import paediatricPhysioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForPaediatricPhysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Paediatric Physiotherapy & Exercises | Ramp Physio and Fitness</title>
                <meta name="description" content="Explore specialized paediatric physiotherapy treatments and exercises designed to support the unique needs of children at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Young Bodies: Paediatric Physiotherapy</h1>
                    <h2>Discover how Ramp Physio and Fitness nurtures youthful vitality through tailored physiotherapy treatments and exercises for children.</h2>
                    <p>Children are not just small adults; their growing bodies require specialized care. At Ramp Physio and Fitness, we understand the nuances of paediatric physiotherapy and its pivotal role in promoting healthy development. Our expert team is dedicated to providing compassionate and effective treatment strategies that cater to the unique needs of children. Join us as we delve into the world of paediatric physiotherapy and share how our exercises and treatments can support your child's growth and well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Paediatric Physiotherapy</h1>
                        <p>Paediatric physiotherapy encompasses a range of treatments and exercises specifically designed for infants, toddlers, children, and adolescents. It aims to improve physical function, enhance motor development, and address various musculoskeletal conditions. With a focus on play-based therapy and family involvement, our physiotherapists make each session engaging and beneficial.</p>

                        <p>At Ramp Physio and Fitness, we begin with a thorough assessment to understand the child's physical challenges and goals. We then create a custom treatment plan that may include strength training, coordination exercises, balance activities, and functional movement patterns.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Tailored Treatments for Varied Conditions</h1>
                        <p>Our paediatric physiotherapy services cater to a wide array of conditions including developmental delays, cerebral palsy, Down syndrome, muscular dystrophy, and postural issues. We utilize evidence-based interventions to maximize each child's potential for movement and independence.</p>

                        <p>Strength and flexibility exercises, neuromuscular training, and sensory integration techniques are just a few examples of how we address these conditions. Our physiotherapists are also skilled in providing post-operative care and support for sports-related injuries in children.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={paediatricPhysioLogo} alt="Paediatric Physiotherapy Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Developmental Milestones and Movement</h1>
                    <p>Reaching developmental milestones is a critical aspect of a child's growth. Our physiotherapy programs are designed to support these milestones through movement and exercise. We encourage activities that promote crawling, walking, jumping, and other fundamental motor skills.</p>

                    <p>By integrating play and fun into our sessions, we help children develop confidence in their physical abilities while fostering a love for active living.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Engaging Exercises for Stronger Kids</h1>
                    <p>Exercise is a cornerstone of paediatric physiotherapy. Our programs include a variety of engaging and age-appropriate exercises to build strength, improve coordination, and increase endurance. We focus on activities that children enjoy, ensuring they stay motivated and committed to their treatment plan.</p>

                    <p>Examples of exercises we may incorporate include obstacle courses, ball games, balance beams, and resistance activities using bands or light weights. These exercises not only enhance physical capabilities but also contribute to better social skills and emotional well-being.</p>

                    <br></br>
                    <p>
                        In conclusion, paediatric physiotherapy at Ramp Physio and Fitness is a journey of growth, empowerment, and joy. Our dedicated team is committed to providing the highest quality care, tailored to the unique needs of each child. Through our expert guidance and supportive environment, we help children reach their full potential and set the foundation for a healthy, active life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForPaediatricPhysiotherapy;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const MindBodyconnection = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empower Your Wellness Journey</h1>
                    <h2>Uncover the powerful connection between mind and body with our physiotherapy and exercise programs, tailored to boost your mental health and physical fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that mental health is just as important as physical fitness. Our services are designed to not only improve your physical capabilities but also to enhance your mental well-being. In this comprehensive guide, we'll explore the intricate relationship between the mind and body, and how our physiotherapy and exercise regimes can play a pivotal role in maintaining and improving mental health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Mind-Body Connection" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Physiotherapy: A Mental Boost</h1>
                        <p>Physiotherapy goes beyond physical rehabilitation; it's a gateway to improved mental health. Our evidence-based techniques help alleviate stress, anxiety, and depression by promoting relaxation, enhancing mood, and improving sleep quality. The therapeutic exercises, mindfulness practices, and one-on-one support form a holistic approach to tackle both physical and psychological challenges.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise: Your Mental Ally</h1>
                    <p>Regular physical activity is a proven antidepressant. At Ramp Physio and Fitness, our exercise programs are not just about building muscle and endurance; they're about crafting a resilient and happy mind. Engaging in our personalized workouts can lead to the release of endorphins, the body's natural mood lifters, and can be as effective as medication for some individuals with mild to moderate depression.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding the Mind-Body Link</h1>
                    <p>Scientific research continues to unravel the complex connections between the brain and the body. Physical activity and physiotherapy interventions have been shown to directly impact the central nervous system, influencing neurotransmitters and neural pathways associated with mood and cognition. This understanding forms the basis of our programs that integrate mind-body techniques to foster both physical and mental resilience.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Our Mindful Movement</h1>
                    <p>Participate in our group fitness classes where the energy of the group amplifies your motivation and elevates your spirit. The social interaction and shared experience of exercise not only strengthen the body but also foster a sense of community and belonging, which are key components of mental health. Our classes are designed to be inclusive and empowering, welcoming individuals from all walks of life to join in the collective pursuit of holistic well-being.</p>
                    <br></br>
                    <p>
                        To sum up, at Ramp Physio and Fitness, we are committed to your comprehensive health. We invite you to experience the transformative power of the mind-body connection through our specialized physiotherapy and exercise programs. Whether you're seeking to overcome mental health challenges or simply looking to enhance your overall well-being, we are here to support you on your journey to a healthier, happier life. Let's strengthen the connection between your mind and body together.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default MindBodyconnection;
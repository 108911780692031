import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforscheuermannsdisease = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for Scheuermann's Disease</h1>
                    <h2>Specialized care for spinal health at Ramp Physio and Fitness, empowering individuals with Scheuermann's Disease through personalized physiotherapy programs.</h2>
                    <p>Scheuermann's Disease, a condition that affects the spine during growth, can lead to pain and discomfort if not managed properly. At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy programs tailored to the unique needs of those living with Scheuermann's Disease. Our aim is to alleviate pain, improve posture, and enhance overall quality of life. In this in-depth look, we explore the benefits of physiotherapy for Scheuermann's Disease and how our expert team can help you manage this condition effectively.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Scheuermann's Disease</h1>
                        <p>Scheuermann's Disease is a developmental disorder that causes abnormal growth of the spinal vertebrae, leading to increased kyphosis (forward curvature of the spine). It typically presents during adolescence and can result in pain, stiffness, and cosmetic concerns. The exact cause of the condition is not well understood, but it's thought to be related to genetics, mechanical factors, and variations in growth rates of the spinal segments. Recognizing the signs and symptoms early can lead to more effective management of the condition.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Management Strategies</h1>
                    <p>Effective management of Scheuermann's Disease involves a multi-faceted approach, including physiotherapy interventions aimed at reducing pain, improving flexibility, and strengthening the muscles that support the spine. Our treatment plan may include:
                        <ul>
                            <li>Exercise therapy to enhance spinal mobility and strength</li>
                            <li>Postural education to promote optimal spinal alignment</li>
                            <li>Manual therapy techniques to relieve pain and stiffness</li>
                            <li>Bracing in conjunction with physiotherapy for severe cases</li>
                            <li>Pain management strategies including heat or cold therapy</li>
                            <li>Guidance on activity modification to avoid aggravation</li>
                            <li>Ergonomic advice for daily activities and work setups</li>
                            <li>Core stability exercises to support the spine</li>
                            <li>Strategies for long-term management and prevention of progression</li>
                            <li>Referral to other health professionals if necessary</li>
                        </ul>
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Exercise in Treatment</h1>
                    <p>Exercise is a cornerstone of treating Scheuermann's Disease. Our physiotherapists design individualized exercise programs that focus on:
                        <ul>
                            <li>Stretching tight muscles to improve flexibility</li>
                            <li>Strengthening exercises targeting the back, abdominal, and leg muscles</li>
                            <li>Low-impact aerobic activities to promote overall fitness</li>
                            <li>Breathing exercises to aid in relaxation and pain management</li>
                            <li>Balancing exercises to improve proprioception and spinal stability</li>
                            <li>Functional training to ensure safe movement patterns in daily life</li>
                        </ul>
                        These exercises not only help manage current symptoms but also contribute to the prevention of further complications associated with the disease.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Long-Term Health and Spinal Care</h1>
                    <p>Managing Scheuermann's Disease is a lifelong process. At Ramp Physio and Fitness, we are committed to helping you understand your condition and provide you with the tools necessary for long-term management. Our approach includes:
                        <ul>
                            <li>Regular follow-up appointments to monitor progress</li>
                            <li>Education on the importance of maintaining a healthy lifestyle</li>
                            <li>Support in developing a sustainable exercise routine</li>
                            <li>Advice on how to minimize risks in sports and other activities</li>
                            <li>Assistance in setting realistic goals and expectations for spinal health</li>
                        </ul>
                        By partnering with us, you'll gain the knowledge and confidence to take control of your health and lead an active, fulfilling life despite Scheuermann's Disease.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforscheuermannsdisease;
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Move NewsColumn outside of SubpagesLink
const NewsColumn = ({ items }) => (
  <div className="news-column">
    {items.map((item, index) => (
      <React.Fragment key={item.path}>
        <div className="news-item">
          <Link to={item.path}>
            <p>{item.title}</p>
          </Link>
        </div>
        {index < items.length - 1 && (
          <div className="news-item-separator">
            <p>•</p>
          </div>
        )}
      </React.Fragment>
    ))}
  </div>
);

const SubpagesLink = () => {
  const newsItems = {
    column1: [
      { path: '/services', title: 'Services' },
      { path: '/news/understandingphysiotherapy', title: 'Understanding Physiotherapy' },
      { path: '/news/physiotherapyforspecificconditions', title: 'Physiotherapy for Specific Conditions' },
    ],
    column2: [
      { path: '/news/rehabilitation', title: 'Rehabilitation' },
      { path: '/news/gymknowledge', title: 'Gym Knowledge Base' },
      { path: '/news/benefitsofgymtraining', title: 'Benefits of Gym Training' },
    ]
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <Link to="/news">
          <h2>Physiotherapy & Gym Training Knowledge</h2>
        </Link>
        <p>
          <strong>Stay informed with the latest news, insights, and updates from Ramp Physio and Fitness.</strong> We are dedicated to keeping you in the loop with our newest services, innovative treatment methods, and exclusive fitness programs designed to enhance your well-being. Whether you're seeking expert health tips, guidance on rehabilitation, or information about upcoming community events, you'll find a wealth of resources here. <strong>Explore our articles, join our discussions, and connect with our team to stay empowered on your journey to optimal health and fitness.</strong>
        </p>
      </div>
      
      <div className="news-content">
        <NewsColumn items={newsItems.column1} />
        <NewsColumn items={newsItems.column2} />
      </div>

      <div className="news-section booking-section">
        <a 
          href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
          target="_blank"
          rel="noopener noreferrer"
          className="servicedetail-book-now"
        >
          Book Now
        </a>
      </div>
    </div>
  );
};

NewsColumn.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SubpagesLink;
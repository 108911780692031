import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForHipPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Hip Pain through Physiotherapy</h1>
                    <h2>Discover effective physiotherapy treatments and exercises for hip pain at Ramp Physio and Fitness.</h2>
                    <p>Hip pain can significantly affect your quality of life by limiting your mobility and causing discomfort during daily activities. At Ramp Physio and Fitness, our physiotherapists specialize in providing targeted treatments and exercises designed to alleviate hip pain and improve your physical function. In this guide, we'll delve into the strategies and techniques that can help you overcome hip pain and regain your active lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Hip Pain Causes</h1>
                        <p>Hip pain can stem from various causes, including arthritis, bursitis, muscle strains, and more. Identifying the root cause of your hip pain is essential for crafting an effective treatment plan. Our physiotherapists conduct thorough assessments to pinpoint the source of discomfort and tailor a rehabilitation program that addresses your specific needs.</p>
                        <p>Our initial evaluation may include range of motion tests, strength assessments, and functional movement analyses to ensure a comprehensive understanding of your condition. This allows us to develop a targeted approach to manage and relieve your hip pain effectively.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Individualized Physiotherapy Programs</h1>
                    <p>At Ramp Physio and Fitness, we believe in personalized care. Each patient receives a custom-tailored physiotherapy program that incorporates a variety of treatments and exercises. Our programs often include manual therapy, such as joint mobilizations and soft tissue techniques, to alleviate pain and improve joint function.</p>
                    <p>Therapeutic exercises play a critical role in hip pain rehabilitation. These exercises are designed to strengthen the muscles around the hip, enhance flexibility, and increase range of motion. Our physiotherapists will guide you through a progression of exercises that are suited to your stage of recovery and pain tolerance.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategies for Reducing Inflammation</h1>
                    <p>Inflammation is a common contributor to hip pain. Our physiotherapists employ strategies that help reduce inflammation, such as cryotherapy, heat therapy, and non-invasive modalities like ultrasound or electrical stimulation. These techniques can provide immediate pain relief and support the body's natural healing processes.</p>
                    <p>We also provide guidance on activity modification and ergonomic adjustments that can help minimize stress on the hip joint during everyday tasks and work-related activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise Examples for Hip Strength</h1>
                    <p>Below are ten examples of exercises that may be included in your physiotherapy program to help strengthen the hip:
                        <br></br>- Bridging
                        <br></br>- Clamshells
                        <br></br>- Hip abduction
                        <br></br>- Squats
                        <br></br>- Lunges
                        <br></br>- Step-ups
                        <br></br>- Leg lifts
                        <br></br>- Hip extensions
                        <br></br>- Pelvic tilts
                        <br></br>- Deadlifts
                    </p>
                    <p>Our physiotherapists will demonstrate these exercises and ensure you perform them correctly to maximize their benefits and prevent further injury. As you progress, the intensity and complexity of the exercises can be adjusted to continue challenging your muscles and promoting recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForHipPain;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforrunnerskneepatellofemoralpainsyndrome = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Runner's Knee</h1>
                    <h2>Unlock the path to recovery from Patellofemoral Pain Syndrome with Ramp Physio and Fitness, your guide to conquering knee pain and reclaiming your stride.</h2>
                    <p>Runner's Knee, medically known as Patellofemoral Pain Syndrome (PFPS), is a common ailment affecting both novice and seasoned athletes. Ramp Physio and Fitness is dedicated to providing expert physiotherapy tailored to address the unique challenges of Runner's Knee. Through a combination of evidence-based treatment plans, strength exercises, and personalized care, our team is committed to helping you overcome pain and return to the activities you love. This article will explore how our specialized approach can assist in your journey to recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Runner's Knee</h1>
                        <p>Patellofemoral Pain Syndrome presents as pain around or behind the kneecap, often exacerbated by activities such as running, squatting, and ascending stairs. Understanding the biomechanics of the knee, the role of muscle imbalances, and the impact of training errors is crucial in addressing this condition. Our physiotherapists at Ramp Physio and Fitness employ a comprehensive assessment to identify the contributing factors to your pain, paving the way for a targeted treatment strategy.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Treatment Strategies</h1>
                    <p>Our approach to treating Runner's Knee is multifaceted, involving a blend of manual therapy, therapeutic exercises, and patient education. We prioritize treatments that are supported by clinical evidence, ensuring that you receive the most effective care possible. Here are 10 key components of our treatment plan:</p>
                    <ul>
                        <li>Individualized assessment and diagnosis</li>
                        <li>Manual therapy techniques</li>
                        <li>Strength training exercises</li>
                        <li>Flexibility and mobility work</li>
                        <li>Gait analysis and correction</li>
                        <li>Load management strategies</li>
                        <li>Patient education on activity modification</li>
                        <li>Use of taping or bracing if necessary</li>
                        <li>Development of a home exercise program</li>
                        <li>Guidance on prevention and future injury reduction</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strengthening for Stability</h1>
                    <p>Strength training is a cornerstone of PFPS rehabilitation. Focusing on the quadriceps, hamstrings, glutes, and core, these exercises aim to create a stable foundation for the knee. By improving muscle strength and balance, we can alleviate stress on the patellofemoral joint, reducing pain and enhancing function. Our physiotherapists will guide you through a personalized strength program, ensuring proper technique and progression to maximize your recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Restoring Function and Mobility</h1>
                    <p>At Ramp Physio and Fitness, we recognize that each individual's journey with Runner's Knee is unique. Our treatment extends beyond pain relief, aiming to restore your full range of motion and functional mobility. Through a combination of hands-on therapy and tailored exercises, we assist you in regaining the confidence and ability to perform daily activities and enjoy your favorite sports without limitation.</p>
                    <br></br>
                    <p>
                        In summary, overcoming Runner's Knee requires a comprehensive and patient-centered approach. At Ramp Physio and Fitness, we are dedicated to providing you with the highest quality of care to ensure a successful recovery. By combining expert knowledge, personalized treatment plans, and a commitment to your well-being, we strive to help you return to pain-free running and an active lifestyle. Book your appointment today and take the first step towards a healthier, stronger you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforrunnerskneepatellofemoralpainsyndrome;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Muscle Recovery with EMS Technology</h1>
                    <h2>Discover the innovative approach of Electrical Muscle Stimulation (EMS) for muscle recovery and rehabilitation, a game-changer in physiotherapy and fitness at Ramp Physio and Fitness.</h2>        <p>At Ramp Physio and Fitness, we are dedicated to providing cutting-edge solutions for sports injury management, physiotherapy, and personalized training. One of the revolutionary tools in our arsenal is Electrical Muscle Stimulation (EMS), a technology that has transformed how we approach muscle recovery and rehabilitation. This article delves into the science behind EMS, its effectiveness for muscle recovery, and how it can be integrated into your treatment plan to optimize outcomes and enhance overall physical health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding EMS Technology</h1>
                        <p>Electrical Muscle Stimulation (EMS) is a therapeutic tool that uses electrical impulses to cause muscle contractions, mimicking the body's natural muscle movements. This technology has been extensively researched and is backed by a wealth of scientific evidence, highlighting its benefits for muscle conditioning, strengthening, and recovery. The University of Queensland's RECOVER Injury Research Centre offers insights into how EMS can be utilized as part of a comprehensive treatment strategy, particularly for individuals recovering from injuries or those with limited mobility.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Science of Muscle Recovery</h1>
                    <p>Muscle recovery is a critical aspect of any fitness or rehabilitation program. The process involves repair, strengthening, and adaptation of muscle tissue after exercise or injury. Studies published by the National Center for Biotechnology Information (NCBI) provide evidence that EMS can significantly enhance muscle recovery by increasing blood flow, reducing inflammation, and promoting tissue repair. By understanding the underlying mechanisms of muscle recovery, we can better appreciate how EMS can be a valuable component of a holistic recovery plan.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>EMS in Rehabilitation and Training</h1>
                    <p>Utilizing EMS in rehabilitation and training is a practice that is gaining widespread acceptance among healthcare professionals and fitness experts. Caremax, a leader in pain management solutions, outlines the use of EMS machines for muscle stimulation, demonstrating their effectiveness in not only alleviating pain but also in improving muscle function and performance. At Ramp Physio and Fitness, we integrate EMS into our clients' rehabilitation and training programs, tailoring the treatment to individual needs and goals to ensure optimal results.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advancing Recovery with EMS</h1>
                    <p>Advancements in recovery modalities have made EMS a go-to option for athletes and patients seeking to enhance their recovery process. Recovery Lab, an authority in recovery techniques, emphasizes the role of devices like Compex in providing targeted EMS therapy. These devices are designed to cater to various recovery and training requirements, from pain relief to muscle endurance and strength building. By incorporating such technologies into our services, Ramp Physio and Fitness remains at the forefront of innovative and effective recovery solutions.</p>
                    <br></br>
                    <p>
                        Electrical Muscle Stimulation (EMS) stands as a testament to the evolution of physiotherapy and fitness, offering a scientifically-backed method for improving muscle recovery and function. At Ramp Physio and Fitness, we are committed to harnessing the potential of EMS to assist our clients in achieving their health and fitness objectives. Whether you are recovering from a sports injury, managing chronic pain, or seeking to elevate your training, our tailored EMS protocols can play a pivotal role in your journey to optimal physical well-being.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;

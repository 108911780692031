import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import backPainLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForBackPain = () => {
    return (
        <div>
            <Helmet>
                <title>Effective Physiotherapy for Back Pain Relief | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the best physiotherapy treatment and exercises for back pain relief with Ramp Physio and Fitness. Achieve a pain-free life today." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Back Pain Relief Through Physiotherapy</h1>
                    <h2>Expert Physiotherapy Treatment and Exercises for Back Pain at Ramp Physio and Fitness</h2>
                    <p>Back pain is a prevalent issue that affects individuals of all ages and lifestyles. At Ramp Physio and Fitness, we specialize in providing targeted physiotherapy treatment and exercises to alleviate back pain and improve your quality of life. Our comprehensive approach to back pain relief is designed to address the root cause of your discomfort and promote long-term health and mobility.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Back Pain Dynamics</h1>
                        <p>Back pain can stem from various sources, including muscle strains, disc injuries, and degenerative conditions. Our physiotherapists at Ramp Physio and Fitness begin with a thorough assessment to identify the underlying cause of your pain and develop a customized treatment plan tailored to your specific needs.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Treatment Plans</h1>
                        <p>Each individual's experience with back pain is unique, which is why we offer personalized treatment plans. Our physiotherapists employ a range of techniques, including manual therapy, soft tissue mobilization, and therapeutic modalities, to alleviate pain and facilitate healing.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={backPainLogo} alt="Back Pain Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Core Strengthening and Stability</h1>
                    <p>Core strength is vital for supporting the spine and reducing back pain. Our physiotherapists guide you through specific exercises aimed at strengthening the core muscles, improving posture, and enhancing stability to prevent future episodes of back pain.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Exercise-Based Rehabilitation</h1>
                        <p>Exercise is a cornerstone of back pain rehabilitation. Our approach includes a combination of stretching, strengthening, and low-impact aerobic exercises to improve flexibility, muscle endurance, and cardiovascular health, all of which contribute to back pain relief.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Ergonomic and Lifestyle Advice</h1>
                        <p>Back pain is often exacerbated by poor ergonomics and lifestyle habits. We provide practical advice on how to optimize your work and home environments to support your spine and reduce the risk of pain recurrence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/physiotherapy.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Hands-On Manual Therapy</h1>
                    <p>Our physiotherapists are skilled in various manual therapy techniques that can help provide immediate relief from back pain, improve range of motion, and facilitate the body's natural healing processes.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/treadmill.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/strengthtraining.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Progress Monitoring and Support</h1>
                        <p>Recovery from back pain is an ongoing process. We continuously monitor your progress and adjust your treatment plan as needed to ensure you are on the fastest path to relief and recovery.</p>
                    </div>
                </div>
            </SkewedContainer>

            <SubpagesLink />
            
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForBackPain;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Harnessing the Power of Physical Therapy for Pain Relief</h1>
                    <h2>Explore the transformative role of physical therapy exercises in managing pain and enhancing mobility, with evidence-based techniques tailored to individual needs at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that pain is a complex experience and that each individual's journey to recovery is unique. As a dedicated physiotherapist, my role is to guide you through a tailored program of physical therapy exercises designed to alleviate discomfort, restore function, and improve your quality of life. This article delves into the foundational principles of physical therapy and highlights key exercises that can empower you to take control of your pain and embark on a path to wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Physical Therapy</h1>
                        <p>Physical therapy is a dynamic profession that specializes in the prevention, diagnosis, and treatment of movement dysfunctions. At its core, physical therapy exercises are meticulously crafted to address specific needs, ranging from sports injury recovery to chronic pain management. By engaging in these exercises, patients can expect not just symptomatic relief but also a long-term enhancement of their physical capabilities. The approach is holistic, considering the intricate interplay between different body systems to achieve optimal health and performance.</p>
                        <p>Our Physiotherapists are DVA, Medicare, NDIS and Work Cover approved, specialising in the correct injury management and rehabilitation protocols to get you pain free and back on track.</p>
                        <p>As we dive into the world of physical therapy, it's essential to recognize that these exercises are more than just a set of movements; they are a form of communication between the therapist and the patient, designed to rebuild strength, flexibility, and endurance. In doing so, the body's natural healing processes are supported, and the risk of future injuries is mitigated.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strategies for Managing Pain</h1>
                    <p>Pain management is a critical component of physical therapy, and exercises play a pivotal role in this process. By utilizing a combination of techniques such as stretching, strengthening, and proprioceptive training, physical therapists can create a customized plan that targets the root cause of pain. These exercises are not only aimed at alleviating symptoms but also at teaching the body new patterns of movement that can prevent the recurrence of pain.</p>
                    <p>For individuals with chronic conditions, physical therapy exercises provide a path to manage their symptoms and improve daily functioning. The goal is to empower patients with self-management strategies that they can incorporate into their everyday lives, reducing their dependence on medication and invasive procedures.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Function</h1>
                    <p>Improving mobility and function is at the heart of physical therapy. Through a series of exercises tailored to each individual's condition and goals, patients can experience significant improvements in their range of motion, balance, and overall physical performance. These exercises are designed to challenge the body in a safe and controlled manner, gradually building up to more complex movements as the patient's condition improves.</p>
                    <p>The emphasis on proper technique and progression ensures that each patient moves at a pace that is appropriate for their level of recovery. By focusing on functional exercises, physical therapy helps patients not only to recover from their current ailments but also to develop the strength and flexibility needed to engage in their favorite activities with confidence and ease.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Empowering Self-Care and Independence</h1>
                        <p>One of the most profound benefits of physical therapy exercises is the sense of empowerment and independence they instill in patients. Through education and hands-on guidance, physical therapists equip individuals with the knowledge and skills necessary to take an active role in their own recovery. This self-care approach fosters a sense of ownership over one's health and encourages a proactive attitude towards maintaining wellness.</p>
                        <p>By learning proper exercise techniques and understanding the principles behind their treatment plan, patients can confidently continue their therapy at home, ensuring ongoing progress and preventing future issues. This empowerment is a cornerstone of the philosophy at Ramp Physio and Fitness, where we believe in supporting our patients to become their own best advocates for health and fitness.</p>
                        <br></br>
                        <p>
                            In conclusion, physical therapy exercises are a vital component of a comprehensive treatment plan, offering relief from pain, enhancing mobility, and promoting an active, healthy lifestyle. At Ramp Physio and Fitness, we are committed to delivering personalized, evidence-based care that empowers our patients to achieve their rehabilitation goals and enjoy a life free from limitations.
                        </p>
                    </div>

                </div>
            </SkewedContainer >
            <HealthSection />
            <TestimonialSection />
        </div >
    );
};

export default NDIS;

import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfOrthoticsInPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Mobility with Orthotics</h1>
                    <h2>Unlock the potential of orthotics in physiotherapy at Ramp Physio and Fitness to support your journey toward optimal movement and pain relief.</h2>
                    <p>At Ramp Physio and Fitness, we understand that every step towards recovery and peak performance is crucial. That's why we integrate the use of orthotics in our physiotherapy practices. Orthotics are more than just insoles; they are custom-designed devices that can alleviate pain, correct foot abnormalities, and enhance overall mobility. In this comprehensive guide, we'll explore the multifaceted role of orthotics in physiotherapy and how they can be a game-changer in your health and fitness regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Custom Orthotics for Personalized Care</h1>
                        <p>Custom orthotics are precision-engineered to cater to your individual needs. They play a pivotal role in physiotherapy by correcting biomechanical foot issues, distributing pressure evenly, and providing support where it's needed most. Here are ten key benefits of incorporating custom orthotics into your treatment plan:</p>
                        <ul>
                            <li>Improved foot and ankle support</li>
                            <li>Enhanced alignment for better posture</li>
                            <li>Reduction in pain and discomfort</li>
                            <li>Decreased risk of injury</li>
                            <li>Optimized athletic performance</li>
                            <li>Better weight distribution</li>
                            <li>Increased comfort during movement</li>
                            <li>Assistance in recovery from foot surgery</li>
                            <li>Prevention of further foot complications</li>
                            <li>Adaptability to various types of footwear</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Orthotics in Action</h1>
                    <p>Orthotics are not a standalone solution; they are part of a comprehensive approach to physiotherapy. When combined with exercises, manual therapy, and patient education, orthotics can significantly enhance treatment outcomes. They are particularly effective in managing conditions such as plantar fasciitis, arthritis, diabetic foot, and sports injuries. By integrating orthotics into your physiotherapy sessions, you can expect a more dynamic and effective path to recovery.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Science Behind Orthotics in Therapy</h1>
                    <p>Understanding the biomechanical principles of orthotics is essential for recognizing their value in physiotherapy. Orthotics work by altering the angles at which the foot strikes a walking or running surface, redistributing ground reaction forces, and improving limb alignment. This can lead to a cascade of positive changes throughout the musculoskeletal system, contributing to pain relief and improved function. The integration of orthotics in physiotherapy is backed by scientific research and clinical success, making them a key component in the treatment of various musculoskeletal disorders.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Integrating Orthotics into Your Lifestyle</h1>
                        <p>At Ramp Physio and Fitness, we advocate for a lifestyle that embraces the benefits of orthotics beyond the clinic. Whether you're an athlete, an office worker, or someone recovering from an injury, we tailor orthotic solutions to fit your daily activities and footwear. Our aim is to ensure that the therapeutic advantages of orthotics translate into your everyday life, offering continuous support and enhancing the quality of your movements. Let us assist you in making orthotics a seamless part of your journey towards a healthier, more active lifestyle.</p>
                        <br></br>
                        <p>
                            In conclusion, orthotics are a powerful tool in the physiotherapy arsenal, offering both corrective and preventive benefits. At Ramp Physio and Fitness, our dedicated team is committed to providing you with personalized orthotic solutions that align with your health goals. Embrace the role of orthotics in your treatment plan and experience the transformative impact they can have on your mobility and well-being. Book your session today and take the first step towards a pain-free and active life with the support of custom orthotics.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfOrthoticsInPhysiotherapy;
import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForBetterBoneDensity = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strengthen Your Skeleton</h1>
                    <h2>Unlock the power of gym workouts for enhancing bone density and safeguarding your skeletal health with Ramp Physio and Fitness.</h2>
                    <p>Strong bones are the foundation of good health, and at Ramp Physio and Fitness, we understand the importance of bone density in maintaining an active, injury-free lifestyle. In this comprehensive guide, we'll explore how targeted gym workouts can significantly improve your bone health, reduce the risk of osteoporosis, and help you live a robust, vibrant life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Weight-Bearing Workouts Win</h1>
                        <p>Engaging in weight-bearing gym workouts is crucial for bone strengthening. These exercises force your body to work against gravity, stimulating bone formation and slowing down bone loss. Here are ten examples of such effective workouts:</p>
                        <ul>
                            <li>Brisk Walking and Jogging</li>
                            <li>Step Aerobics</li>
                            <li>Resistance Training with Weights</li>
                            <li>Bodyweight Exercises like Push-ups</li>
                            <li>Stair Climbing</li>
                            <li>Jumping Rope</li>
                            <li>Dance Fitness Classes</li>
                            <li>Hiking on Varied Terrain</li>
                            <li>Elliptical Training Sessions</li>
                            <li>Functional Training Movements</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Resistance Training Routines</h1>
                    <p>Resistance training is particularly beneficial for bone health. By stressing your muscles, resistance exercises also stress your bones, which responds by increasing in density. Our gym at Ramp Physio and Fitness is equipped with a variety of resistance training equipment to cater to your needs, including free weights, resistance bands, and weight machines.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>High-Impact vs. Low-Impact</h1>
                    <p>Understanding the difference between high-impact and low-impact exercises is key to a balanced bone-strengthening regimen. High-impact exercises, such as running and jumping, can offer more intense stimulation for bone growth, while low-impact exercises, like swimming and cycling, are gentler on the joints but less effective for improving bone density. Our team can help you find the right balance based on your fitness level and bone health goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balance and Flexibility</h1>
                    <p>Maintaining balance and flexibility is essential for preventing falls, which can be a significant risk for individuals with low bone density. Incorporating RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs into your routine can improve your stability and flexibility, thereby protecting your bones from potential injuries.</p>
                    <br></br>
                    <p>
                        In conclusion, gym workouts are a powerful tool for building better bone density and maintaining overall health. At Ramp Physio and Fitness, we're dedicated to helping you integrate these exercises into your routine safely and effectively. Join us to strengthen your bones and enhance your quality of life through our expert-guided fitness programs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForBetterBoneDensity;